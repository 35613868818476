import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  MdAccessTime,
  MdChevronRight,
  MdOutlineLocationOn,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoCalendarOutline, IoPlayCircleOutline } from "react-icons/io5";
import { LaunchModal, UpcomingModal } from "../UI/Modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MdChevronLeft } from "react-icons/md";
import { current } from "@reduxjs/toolkit";

const formatDate = (dates) => {
  if (!dates || dates.length === 0) {
    return [];
  }

  var formDates = [];

  const formattedDates = dates.map((date) => {
    const startDate = new Date(date.startdate);
    const endDate = new Date(date.enddate);
    var obj = {};
    const obj1 = {
      date: startDate.getDate(),
      month: startDate.toLocaleString("default", { month: "short" }),
      day: startDate.toLocaleString("default", { weekday: "short" }),
      year: startDate.getFullYear(),
    };
    if (endDate) {
      const obj2 = {
        date: endDate.getDate(),
        month: endDate.toLocaleString("default", { month: "short" }),
        day: endDate.toLocaleString("default", { weekday: "short" }),
        year: endDate.getFullYear(),
      };
      obj.end = obj2;
      obj.id = date.id;
    }
    obj.start = obj1;
    obj.id = date.id;
    formDates.push(obj);
  });

  return formDates;
  //Monday - Nov. 11, 2024
  // return formattedDates
};

const LaunchForm = ({ isLaunched, setIsLaunched }) => {
  const storedFormData = useSelector((state) => state.formData);
  const storedFiles = useSelector((state) => state.formDataFiles);
  const [dates, setDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsLaunched(false);
  };

  useEffect(() => {
    setDates(formatDate(storedFormData.dates));
  }, [storedFormData]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ opacity: { duration: 0.75 }, y: { duration: 0.5 } }}
      >
        <div className=" lg:py-7 lg:px-6">
          <div className="grid lg:grid-cols-2 grid-rows-1 gap-x-6 grid-cols-1">
            <SimpleSlider storedFiles={storedFiles} />

            <div className="flex flex-col  lg:shadow-xl lg:rounded-xl">
              <div className="card rounded-t-2xl  shadow-[rgba(0,0,0,0.2)_0px_-3px_5px_0px] md:rounded-2xl mt-[-2rem] md:mt-0 relative lg:z-0 z-10 bg-white px-4 py-0">
                <div className="flex flex-col mt-4 mb-2 gap-0">
                  <h2 className="font-bold text-2xl">{storedFormData.name}</h2>
                  <h2>
                    from{" "}
                    <span className="font-bold text-xl">
                      {Math.round(storedFormData.price).toLocaleString(
                        "en-IN",
                        {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                    </span>
                    <span className=" text-sm line-through ml-1">
                      {Math.round(storedFormData.price_striked).toLocaleString(
                        "en-IN",
                        {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                    </span>
                  </h2>
                  {/* <h2>Open dates - </h2> */}
                </div>

                <div className="">
                  <div>
                    <hr className={"h-[1px] my-4 bg-black opacity-50]"} />
                    {/* <h2 className='course_overview font-bold text-lg mb-2'>Highlights</h2> */}
                    <div className="flex flex-col gap-4 mt-0">
                      <div className="flex gap-2 items-center">
                        <MdOutlineLocationOn size={25} className="text-black" />
                        <span className="font-semibold text-md max-w-sm">
                          {storedFormData?.event_type === true
                            ? storedFormData?.event_location
                              ? storedFormData?.event_location
                              : "Offline"
                            : "Online"}
                        </span>
                      </div>
                      <div className="flex gap-2 items-center">
                        <MdAccessTime size={25} className="text-black" />
                        <div className="flex flex-col">
                          {storedFormData.number_of_hours && !(storedFormData.number_of_days && storedFormData.number_of_nights) && (
                            <span className="font-semibold text-md">
                              {storedFormData.number_of_hours}{storedFormData?.number_of_hours > 1 ? ' Hours' : ' Hour'}
                            </span>
                          )}
                          {storedFormData.number_of_days && storedFormData.number_of_nights && storedFormData.number_of_hours && (
                          <span className="font-semibold text-md">
                            {storedFormData.number_of_days} Days,{" "}
                            {storedFormData.number_of_nights} Nights, {" "}
                            {storedFormData.number_of_hours} Hours
                          </span>
                          )}

                          {storedFormData.number_of_days && storedFormData.number_of_nights && !storedFormData.number_of_hours && (
                            <span className="font-semibold text-md">
                              {storedFormData.number_of_days} Days,{" "}
                              {storedFormData.number_of_nights} Nights
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr className={"h-[1px] my-4 bg-black opacity-50]"} />
                  </div>
                </div>
              </div>
              <NavigationTab storedFormData={storedFormData} />
            </div>
          </div>
        </div>
        {isLaunched && <LaunchModal closeModal={closeModal} />}
        {/* <LaunchModal closeModal={closeModal} /> */}
      </motion.div>
    </AnimatePresence>
  );
};

export default LaunchForm;

const About_Event = ({ about }) => {
  return (
    <div>
      <p
        className="px-6 mt-2 mb-2 "
        style={{ fontFamily: "Helvetica Neue", fontSize: "15px" }}
        dangerouslySetInnerHTML={{ __html: about }}
      ></p>
    </div>
  );
};

// const Itinerary_Event = ({ event }) => {
//     return (
//         <>
//             {event.addday.map((day, dayIndex) => (

//                 <div key={dayIndex} className='eventCardfont p-2 rounded mb-2' style={{fontFamily: 'Helvetica Neue',fontSize: '15px'}}>
//                     <p className="mb-1"><b>Day </b>:  <span dangerouslySetInnerHTML={{ __html: day.daysnumber }}></span></p>
//                     {day.name && (
//                         <p className="mb-1"><b>Highlight : </b><br /><span dangerouslySetInnerHTML={{ __html: day.name }}></span></p>
//                     )}
//                     {day.details && (
//                         <p className="mb-1"><b>Details :</b><br /><span dangerouslySetInnerHTML={{ __html: day.details }}></span></p>
//                     )}
//                 </div>
//             ))}
//         </>
//     )
// }

const Inclusions_Event = ({ inclusions, exclusions }) => {
  return (
    <div>
      <p
        className="px-6  mt-2 mb-2 rounded"
        style={{ fontFamily: "Helvetica Neue", fontSize: "15px" }}
        dangerouslySetInnerHTML={{ __html: inclusions }}
      ></p>
      <p
        className="px-6  mt-2 rounded mb-2"
        style={{ fontFamily: "Helvetica Neue", fontSize: "15px" }}
        dangerouslySetInnerHTML={{ __html: exclusions }}
      ></p>
    </div>
  );
};

const Pricing_Event = ({ storedFormData }) => {
  const res = formatDate(storedFormData.dates);

  console.log(res);
  // console.log(storedFormData.dates);
  // const firstDate = new Date(storedFormData.dates[0].startdate);
  // console.log(firstDate);
  return (
    <div className="px-6 mt-2 mb-2">
      {res.length > 0 && (
        <div>
          <span>Next upcoming on</span>
          <div>
            <span className="font-semibold mt-1">
              {res[0].start.day} - {res[0].start.month}. {res[0].start.date},{" "}
              {res[0].start.year}
            </span>
          </div>
        </div>
      )}
      <div>
        <span className="font-semibold py-2 block">Ticket Prices</span>
        {storedFormData?.variablePrices?.length > 1 ? (
          <div className="">
            {storedFormData.variablePrices.map((price, index) => {
              return (
                <div className="flex items-center justify-between w-full">
                  <div className="flex gap-2 items-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/ticket.png`}
                      alt="price-tag"
                      className="w-10 h-10 inline-block"
                    />
                    <span>{price.attribute}</span>
                  </div>
                  <div>
                    <span className="font-semibold">
                      &#8377;{Math.round(price.price)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h2>
            Starting from{" "}
            <span className="font-bold text-xl">
              {Math.round(storedFormData.price).toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </h2>
        )}
      </div>
    </div>
  );
};

// const Exclusions_Event = ({ exclusions }) => {
//   return (
//     <div>
//       <p
//         className="px-6  mt-2 rounded mb-2 min-h-[40vh]"
//         style={{ fontFamily: "Helvetica Neue", fontSize: "15px" }}
//         dangerouslySetInnerHTML={{ __html: exclusions }}
//       ></p>
//     </div>
//   );
// };

const NavigationTab = ({ storedFormData }) => {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [indicatorStyle, setIndicatorStyle] = useState({});

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 30;

  const tabIndicatorRef = useRef(null);

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      setActiveTab(
        tabs[tabs.indexOf(activeTab) + (isLeftSwipe ? 1 : -1)] || activeTab
      );
    }
    // add your conditional logic here
  };

  useEffect(() => {
    let presentTabs = [];

    presentTabs.push("Overview");

    presentTabs.push("Pricing");

    if(storedFormData.inclusions || storedFormData.exclusions){
    presentTabs.push("Inclusions");
    }

    setTabs(presentTabs);
    setActiveTab(presentTabs[0]);
  }, []);
  return (
    <div
      className="bg-white rounded-b-2xl"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div>
        <ul className="tabs_list">
          {tabs.map((tab, index) => (
            <li
              style={{ cursor: "pointer" }}
              key={tab}
              className={` ${
                activeTab == tab ? `activetab` : ""
              } font-semibold`}
              onClick={() => {
                console.log(tab);
                setActiveTab(tab);
              }}
            >
              {tab}
            </li>
          ))}
          {/* <div className='tab_indicator' style={{ left: `${tabs.indexOf(activeTab) * 25}%` }}></div> */}
          {/* <span className='tab_indicator'></span> */}
        </ul>
      </div>
      <div className="min-h-[45vh]">
        {activeTab === "Overview" && (
          <About_Event about={storedFormData.About_Tour} />
        )}

        {activeTab === "Inclusions" && (
          <Inclusions_Event
            inclusions={storedFormData.inclusions}
            exclusions={storedFormData.exclusions}
          />
        )}

        {activeTab === "Pricing" && (
          <Pricing_Event storedFormData={storedFormData} />
        )}
      </div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    // <button type="button">
    <span
      onClick={onClick}
      className={`absolute arrow top-1/2 transform cursor-pointer -translate-y-1/2 left-4 bg-black text-white p-1 rounded-full shadow-lg  transition`}
    >
      <MdChevronLeft size={20} />
    </span>
    // </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    // <button type="button">
    <span
      onClick={onClick}
      className={`absolute arrow top-1/2 transform cursor-pointer right-4 -translate-y-1/2 bg-black text-white p-1 rounded-full shadow-lg  transition`}
    >
      <MdChevronRight size={20} />
    </span>
    // </button>
  );
};

function SimpleSlider({ storedFiles }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  var totals = 1;
  if (storedFiles?.eventImages?.length>0) {
    totals += storedFiles?.eventImages.length;
  }
  if(storedFiles?.coverVideo?.preview){
    totals += 1;
  }
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    onInit: (slider) => setTotalSlides(totals),
    adaptiveHeight: true,
    nextArrow: currentSlide!=totals- 1 ? <NextArrow /> : null,
    prevArrow: currentSlide > 0 && <PrevArrow />,
   
  };

  console.log(storedFiles);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef(null);

  const handlePlayPause = () => {
    console.log("clicked", videoRef.current);
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Slider className=" max-h-[350px] relative" {...settings}>
      {storedFiles?.coverVideo?.preview && (
        <div className="h-[250px] relative lg:h-[350px]">
          <div
            className={`playicon absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2  ${
              isPlaying
                ? "transform transition duration-500 ease-in opacity-0 scale-150"
                : "transition-opacity duration-700 ease-in opacity-1"
            }`}
          >
            <IoPlayCircleOutline
              className="text-white cursor-pointer"
              size={45}
            />
          </div>
          <video
            ref={videoRef}
            onClick={handlePlayPause}
            src={storedFiles?.coverVideo?.preview}
            className="md:rounded-2xl w-full h-full object-cover"
          />
        </div>
      )}

      <div className="h-[250px] lg:h-[350px]">
        <img
          className="md:rounded-2xl w-full h-full object-cover"
          src={storedFiles?.coverImage?.preview}
          alt="image"
        />
      </div>
      {storedFiles.eventImages &&
        storedFiles?.eventImages.map((image) => (
          <div className="h-[250px]  lg:h-[350px]">
            <img
              className="md:rounded-2xl w-full h-full object-cover"
              src={`${process.env.REACT_APP_API_URL}` + image?.preview}
              alt="image"
            />
          </div>
        ))}
    </Slider>
  );
}
