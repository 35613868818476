import React, { useEffect, useRef } from 'react';


  
const LogoutWidget = ({isOpen, eventSlug}) => {
  
    useEffect(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src ="https://logout.world/static/widget/logout-booking.js"
      document.body.appendChild(script);
      script.onload = () => {
        
            const { widget } = window.logout;
            widget.setConfig({
                eventSlug: eventSlug,
                placement: "#book-container",
                customClass: "",
                btnId: "bookingBtn",
                downloadBtnId: "",
                enquiryBtnId: "",
                onlyButton: false,
                showEverything: false,
                showItineraryButton: false,
                showEnquiryButton: false,
                
            })
            if(isOpen){
             widget.init();
           widget.triggerBookingModal()
           }
        }

    
        // Cleanup function to remove script on component unmount
        return () => {
          document.body.removeChild(script);
          
        };
      }, [isOpen]);

  return (
    <div className='bookingWidgetContainer'>
      <div id="book-container"></div>
    </div>
  )
};

export default LogoutWidget;

