import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Editor({defaultvalue, setValue}) {

    const handleChange = (value) => {
        setValue(value)
        if(value === '<p><br></p>'){
            setValue('')
        }
    }
  
  const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
      
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        ['clean']                                         // remove formatting button
      ]
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return <ReactQuill className='rounded-xl' theme="snow" modules={modules} value={defaultvalue} onChange={handleChange} />;
}