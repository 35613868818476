import axios from "axios";
const REACT_APP_MASTER_DOMAIN = "https://logout.world/"
const postApi = "https://logout.world/tours/save-event-enquiry/";
const endPointUrl = "https://logout.world";




export const BioLinkData = async (partner) => {
    try {
        const response = await axios.get(REACT_APP_MASTER_DOMAIN + "api/biolink/" + partner);
        const data = response.data;
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const DownloadPdf = async (pdfData, slug) => {
    try {
        const formData = new FormData();
        formData.append("fullname", pdfData.name);
        formData.append("mobile", pdfData.number);

        const response = await axios.post(`${postApi}${slug}/`, formData);
        const data = response.data;
        
        if (data.pdf_download_url) {
            // const link = document.createElement('a');
            // link.href = endPointUrl + data.pdf_download_url;
            // link.setAttribute('download', 'download');  
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            // window.open(data.pdf_download_url, "_self");
            window.location.href = data.pdf_download_url;
        } else {
            console.error("PDF download URL not found in response data.");
        }

        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

