export const filterBookingEvents = (batchList) => {
    const filteredData = batchList.filter(batch => batch.actual_numberofbookings > 0 || batch.manual_numberofbookings > 0);
    return filteredData
}

export const filterWithoutBookingEvents = (batchList) => {
    const filteredData = batchList.filter(batch => batch.actual_numberofbookings + batch.manual_numberofbookings == 0);
    return filteredData
}

export const getUniqueEventsFromBatchesObject = (batchList) => {
    const uniqueTitlesArray = [...new Set(batchList.map(event => event.title))];
    return uniqueTitlesArray
}

export const selectAllCheckboxes = (isChecked) => {
    const checkboxes = document.querySelectorAll('.event-checkbox');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
}

export const markAsChecked = (eventList) => {
    const checkboxes = document.querySelectorAll('.event-checkbox');
    checkboxes.forEach((checkbox) => {
        const checkboxValue = checkbox.value;
        if (eventList.includes(checkboxValue)) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
    });
}

export const countNumberOfCheckbox = () => {
    const checkboxes = document.querySelectorAll('.event-checkbox');
    let checkedBoxes = 0;
    checkboxes.forEach((checkbox) => {
        if(checkbox.checked)
            checkedBoxes+=1;
    });
    return checkboxes.length == checkedBoxes
}