import React, { useRef, useEffect, useState } from "react";
import {
  MdCancel,
  MdClose,
  MdOutlineAccountCircle,
  MdOutlineSearch,
  MdSearch,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdAddCircleOutline } from "react-icons/md";
import { InstructorsModal } from "../UI/Modal";
import { BsFillPeopleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import Editor from "../components/Editor";
import Select from "react-select";

const DetailsForm = ({ formData, setFormData, salesTeam, instructors }) => {
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [overview, setOverview] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [addInstructorOpen, setAddInstructorOpen] = React.useState(false);
  const ref = useRef(null);
  const searchRef = useRef(null);
  const storedFormData = useSelector((state) => state.formData);
  console.log("storedFormData", storedFormData);
  const [hosts, setHosts] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  
  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      searchRef.current &&
      !searchRef.current.contains(event.target)
    ) {
      setIsSearchOpen(false);
    }
  };

  const handleAddClickInstructors = (options) => {
    console.log("options", options);
    const selected = []
    options.map((option) => {
      selected.push(option.value)
    })
    setHosts(options);
    setFormData((prevData) => ({ ...prevData, hosts: selected }));
    // setSelectedInstructors(options);
  };

  const instructorRef = useRef(null)

  const handleAddClickSales = (options) => {
    const selected = []
    selected.push(options.value)
    setSalesRep(options);
    setFormData((prevData) => ({ ...prevData, salesRep: selected}));
  };

  const handleAddClickCategory = (options) => {
    const selected = []
    options.map((option) => {
      selected.push(option.label)
    })
    setSelectedCategories(options);
    setFormData((prevData) => ({ ...prevData, events_category: selected }));
  }

  const [salesOptions, setSalesOptions] = useState([]);
  const [instrutorOptions, setInstructorOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (storedFormData?.company_people?.sales_team_mates?.length > 0) {
      const options = storedFormData.company_people.sales_team_mates?.map((mate) => {
        return { value: mate.peopleid, label: mate.username };
      });
      setSalesOptions(options);
    }
    if (storedFormData?.company_people?.team_mates?.length > 0) {
      const opts = storedFormData?.company_people?.team_mates?.map((mate) => {
        return { value: mate.peopleid, label: mate.username };
      });
      setInstructorOptions(opts);
    }
    if(storedFormData?.company_category?.categories?.length>0){
      const opts = storedFormData?.company_category?.categories?.map((cate) => {
        return { value: cate.id, label: cate.name };
      });
      setCategoryOptions(opts);
    }

  }, []);

  useEffect(() => {
    if (storedFormData?.salesRep?.length > 0) {
     
      storedFormData?.salesRep?.forEach((rep) => {
        const res = storedFormData?.company_people?.sales_team_mates?.find((item) => item.peopleid == rep);
        console.log("res", res);
        if(res){
        setSalesRep({ label: res?.username, value: res?.peopleid })
        }

      });
    }

    if (storedFormData?.hosts?.length > 0) {
      console.log("ins",instructors,storedFormData.hosts);
      storedFormData?.hosts?.forEach((rep) => {
        const res = storedFormData?.company_people?.team_mates?.find((item) => item.peopleid == rep);
        console.log("res", res);
        if (res) {
          setHosts((prevState) => {
            // Check if the item already exists in the state
            const exists = prevState.some(
              (item) => item.value === res.peopleid
            );
            if (!exists) {
              return [
                ...prevState,
                { label: res?.username, value: res?.peopleid },
              ];
            }
            return prevState; // Return the unchanged state if duplicate found
          });
        }
      });
    }

    if(storedFormData?.events_category?.length>0){
      storedFormData?.events_category?.forEach((cate) => {
        const res = storedFormData?.company_category?.categories?.find((item) => item.name == cate);
        console.log("res", res);
        if (res) {
          setSelectedCategories((prevState) => {
            const exists = prevState.some(
              (item) => item.value == res.id
            );
            if (!exists) {
              return [
                ...prevState,
                { label: res?.name, value: res?.id },
              ];
            }
            return prevState;
          });
        }
      })
    
  }

  }, [storedFormData,salesTeam,instructors]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f8f6f2"
        : state.isFocused
        ? "#f8f6f2"
        : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };



  useEffect(() => {
    if (storedFormData) {
      setOverview(storedFormData.About_Tour);
      setEligibility(storedFormData.who_for);
      // console.log("hosts",storedFormData.sales_representative)
    }
  }, [storedFormData]);

  useEffect(()=>{
    console.log("categoryOptions",categoryOptions);
  },[categoryOptions])


  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      About_Tour: overview,
      who_for: eligibility,
    }));
  }, [overview, eligibility]);

  useEffect(() => {
    console.log(salesTeam, instructors);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);


  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ opacity: { duration: 0.75 }, y: { duration: 0.5 } }}
      >
        <div className=" px-6 py-4 overflow-auto pb-24 flex flex-col section_gap_sm lg:gap-0">
          <div className="lg:section_padding_lg lg:pt-0 mt-4 relative grid grid-rows-1 lg:grid-cols-3 grid-cols-1">
            <h2 className="section_heading">Overview*</h2>
            <div className="col-span-2">
              <Editor defaultvalue={overview} setValue={setOverview} />
            </div>
            <input
              type="text"
              value={overview}
              required
              className="opacity-0 absolute z-0 top-40 lg:right-10"
            />
            
          </div>
          <div className="relative lg:section_padding_lg grid grid-rows-1 lg:grid-cols-3 grid-cols-1">
            <h2 className="section_heading">
              Eligibility and prerequisites
            </h2>
            <div className="col-span-2">
              <Editor defaultvalue={eligibility} setValue={setEligibility} />
            </div>
            <input
              type="text"
              value={eligibility}
              className="opacity-0 absolute z-0 top-40"
            />
           
          </div>
          {/* <div className="py-0 relative lg:hidden">
            {hosts && hosts?.length > 0 && (
              <div className="mt-0 pb-2">
                <h2 className="section_heading">Instructors</h2>
                <div className="selected_instructors flex flex-wrap w-full gap-4 mt-3">
                  {hosts.map((host, index) => (
                    <div
                      key={index}
                      className="relative z-10 px-2 py-1 bg-[#f8f6f2] rounded-md flex items-center gap-2"
                    >
                      <span className="">{host.label}</span>
                      <MdClose
                        size={15}
                        onClick={() => {
                          setHosts(
                            hosts.filter((item) => item.value !== host.value)
                          );
                        }}
                        className=""
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {Object.keys(salesRep).length>0 && (
              <div className="mt-2 mb-2">
                <h2 className="section_heading">Sales</h2>
                <div className="selected_instructors flex flex-wrap gap-4 mt-2">
                    <div
                      
                      className="relative z-10 px-2 py-1 bg-[#f8f6f2] rounded-md flex items-center gap-2"
                    >
                      <span className="">{salesRep.label}</span>
                      <MdClose
                        size={15}
                        onClick={() => {
                          setSalesRep(
                            {}
                          );
                        }}
                        className=""
                      />
                    </div>
                 
                </div>
              </div>
            )}
            <div
              className="bg-[#f8f6f2] flex justify-between items-center px-4 py-2 mt-3  rounded-xl"
              onClick={() => {
                setAddInstructorOpen(true);
              }}
            >
              <div className="flex items-center gap-3">
                <BsFillPeopleFill size={25} />
                <span className="font-semibold">
                  {(hosts && hosts?.length > 0) || Object.keys(salesRep)?.length > 0
                    ? "Update people"
                    : "Add people"}
                </span>
              </div>
              <MdAddCircleOutline
                size={25}
                className=" text-gray-500 cursor-pointer"
              />
            </div>
          </div> */}
        <div className="lg:block">
          <div className="lg:section_padding_lg grid grid-rows-1 lg:grid-cols-3">
            <h2 className="section_heading">Assign instructors</h2>
            <div className="col-span-2">
            <Select
              onChange={handleAddClickInstructors}
              ref={instructorRef}
              value={hosts}
              isMulti
              isSearchable
              options={instrutorOptions}
              styles={customStyles}
              menuPlacement="top"
              classNames={{
                control: () => "!rounded-xl !border-2 !border-[#e5e7eb]",
              }}
            />
            </div>
          </div>
          <div className=" grid grid-rows-1 lg:grid-cols-3 lg:section_padding_lg py-8">
            <h2 className="section_heading">Assign sales</h2>
            <div className="col-span-2">
            <Select
              onChange={handleAddClickSales}
              value={salesRep}
              // isMulti
              isSearchable
              options={salesOptions}
              menuPlacement="top"
              classNames={{
                control: () => "!rounded-xl !border-2 !border-[#e5e7eb]",
              }}
            />
            </div>
          </div>
          <div className=" grid grid-rows-1 lg:grid-cols-3 lg:section_padding_lg ">
            <h2 className="section_heading">Select Categories</h2>
            <div className="col-span-2">
            <Select
              onChange={handleAddClickCategory}
              value={selectedCategories}
              isMulti
              isSearchable
              options={categoryOptions}
              menuPlacement="top"
              classNames={{
                control: () => "!rounded-xl !border-2 !border-[#e5e7eb]",
              }}
            />
            </div>
          </div>
        </div>
        </div>
        {addInstructorOpen && (
          <InstructorsModal
            hosts={hosts}
            instrutorOptions={instrutorOptions}
            salesOptions={salesOptions}
            salesRep={salesRep}
            handleAddClickInstructors={handleAddClickInstructors}
            handleAddClickSales={handleAddClickSales}
            closeModal={() => {
              setAddInstructorOpen(false);
            }}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default DetailsForm;
