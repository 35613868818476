import React from 'react'

const Button = ({text,classes,handleClick}) => {
  return (
    <button type='button' onClick={handleClick} className={`text-white text-[0.875rem] bg-[#045540] font-medium px-4 py-2 my-0 rounded-lg ${classes}`}>
       {text}
    </button>
  )
}

export default Button
