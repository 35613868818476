import React, { useEffect } from 'react';
import { Tuple } from '@reduxjs/toolkit';

const Input = ({ placeholder, type, classes, name,handleChange,...rest }) => {
 
    return (
      <div className='w-full'>
        <input
          style={{ fontSize: '13px' }}
          type={type}
          name={name}
          {...rest}
          placeholder={placeholder}
          onChange={handleChange}
          className={`px-[13px] py-[9.570px] rounded-xl focus:outline-none text-input_text bg-[#f8f6f2] focus:bg-[#f4f1eb] font-medium placeholder-gray-400 ${classes}`}
        />
      </div>
    );
  }

// Setting the displayName makes it easier to debug if you inspect the component in React DevTools
Input.displayName = 'Input';

export default Input;


export const RadioInput = ({radioLabel,name, radioInputValue, checked, onChange}) => {
    console.log(name,checked)
    return (
    <label className="flex items-center space-x-1  cursor-pointer">
        <input
          type="radio"
          name={name}
          value={radioInputValue}
          className="sr-only peer"
          defaultChecked={checked}
          onChange={() => onChange(radioInputValue)}
        />
        <div className="w-5 h-5 bg-[#f8f6f2] rounded-full border-2 peer-checked:ring-[6px] peer-checked:ring-inset peer-checked:ring-[#045540]"></div>
        <span className="text-black">{radioLabel}</span>
      </label>
    )
}

export const RadioButtonGroup = ({ name, options, selectedValue, onChange, classes,columns }) => {
  console.log(name, selectedValue)
    return (
      <div className={`radio-button-group grid grid-cols-2 lg:grid-cols-${columns}  gap-2`}>
        {options.map((option) => (
          <label key={option.value} className={`flex hover:bg-[#DDF8FC] hover:border-black items-center justify-start px-[19.5px] py-[14px] rounded-xl border-[1px] border-dashed border-[#dad3c3]  cursor-pointer ${classes}`}>
          <input
            type="radio"
            name={name}
            value={option.value}
            className="sr-only peer"
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
          />
          
          <div className="w-5 h-5 bg-[#F6F1E9] rounded-full peer-checked:ring-[5px] peer-checked:ring-inset peer-checked:ring-[#045540]"></div>
          <span className="text-black font-medium ml-2">{option.label}</span>
          
        </label>
        ))}
      </div>
    );
  };


