import React from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'

const SecondaryButton = ({text,classes,handleClick}) => {
  return (
    // <button type='button' onClick={handleClick} className={`font-medium px-4 py-2 text-black opacity-75 rounded-lg ${classes}`}>
    //    {text}
    // </button>
    <button type='button'
                    onClick={handleClick}
                    className={`flex text-sm underline text-black items-center justify-center gap-1 rounded-md cursor-pointer ${classes}`}
                  >
                    <span>Remove</span>
                    <HiOutlineTrash size={20} />
                  </button>
  )
}

export default SecondaryButton
