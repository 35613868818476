import React from 'react'
import {MdChevronLeft} from "react-icons/md";

const BackButton = ({text,classes,handleClick}) => {
    return (
        <div>
      <button type='button' onClick={handleClick} className={`font-medium flex items-center py-2 text-black opacity-75 rounded-lg ${classes}`}>
          {text=="Back" && <MdChevronLeft className={'text-black opacity-75'} size={25} />}
         {text}
      </button>
      </div>
    )}

export default BackButton
