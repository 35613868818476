import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import moment from 'moment';
// import './_theme/assets/css/style.bundle.css'
// import '../../_theme/assets/sass/style.dark.scss';
// import './_theme/assets/sass/style.scss';
// import './_theme/assets/sass/plugins.scss'
// import '../../_theme/assets/plugin/custom/fullcalendar.bundle.css';
// import '../../_theme/assets/plugin/global/plugins.bundle.css';

import '../../style.css';
import '../../Components/preloader/preloader.css'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { filterBookingEvents, filterWithoutBookingEvents, getUniqueEventsFromBatchesObject, markAsChecked, selectAllCheckboxes, countNumberOfCheckbox } from './Utils';


const Calendar = () => {
  const [resources,setResources] = useState();
  const [resourceList,setResourceList] = useState();
  const [view, setView] = useState('resourceTimelineWeek');
  const [key, setKey] = useState(Date.now()); // Generate a unique key
  const [cssLoaded, setCssLoaded] = useState(false);
  const [data, setData] = useState(null);
  const {slug} = useParams()
  const [selectAll, setSelectAll] = useState(true);
  const [initialDate, setInitialDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedOption, setSelectedOption] = useState("All Event");
  const [eventList, setEventList] = useState();
  // const host = "http://127.0.0.1:8000"
  const host = "https://logout.world"
  const [batchesData, setBatchesData] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if(option == "All Event"){
      handleSelectAll({"target": {
        "checked": true
      }})
      setSelectAll(true)
      setResources(resourceList)
    }
    else if(option == "Events with booking"){
      const filteredBatches = filterBookingEvents(data.batches)
      const uniqueEvents = getUniqueEventsFromBatchesObject(filteredBatches)
      markAsChecked(uniqueEvents)
      setBatchesData(filteredBatches)
      setSelectAll(countNumberOfCheckbox())
      let resourceFilter = resourceList.filter(event => uniqueEvents.includes(event.title))
      setResources(resourceFilter)
    }
    else if(option == "Events without booking"){
      const filteredBatches = filterWithoutBookingEvents(data.batches)
      const uniqueEvents = getUniqueEventsFromBatchesObject(filteredBatches)
      markAsChecked(uniqueEvents)
      setBatchesData(filteredBatches)
      setSelectAll(countNumberOfCheckbox())
      let resourceFilter = resourceList.filter(event => uniqueEvents.includes(event.title))
      setResources(resourceFilter)

    }
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    searchFunction(query);
  };

  const searchFunction = (query) => {
    const filteredEvents = data.events.filter((event) => {
      const { id, name, slug } = event;

      return (
        name.toLowerCase().includes(query.toLowerCase()) 
      );
    });
    setEventList(filteredEvents);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (!isChecked && selectedOption=="All Event"){
      setSelectedOption(null)
    }
    selectAllCheckboxes(isChecked)
    if(isChecked){
      setBatchesData(data.batches)
      setResources(resourceList)
    }
    else{
      setBatchesData([])
      setResources([])
    }
  };

  const sortByStartDate = (a, b) => {
    const dateA = new Date(a.start);
    const dateB = new Date(b.start);
    
    return dateA - dateB;
  };

  const handleEventCheckboxChange = (event, eventSlug) => {
    const isChecked = event.target.checked;
    setBatchesData(prevBatchesData => {
      if (isChecked) {
        // Add the batch if checked
        const batches = data.batches.filter(batch => batch.slug == eventSlug )
        const temp = [...prevBatchesData, ...batches].sort(sortByStartDate)

        return temp;
      } else {
        // Remove the batch if unchecked
        return prevBatchesData.filter(batch => batch.slug !== eventSlug);
      }
    });
    setResources(prevResourcesData => {
      if (isChecked) {
        // Add the event if checked
        const events = resourceList.filter(event => event.id == eventSlug )
        const temp = [...prevResourcesData, ...events]

        return temp;
      } else {
        // Remove the event if unchecked
        return prevResourcesData.filter(event => event.id !== eventSlug);
      }
    });
    rerenderCalendar();
  };

  useEffect(() => {
    // Dynamically import CSS when the component mounts
    Promise.all([
        import('../../_theme/assets/plugin/custom/fullcalendar.bundle.css'),
        import('../../_theme/assets/sass/style.dark.scss'),
        import('../../_theme/assets/plugin/global/plugins.bundle.css')
      ]).then(() => {
        // Set the state to indicate that CSS has been loaded
        setCssLoaded(true);
      }).catch((error) => {
        console.error('Failed to load CSS:', error);
      });
    
    // Cleanup function to remove CSS when component unmounts
    return () => {
      // You can remove styles if necessary
    };
  }, []);

  useEffect(() => {
    let startOfWeek = moment().startOf('week');
    let endOfWeek = moment().endOf('week');

    // Formatting the dates to return
    let startDateFormatted = startOfWeek.format('YYYY-MM-DD');
    let endDateFormatted = endOfWeek.format('YYYY-MM-DD');
    const url = `${host}/api/calendar/event-batches/${slug}/`;
    axios.get(url,{
      params:{
        start: startDateFormatted,
        end: endDateFormatted,
      }
    }).then(response => {
      // Handle the successful response
      const modifiedData = {
        ...response.data,
        batches: response.data.batches.map(batch => ({
          ...batch,
          'allDay': true,
          "resourceId": batch.slug 
        })),
        resources: response.data.events.map(event => ({
          'id': event.slug,
          'title': event.name 
        }))
      };
      setData(modifiedData);
      setBatchesData(modifiedData.batches);
      setEventList(modifiedData.events);
      setResources(modifiedData.resources);
      setResourceList(modifiedData.resources);
    })
    .catch(error => {
      // Handle any errors
      console.error('Error fetching data:', error);
    });
    axios.get(url)
      .then(response => {
        // Handle the successful response
        const modifiedData = {
          ...response.data,
          batches: response.data.batches.map(batch => ({
            ...batch,
            'allDay': true,
            "resourceId": batch.slug 
          })),
          resources: response.data.events.map(event => ({
            'id': event.slug,
            'title': event.name 
          }))
        };
        setData(modifiedData);
        setBatchesData(modifiedData.batches);
        setEventList(modifiedData.events);
        setResources(modifiedData.resources);
        setResourceList(modifiedData.resources);
      })
      .catch(error => {
        // Handle any errors
        console.error('Error fetching data:', error);
      });
  },[])

  // useEffect(() => {
  //   setKey(Date.now());
  // }, [batchesData])

  const rerenderCalendar = () => {
    setKey(Date.now());
  }

  const handleDateSet = (info) => {
    const startDateFormatted = moment(info.start).format('YYYY-MM-DD')
    setInitialDate(startDateFormatted)
  }
  
  

  const handleViewChange = (newView) => {
    setView(newView);
    setKey(Date.now()); // Update key to re-mount the component for list view
  };

  const handleEventClick = (info) => {
    const eventId = info.event.id; 
    // window.location.href = `https://logout.world/dashboard/batch-refund/${eventId}/`;
    window.open(`${host}/dashboard/batch-details/${eventId}`, '_blank');
  };

  if (!cssLoaded || batchesData==null) {
    return (
      <div className='row m-2'>
        <div className='col-md-3 mt-2'>
          <div className="card" style={{height: 'calc(100vh - 20px)'}}>
            <div className="p-32">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>

              <div className="shimmerBG content-line m-t-24"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line"></div>
              <div className="shimmerBG content-line end"></div>
            </div>
          </div>
        </div>
        <div className='col-md-9 mt-2'>
        <div className="card" style={{height: 'calc(100vh - 20px)'}}>
            <div className="p-32 h-100">
              <div className="shimmerBG title-line"></div>
              <div className="shimmerBG title-line end"></div>

              <div className="shimmerBG media"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const eventContent = (eventInfo) => {
    // console.log(eventInfo.event._def.extendedProps)
    const eventData = eventInfo.event._def.extendedProps
    // console.log(eventData,eventData.actual_numberofbookings + eventData.manual_numberofbookings)
    return (
      <div style={{overflow:"auto"}}>
        <strong>{eventInfo.event.title}</strong>
        <br/>
        <p className='mb-0'>
        {eventData.starttime?moment(eventData.starttime).format('h:mm A'):"--"} | Booked: {eventData.actual_numberofbookings + eventData.manual_numberofbookings}/{eventData.batch_size?eventData.batch_size:"--"}
        </p>
      </div>
    );
  };

  const groupByEventContent = (eventInfo) => {
    // console.log(eventInfo.event._def.extendedProps)
    const eventData = eventInfo.event._def.extendedProps
    // console.log(eventData,eventData.actual_numberofbookings + eventData.manual_numberofbookings)
    return (
      <div style={{overflow:"auto"}}>
        <strong>Time: {eventData.starttime?moment(eventData.starttime).format('h:mm A'):"--"}</strong>
        <br/>
        <strong>Booked: {eventData.actual_numberofbookings + eventData.manual_numberofbookings}/{eventData.batch_size?eventData.batch_size:"--"}</strong>
      </div>
    );
  };

  // const handleEventsSet = (events) => {
  //   console.log('Events are set:', events);
  //   // Perform additional actions with the events if needed
  // };

  return (
    <>
      <div className='row m-2'>
        <div className='col-md-3 mt-2'>
          <div className="card" style={{ maxHeight: 'calc(100vh - 20px)' }}>
            <div className="card-header">
              <div className='row w-100'>
                <div className='col-2 my-auto'>
                  <div className="form-check form-check-solid my-auto">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={selectAll}
                      id="select-all-checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                    {/* <label className="form-check-label fs-5 fw-bolder" htmlFor="select-all-checkbox">
                      All Events
                    </label> */}
                  </div>
                </div>
                <div className='col-8 my-auto'>
                  <input
                    type='text'
                    className='form-control form-control-solid px-4 rounded-5 w-100 my-auto'
                    name='search'
                    placeholder='Search Events by name'
                    // value={searchQuery}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-2 my-auto">
                  <div className="dropdown">
                    <button
                      className="btn btn-icon dropdown-toggle"
                      type="button"
                      id="filterDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter text-white"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="filterDropdown" style={{minWidth:"195px"}}>
                      {/* Add your dropdown options here */}
                      <li>
                        <a className={`dropdown-item ${selectedOption === "All Event" ? "active" : ""}`} href="#" onClick={() => handleOptionSelect("All Event")}>
                          <input type="radio" name="filterOption" id="option1" className="form-check-input me-2" checked={selectedOption === "All Event"} readOnly />
                          All Events
                        </a>
                      </li>
                      <li>
                        <a className={`dropdown-item ${selectedOption === "Events with booking" ? "active" : ""}`} href="#" onClick={() => handleOptionSelect("Events with booking")}>
                          <input type="radio" name="filterOption" id="option2" className="form-check-input me-2" checked={selectedOption === "Events with booking"} readOnly />
                          With booking
                        </a>
                      </li>
                      <li>
                        <a className={`dropdown-item ${selectedOption === "Events without booking" ? "active" : ""}`} href="#" onClick={() => handleOptionSelect("Events without booking")}>
                          <input type="radio" name="filterOption" id="option3" className="form-check-input me-2" checked={selectedOption === "Events without booking"} readOnly />
                          Without booking
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ overflow: 'auto' }}>
              {eventList.map((event, idx) => {
                return (
                  <div className="form-check form-check-solid mb-3 text-start" key={idx}>
                    <input
                      className="form-check-input event-checkbox"
                      type="checkbox"
                      value={event.name}
                      id={`checkbox${idx}`}
                      defaultChecked={true}
                      onChange={(e) => handleEventCheckboxChange(e, event.slug)}
                    />
                    <label className="form-check-label mw-100" htmlFor={`checkbox${idx}`} title={event.name} style={{fontSize:"0.95rem"}}>
                      <img
                        className="rounded-1 me-2"
                        src={`${host}${event.cover_pic}`}
                        alt={`${event.name}`}
                        style={{ height: '2em', width: '2em', objectFit:"cover" }}
                      />
                      {event.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='col-md-9 mt-2'>
          <div className="card" style={{maxHeight: 'calc(100vh - 20px)'}} key={key}>
            {/* <div className="card-header">
              <h2 className="card-title fw-bold">Calendar</h2>
            </div> */}
            <div className="card-body" style={{overflow:"auto"}}>
            {/* Uncomment for adding button for different views */}
            <div className='text-center'>
              <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button type="button" onClick={() => handleViewChange('timeGridWeek')} className={`btn border btn-outline-primary text-white ${view=="timeGridWeek"?"active":""}`}>Group By Time</button>
                <button type="button" onClick={() => handleViewChange('resourceTimelineWeek')} className={`btn border btn-outline-primary text-white ${view=="resourceTimelineWeek"?"active":""}`}>Group By Events</button>
                {/* <button type="button" onClick={() => handleViewChange('listWeek')} className="btn border btn-outline-primary text-white">List</button> */}
              </div>
            </div>
            <FullCalendar
              key={key} // Use key to force re-mounting of component
              schedulerLicenseKey= {'GPL-My-Project-Is-Open-Source'}
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin, resourceTimelinePlugin]}
              initialView={view}
              initialDate={initialDate} // Specify the initial date for list view
              weekends={true}
              contentHeight={"auto"}
              slotDuration="24:00:00"
            //   slotLabelInterval={{ hours: 2 }}
              events={batchesData}
              eventClick={handleEventClick}
              // slotEventOverlap={false}
              eventContent={view=="timeGridWeek"?eventContent:groupByEventContent}
              eventOrder={"start"}
              datesSet={handleDateSet}
              resources={resources}
              firstDay={1}
              resourceAreaHeaderContent={"Events"}
            />
            </div>
          </div>
        </div>
        </div>
          {/* Modals */}
          <div className="modal fade" id="kt_modal_add_event" tabIndex="-1" aria-hidden="true" data-bs-focus="false">
            {/* Add Event Modal Content */}
          </div>
          <div className="modal fade" id="kt_modal_view_event" tabIndex="-1" data-bs-focus="false" aria-hidden="true">
            {/* View Event Modal Content */}
          </div>
        
    </>
  );
};

export default Calendar;
