import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LinkInBio from '../pages/link_in_bio/LinkInBio';
import Calendar from '../pages/calendar/Calendar';
import EventForm from '../pages/yoga-flow/EventForm';

function AppRoutes() {
    return (
      <Routes>
        {/* <Route path="/:companyname/create-event/yoga" element={<TokenHandler />}/> */}
        {/* <Route path="/create-event/:companyname" element={<TokenHandler />}/> */}
        <Route path="/biolink/:slug" element={<LinkInBio />} />
        <Route path='/calendar/:slug' element={<Calendar/>}/>
        <Route path="/create-event/:companyname/yoga" element={<EventForm />} />
        <Route path="/edit-event/:companyname/:slug" element={<EventForm />} />
      </Routes>
    )
}
export default AppRoutes