import React, { useRef, useState } from "react";
import { MdAddCircleOutline, MdOutlineClose, MdCancel } from "react-icons/md";
import SecondaryButton from "./SecondaryButton";
import Button from "./Button";
import BackButton from "./BackButton";

const ModalMultiple = ({ isOpen, closeModal, setEventImages, eventImages }) => {
  const MAX_FILES = 10;
  const inputRef = useRef(null);
  const [counter, setCounter] = useState(0);
  const [chosenFiles, setChosenFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [error,setError] = useState(false);

  const handleFileEvent = (e) => {
    console.log("fired");
    const files = Array.prototype.slice.call(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png"]; // Define allowed types

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        setError(true)
        return false; // Skip files that are not allowed
      }
      return true;
    });
    handleUploadFiles(validFiles);
  };

  const handleUploadFiles = (files) => {
    const chosen = [...chosenFiles];
    var x = 0;
    files.some((file) => {
      if (chosen.findIndex((f) => f.name === file.name) === -1) {
        const uniqueId = `${Date.now()}-${Math.random()
          .toString(36)
          .substr(2, 9)}`;
        chosen.push({
          file: file,
          preview: URL.createObjectURL(file),
          id: uniqueId,
        });
        x++;
      }
      if (chosen.length === MAX_FILES) setFileLimit(true);
      return chosen.length === MAX_FILES;
    });
    setChosenFiles(chosen);
    // setCounter(counter + 1);
  };

  if (!isOpen) return null;
  return (
    <div
      class="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div class="fixed inset-0 z-10 w-screen h-[90vh]">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div class="relative max-h-[90vh] overflow-auto w-full transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white rounded-lg px-4 md:px-2 pb-2 pt-5 sm:p-6 sm:pb-4">
              <div class="flex flex-col gap-4 px-4 cursor-pointer">
                <div className="flex gap-8 items-center justify-between">
                  <h2 className="text-2xl md:text-3xl font-bold">
                    Choose images
                  </h2>
                  <MdOutlineClose
                    size={25}
                    onClick={closeModal}
                    className=" text-gray-500 cursor-pointer"
                  />
                </div>
                <div
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  className="flex flex-col py-8 rounded-xl items-center justify-center border border-[#dad3c3] border-dashed"
                >
                  <MdAddCircleOutline
                    size={25}
                    className=" text-gray-500 cursor-pointer mb-2"
                  />
                  <h2 className="text-lg text-black font-semibold">
                    Click to upload
                  </h2>
                  <input
                    onChange={handleFileEvent}
                    id="fileUpload"
                    type="file"
                    className="hidden"
                    multiple
                    accept="image/jpeg, image/png"
                    ref={inputRef}
                  />
                  <span className="text-gray-500 text-sm">
                    Supported formats: .png, .jpg
                  </span>
                  {error && <span>Please select .jpg or .png files</span>}
                </div>
              </div>
            </div>
            {
              <div className="grid grid-cols-2 place-items-center gap-x-4 gap-y-4 px-6">
                {chosenFiles.map((file) => (
                  <div key={file.file.name} className="relative contents">
                    <div
                      style={{
                        backgroundImage: `url(${file.preview})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        aspectRatio: "1/1",
                        flexBasis: `calc(50% - 0.5rem)`,
                        display: "flex",
                        borderRadius: "0.75rem",
                      }}
                      className={`overflow-hidden relative w-full`}
                    >
                      <MdCancel
                        onClick={() => {
                          setChosenFiles(
                            chosenFiles.filter(
                              (f) => f.file.name !== file.file.name
                            )
                          );
                        }}
                        size={25}
                        className="absolute top-2 right-2 text-[rgba(0,0,0,0.6)] bg-white rounded-full cursor-pointer"
                      />
                      {/* <img src={URL.createObjectURL(coverImage)} alt='cover' className='absolute object-cover w-full top-0 left-0'/> */}
                    </div>
                  </div>
                ))}
              </div>
            }

            <hr className="my-2 h-[1px] border-none bg-black opacity-30" />
            <div className="flex justify-between px-4 py-3">
              {/* <button
                type="button"
                onClick={() => {
                  setChosenFiles([]);
                  closeModal();
                }}
                className=" bg-white border-[1px] font-semibold border-black border-opacity-40 px-10 py-1 text-black rounded-lg"
              >
                Cancel
              </button> */}
              <BackButton
                text={"Cancel"}
                handleClick={() => {
                  setChosenFiles([]);
                  closeModal();
                }}
              ></BackButton>
              {/* <button
                type="button"
                className="text-white bg-[#4F55DA] font-medium px-10 py-1 my-0 rounded-lg"
                onClick={() => {
                  setEventImages((prev) => [...prev, ...chosenFiles]);
                  setChosenFiles([]);
                  closeModal();
                }}
              >
                Done
              </button> */}
              <Button text={"Done"} handleClick={() => {setEventImages((prev) => [...prev, ...chosenFiles]);
                  setChosenFiles([]);
                  closeModal();}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMultiple;
