import React, { useEffect, useState } from "react";
import { BioLinkData, forPdfDownload } from "../api/Api";
import './navbar/navbar.css'

const Navbar = ({ inputText, onInputChange, slug }) => {
  const [company, setCompany] = useState([]);
  const [previousRoute, setPreviousRoute] = useState('/');
  const [searchTerm, setSearchTerm] = useState("");
  const currentUrl = window.location.href;
  const partner = slug.slug;

  const fetchData = async () => {
    try {
      const data = await BioLinkData(partner);
      setCompany(data.company);
      

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if(partner) {
      fetchData();
    }
  }, [partner]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(inputText);
  };

  const handleBackButtonClick = () => {
    // Navigate to the previous route stored in state
    window.location.href = previousRoute;
  };

  return (
    <>
   
<div className=" bg-light p-2">
    <ul class="footerlw">
      <a href={ company.Facebook } target="_blank">
  <li class="icon facebook">
    {/* <span class="tooltip">Facebook</span> */}
    <svg
      viewBox="0 0 320 512"
      height="1.2em"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" 
      ></path>
    </svg>
  </li>
  </a>
  <a href={company.whatsapp_number} target="_blank">
  <li className="icon whatsapp">
    {/* <span className="tooltip">WhatsApp</span> */}
    <svg
    viewBox="0 0 24 24"
    fill="none"
    height="24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    class="w-6 h-6 text-gray-800 dark:text-white"
  >
    <path
      clip-rule="evenodd"
      d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
      fill-rule="evenodd"
      fill="currentColor"
    ></path>
    <path
      d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
      fill="currentColor"
    ></path>
  </svg>
 
</li>
</a>
  
  <a href={ company.Instagram } target="_blank" className="text-decoration-none" >
  <li class="icon instagram">
    {/* <span class="tooltip">Instagram</span> */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1.2em"
      fill="currentColor"
      class="bi bi-instagram"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
      ></path>
    </svg>
  </li>
  </a>
</ul>

    {/* <div  className="text-center mt-5 container-fluid ">
         
          <a href="{{ instance.Instagram }}" target="_blank">
            <i className ="fa-brands fa-instagram icon-type text-color"></i>
          </a>
          
          
          <a href="{{ instance.Facebook }}" target="_blank">
            <i className ="fa-brands fa-facebook icon-type text-color"></i>
          </a>
         
          
          <a href="https://wa.me/91{{ instance.whatsapp_number }}?text=I'm%20interested%20in%20{{ instance.name }}" target="_blank">
            <i className ="fa-brands fa-whatsapp icon-type text-color"></i>
          </a>
         
        </div> */}
        <div className ="text-center" >
          <h6 className ="fw-semibold footer-type text-color" style={{fontFamily: 'Helvetica Neue Light',}}>Powered by : 
          <span class="mb-0" style={{fontFamily: 'Hanken Grotesk',fontWeight: '600'}}><i class="far fa-compass rotate-n-30 pe-1 ps-1" style={{color: '#fd305e', fontSize: '1.2rem'}}></i>
          <span class="pb-2" style={{color: '#fafafa',fontSize: '18px'}}><b><span style={{color: '#211f1c', fontWeight: '800'}}>logout</span><span style={{color: '#211f1c',fontWeight: '800'}}>.studio</span></b></span></span>
          </h6>
        </div>
        </div>
    </>
  );
};

export default Navbar;
