import React, { useEffect, useState, useRef } from 'react'


const About_Event = ({ event }) => {


    return <div>
        <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont" style={{ fontFamily: 'Helvetica Neue', fontSize: '15px' }}> <span dangerouslySetInnerHTML={{ __html: event.About_Tour }}></span></p>
    </div>
}

const Itinerary_Event = ({ event }) => {
    return (
        <>
            {event.addday.map((day, dayIndex) => (


                <div key={dayIndex} className='eventCardfont p-2 rounded mb-2' style={{ fontFamily: 'Helvetica Neue', fontSize: '15px' }}>
                    <p className="mb-1"><b>Day </b>:  <span dangerouslySetInnerHTML={{ __html: day.daysnumber }}></span></p>
                    {day.name && (
                        <p className="mb-1"><b>Highlight : </b><br /><span dangerouslySetInnerHTML={{ __html: day.name }}></span></p>
                    )}
                    {day.details && (
                        <p className="mb-1"><b>Details :</b><br /><span dangerouslySetInnerHTML={{ __html: day.details }}></span></p>
                    )}
                </div>
            ))}
        </>
    )
}

const Inclusions_Event = ({ event }) => {


    return (
        <>
            <div>
                <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont" style={{ fontFamily: 'Helvetica Neue', fontSize: '15px' }}><b><i className="fa-regular fa-circle-check" style={{ color: "#34b233" }}></i><span className="px-2">Inclusion</span></b> <br /><span dangerouslySetInnerHTML={{ __html: event.inclusions }}></span></p>
            </div>
            <div>
                <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont" style={{ fontFamily: 'Helvetica Neue', fontSize: '15px' }}><b><i className="fa-regular fa-circle-xmark" style={{ color: "rgb(178 51 89)" }}></i><span className="px-2">Exclusion</span></b> <br /><span dangerouslySetInnerHTML={{ __html: event.exclusions }}></span></p>
            </div>
        </>
    )
}


const Pricing = ({ event }) => {
    
    const firstBatch = event?.batches[0]
    const date = new Date(firstBatch?.startdate)
    const dates = event.batches.length

    const obj1 = {
        date: date.getDate(),
        month: date.toLocaleString('default', { month: 'short' }),
        day: date.toLocaleString('default', { weekday: 'short' }),
        year: date.getFullYear(),
    }
    return (
        <div className='pb-5'>
            <p style={{ fontFamily: 'Helvetica Neue', fontWeight: '400' }} className="rounded mb-0 eventCardfont">Next upcoming on</p>
            <p className="mb-0"><b style={{ fontFamily: 'Helvetica Neue', fontWeight: '600', fontSize: '15px' }}>{obj1.day} - {obj1.month}. {obj1.date}, {obj1.year}</b></p>
            <div className='mt-2'>
                <span style={{ fontFamily: 'Helvetica Neue', fontSize: '16px', fontWeight: '600' }}>Ticket prices</span>
                {firstBatch.eventprices.length > 0 ? firstBatch.eventprices.map((price, index) => (
                    <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                        <div className='d-flex align-items-center justify-content-center' style={{ gap: '5px' }}>
                            <img width="30" height="30" src={`${process.env.PUBLIC_URL}/ticket.png`} alt="ticket" />
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '400' }}>{price.attribute}</span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '600' }}>&#8377;{Math.round(price.price)}</span>
                        </div>
                    </div>
                ))
                    :
                    event.event_price_types.map((price, index) => (
                        <div className='d-flex justify-content-between align-items-center w-100 mt-2'>
                            <div className='d-flex align-items-center justify-content-center' style={{ gap: '8px', maxWidth: '70%' }}>
                                <img width="30" height="30" src={`${process.env.PUBLIC_URL}/ticket.png`} alt="ticket" />
                                <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '400' }}>{price.attribute}</span>
                            </div>
                            <div>
                                <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '600' }}>&#8377;{Math.round(price.price)}</span>
                            </div>
                        </div>
                    ))}
                {
                    firstBatch.eventprices.length == 0 && event.event_price_types.length == 0 && <div>
                        <span style={{fontFamily: 'Helvetica Neue'}}>Starting from <span style={{fontFamily: 'Helvetica Neue', fontWeight: '600', fontSize: '18px'}}>{event.price}</span></span>
                    </div>
                }
            </div>
           {/* {dates>1 && <a href="javascript:void(0);" type="button" className="mt-2" data-bs-toggle="modal" data-bs-target="#datesModal">  See all dates and pricing</a>} */}
           {dates>1 && <button data-bs-toggle="modal" data-bs-target="#datesModal" type="button" className='mt-4 d-block alldates m-auto' >All Dates & Prices</button>}
            <span className='terms mt-1 m-auto d-block text-center'>*Prices may vary depending on dates</span>
        </div>
    )
}

const NavigationTab = ({ event }) => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [indicatorStyle, setIndicatorStyle] = useState({});

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 30

    const tabIndicatorRef = useRef(null);






    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) {
            setActiveTab(tabs[tabs.indexOf(activeTab) + (isLeftSwipe ? 1 : -1)] || activeTab)
        }
        // add your conditional logic here
    }

    useEffect(() => {
        let presentTabs = [];
        if (event.About_Tour) {
            presentTabs.push('About');
        }
        if (event.addday.length > 0) {
            presentTabs.push('Itinerary');
        }
        presentTabs.push('Pricing')
        if (event.inclusions) {
            presentTabs.push('Inclusions');
        }
        setTabs(presentTabs);
        setActiveTab(presentTabs[0]);
    }, [])
    return (
        <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <div>
                <ul className='tabs_list'>
                    {tabs.map((tab, index) => (

                        <li style={{ fontFamily: 'Helvetica Neue Medium', cursor: 'pointer' }} key={tab} className={` ${activeTab == tab ? `activetab` : ''}`} onClick={() => { console.log(tab); setActiveTab(tab) }}>{tab}</li>


                    ))}
                    {/* <div className='tab_indicator' style={{ left: `${tabs.indexOf(activeTab) * 25}%` }}></div> */}
                    {/* <span className='tab_indicator'></span> */}
                </ul>
            </div>
            <div>
                {activeTab === 'About' && <About_Event event={event} />}
                {activeTab === 'Itinerary' && <Itinerary_Event event={event} />}
                {activeTab === 'Inclusions' && <Inclusions_Event event={event} />}
                {activeTab === 'Pricing' && <Pricing event={event} />}
            </div>
        </div>
    )
}

export default NavigationTab
