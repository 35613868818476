import React, { useEffect, useState } from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import {
  MdAccessTime,
  MdOutlineAccessTime,
  MdOutlineRemoveCircleOutline,
  MdSyncLock,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../UI/Button";
import Input, { RadioButtonGroup } from "../UI/Input";
import SecondaryButton from "../UI/SecondaryButton";
import moment from "moment";
import { useSelector } from "react-redux";
import { DatesModal } from "../UI/Modal";
import { FaCheck } from "react-icons/fa6";
import BasicTimePicker from "../components/MobileTime";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SecondaryOutline from "../UI/SecondaryOutline";
import {IoCloseOutline} from "react-icons/io5";
import {useMediaQuery} from "react-responsive";

export const DateInput = ({
  key,
  index,
  arrayIndex,
  removeDate,
  setFormData,
  storedFormData,
  days,
  nights,
  id,
  repeats
}) => {
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState();
  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const isMobile = useMediaQuery({maxWidth: '768px'})
  console.log("index",index)
  useEffect(() => {
    if (storedFormData.dates.length > 0) {
      console.log("here");
      setStartDateValue(storedFormData.dates[index]?.startdate);
      setEndDateValue(moment(storedFormData.dates[index]?.enddate).format("MMM DD, YYYY"));
      const start = new Date();
      if (storedFormData?.dates[index]?.starttime) {
        const existing = storedFormData?.dates[index]?.starttime?.split(":");
        console.log("existing", existing);
        if (existing) {
          start.setHours(existing[0]);
          start.setMinutes(existing[1]);
          setStartValue(start);

        } else {
          setStartValue(null);
        }
        console.log(start);
      } else {
        setStartValue(null);
      }

      const end = new Date();
      if (storedFormData?.dates[index]?.endtime) {
        const existingEnd = storedFormData?.dates[index]?.endtime?.split(":");
        if (existingEnd) {
          end.setHours(existingEnd[0]);
          end.setMinutes(existingEnd[1]);
          setEndValue(end);
        } else {
          setEndValue(null);
        }
      } else {
        setEndValue(null);
      }

      console.log(storedFormData.dates.slice(1));
     
      console.log(storedFormData);
      
    }
    
  }, [storedFormData]);

  const updateEndDate = (value) => {
    if(days){
          const k = value
          k.add(days-1, 'days')
          console.log(moment(k.format()).format("YYYY-MM-DD"))
        setEndDateValue(k);
        
        const name = "enddate"
        setFormData((prevData) => {
          const newDate = {
            ...prevData.dates.find((item) => item.id === index),
            id: index,
            [name]: name.includes("date")
              ? moment(value.format()).format("YYYY-MM-DD")
              : value,
          };
          console.log("new", newDate);
    
          const updatedDates = prevData.dates.some((item) => item.id === index)
            ? prevData.dates.map((item) => (item.id === index ? newDate : item))
            : [...prevData.dates, newDate];
          console.log(prevData);
          console.log(updatedDates);
    
          return { ...prevData, dates: updatedDates };
        });
      }
  }




  var obj = {};
  const handleChange = (value, name) => {
    console.log("called");
    if (name == "startdate") {
      setStartDateValue(value);
      setTimeout(() => updateEndDate(value), 0);
    }
    if (name == "enddate") {
      setEndDateValue(value);
    }
    if (name == "starttime") {
      setStartValue(value);
    }
    if (name == "endtime") {
      setEndValue(value);
      
    }
    setFormData((prevData) => {
      const newDate = {
        ...prevData.dates.find((item) => item.id === index),
        id: index,
        [name]: name.includes("date")
          ? moment(value.format()).format("YYYY-MM-DD")
          : value,
      };
      console.log("new", newDate);

      const updatedDates = prevData.dates.some((item) => item.id === index)
        ? prevData.dates.map((item) => (item.id === index ? newDate : item))
        : [...prevData.dates, newDate];
      console.log(prevData);
      console.log(updatedDates);

      return { ...prevData, dates: updatedDates };
    });
  };

  return (
    <div className="pt-3">
      <div className="grid relative grid-cols-2 text-left grid-rows-2 place-items-start border border-[#dad3c3] px-[19.5px] py-[14px] rounded-xl border-dashed lg:grid-cols-2 lg:grid-rows-1 gap-y-2 gap-x-2 lg:gap-x-2">
        <div className="">
          <label
            htmlFor="opendate"
            class="block text-sm input_label_padding font-medium text-gray-900"
          >
            Start date
          </label>
          <DatePicker
            format="MMM DD, YYYY"
            render={
              <InputIcon placeholder={'Select date'} className="lg:w-[180px] w-[100%] focus-within:border-[#B5B0A1] focus:outline-none text-[13px] border px-[13px] py-[9.570px] rounded-xl" />
            }
            placeholder="Select date"
            value={moment(startDateValue).format("MM DD, YYYY")}
            onChange={(value) => {
              handleChange(value, "startdate");
            }}
            id="opendate"
          />
        </div>
        <div className="">
          <label
            htmlFor="opendate"
            class="block text-sm input_label_padding font-medium text-gray-900"
          >
            End date
          </label>
          <DatePicker
            format="MMM DD, YYYY"
            render={
              <InputIcon placeholder={'Select date'}  className="lg:w-[180px] w-[100%] focus-within:border-[#B5B0A1] focus:outline-none text-[13px] border px-[13px] py-[9.570px] rounded-xl" />
            }
            placeholder="Select date"
            value={endDateValue}
            onChange={(value) => {
              handleChange(value, "enddate");
            }}
            id="closedate"
          />
        </div>
        <div class="flex flex-col">
          <label for="start-time" class="text-sm input_label_padding font-medium text-gray-700">
            Start time
          </label>

          <BasicTimePicker
            label="starttime"
            value={startValue}
            handleChange={handleChange}
            // width={isMobile && '160px'}
            // width={'160px'}
          />
        </div>
        <div class="flex flex-col">
          <label for="end-time" class="text-sm input_label_padding font-medium text-gray-700">
            End time
          </label>

          <BasicTimePicker
            label="endtime"
            value={endValue}
            handleChange={handleChange}
            // width={isMobile && '160px'}
            // width={'160px'}
          />
        </div>
       {!repeats && <div onClick={() => removeDate(index)} className='absolute cursor-pointer lg:top-4 lg:right-5 top-2 right-2'>
          <IoCloseOutline size={25} className="text-red-500"/>
        </div>
}
      {/*  <div className="col-span-2">*/}
      {/*<SecondaryButton*/}
      {/*  handleClick={() => removeDate(index)}*/}
      {/*  text={"Remove"}*/}
      {/*  classes="mb-4 py-0 ml-1 text-center m-auto hidden lg:flex"*/}
      {/*  />*/}
      {/*  </div>*/}
    </div>
    {/*<SecondaryButton*/}
    {/*    handleClick={() => removeDate(index)}*/}
    {/*    text={"Remove"}*/}
    {/*    classes="mb-4 py-0 ml-1 w-full text-center flex lg:hidden m-auto"*/}
    {/*    />*/}
      </div>
  );
};

const DateForm = ({ formData, setFormData, setRepeatedDays }) => {
  const [dateOptionSelected, setDateOptionSelected] = useState("custom");
  const [dateInput, setDateInput] = useState([0]);
  const [counter, setCounter] = useState(1);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [tillDate, setTillDate] = useState(null);
  const [selDates, setSelDates] = useState([]);
  const [datesVisible, setDatesVisible] = useState(false);
  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const [isHourlyEvent, setIsHourlyEvent] = useState(false);

  const storedFormData = useSelector((state) => state.formData);
  const [days, setDays] = useState(storedFormData.number_of_days);
  const [nights, setNights] = useState(storedFormData.number_of_nights);
  const [hours, setHours] = useState(storedFormData.number_of_hours);
  const isEdit = useSelector((state) => state.isEdit);
  const [filter, setFilter] = useState("all");

  const days_of_week = [
    { name: "Mon", type: "weekday" },
    { name: "Tues", type: "weekday" },
    { name: "Wed", type: "weekday" },
    { name: "Thur", type: "weekday" },
    { name: "Fri", type: "weekday" },
    { name: "Sat", type: "weekend" },
    { name: "Sun", type: "weekend" },
  ];

  const timeOptions = [
    { value: "hourly", label: "Hourly" },
    { value: "multiday", label: "Multi-day" },
  ]

  const isMobile = useMediaQuery({maxWidth: '768px'})
  const [selectedDays, setSelectedDays] = useState(
    days_of_week.map((day) => day.name)
  );

  useEffect(() => {
    setRepeatedDays(selectedDays);
  }, [selectedDays]);

  // Helper to toggle days
  const toggleDays = (filter) => {
    setFilter(filter);
    if (filter === "all") {
      setSelectedDays(days_of_week.map((day) => day.name));
    } else {
      setSelectedDays(
        days_of_week.filter((day) => day.type === filter).map((day) => day.name)
      );
    }
  };

  const repeatOptions = [
    { value: "all", label: "All" },
    { value: "weekday", label: "Weekdays" },
    { value: "weekend", label: "Weekend" },
  ];

  // Handle individual checkbox toggle
  const toggleDay = (dayName) => {
    setSelectedDays((prev) =>
      prev.includes(dayName)
        ? prev.filter((day) => day !== dayName)
        : [...prev, dayName]
    );
  };


  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setSelectedFrequency(e.target.value);
    setFormData((prevData) => {
      return {
        ...prevData,
        repeatType: e.target.value,
      };
    });
    console.log(e.target.value);
  };

  useEffect(() => {
    console.log(startValue);
  }, [startValue]);

  useEffect(() => {
    console.log("running")
    if (storedFormData.dates.length > 0) {
      console.log("here");
      setStartDateValue(storedFormData.dates[0]?.startdate);
      setEndDateValue(moment(storedFormData.dates[0]?.enddate).format("MMM DD, YYYY"));
      const start = new Date();
      if (storedFormData?.dates[0]?.starttime) {
        const existing = storedFormData?.dates[0]?.starttime?.split(":");
        console.log("existing", existing);
        if (existing) {
          start.setHours(existing[0]);
          start.setMinutes(existing[1]);
          setStartValue(start);
        } else {
          setStartValue(null);
        }
        console.log(start);
      } else {
        setStartValue(null);
      }

      const end = new Date();
      if (storedFormData?.dates[0]?.endtime) {
        const existingEnd = storedFormData?.dates[0]?.endtime?.split(":");
        if (existingEnd) {
          end.setHours(existingEnd[0]);
          end.setMinutes(existingEnd[1]);
          setEndValue(end);
        } else {
          setEndValue(null);
        }
      } else {
        setEndValue(null);
      }
    }

     
      console.log(storedFormData);
      if (storedFormData) {
        setDays(storedFormData.number_of_days);
        setNights(storedFormData.number_of_nights);
        setHours(storedFormData.number_of_hours);
        if(storedFormData.number_of_days==null && storedFormData.number_of_nights==null && storedFormData.number_of_hours!=null){
          setIsHourlyEvent(true)
      }
    } 

  }, [storedFormData]);

  const closeModal = () => {
    setDatesVisible(false);
  };

  useEffect(()=>{
    console.log(formData)
  },[formData])




  const updateEndDate = (value) => {
    if(days){
          const k = value
          k.add(days-1, 'days')
          console.log(moment(k.format()).format("YYYY-MM-DD"))
        setEndDateValue(k);
        
        const name = "enddate"
        setFormData((prevData) => {
          console.log("prev", prevData);
          const newDate = {
            ...prevData.dates.find((item) => item.id === formData?.dates[0].id),
            id: formData?.dates[0].id,
            [name]: name.includes("date")
              ? moment(k.format()).format("YYYY-MM-DD")
              : k,
          };
          console.log("date", newDate);
          const updatedDates = prevData.dates.some(
            (item) => item.id === formData?.dates[0].id
          )
            ? prevData.dates.map((item) =>
                item.id === formData?.dates[0].id ? newDate : item
              )
            : [...prevData.dates, newDate];
    
          return { ...prevData, dates: updatedDates };
        });
      }
  }



  const handleChange = (value, name) => {
    console.log("clalled")
    if (name == "startdate") {
      console.log(value)
      setStartDateValue(value);
      setTimeout(() => updateEndDate(value), 0);
  }
    if (name == "enddate") {
      console.log(value,"enddate")
      setEndDateValue(value);
    }
    if (name == "starttime") {
      setStartValue(value);
    }
    if (name == "endtime") {
      console.log("end", value);
      setEndValue(value);
    }
    setFormData((prevData) => {
      console.log("prev", prevData);
      const newDate = {
        ...prevData.dates.find((item) => item.id === formData?.dates[0].id),
        id: formData?.dates[0].id,
        [name]: name.includes("date")
          ? moment(value.format()).format("YYYY-MM-DD")
          : value,
      };
      console.log("date", newDate);
      const updatedDates = prevData.dates.some(
        (item) => item.id === formData?.dates[0].id
      )
        ? prevData.dates.map((item) =>
            item.id === formData?.dates[0].id ? newDate : item
          )
        : [...prevData.dates, newDate];

      return { ...prevData, dates: updatedDates };
    });
  };

  useEffect(() => {
    const reps = dateOptionSelected == "repeat" ? true : false;
    setFormData((prevData) => {
      return {
        ...prevData,
        repeats: reps,
      };
    });
  }, [dateOptionSelected]);

  useEffect(() => {
    setFormData((prevData) => {
      return {
        ...prevData,
        number_of_days: days,
        number_of_nights: nights,
        number_of_hours: hours,
      };
    });
  }, [days, nights, hours]);

  const dateOptions = [
    { value: "custom", label: "Custom dates" },
    { value: "repeat", label: "Repeat" },
  ];

  const addDate = () => {
    dateInput.push(counter);
    setDateInput([...dateInput]);
    setCounter(counter + 1);
  };

  useEffect(() => {
    console.log("form", formData);
  }, [formData]);

  const removeDate = (index) => {
    // if (dateInput.length === 1) {
    //   console.log("Cannot remove the last price.");
    //   return;
    // }

    setDateInput(dateInput.filter((idx) => idx != index));
    setFormData((prevData) => ({
      ...prevData,
      dates: prevData.dates.filter((item) => item.id !== index),
    }));
  };



  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ opacity: { duration: 0.75 }, y: { duration: 0.5 } }}
      >
        <div className=" px-6 py-4 pt-0 min-h-[80vh] pb-20">
          <div className="py-4 mt-4 lg:mt-0 lg:section_padding_lg align-baseline grid grid-rows-1 lg:grid-cols-3">
            <h2 className="section_heading mb-2 lg:mb-0">Duration*</h2>
            <div className="flex flex-col w-full col-span-2">
            <div className="pb-3">
                {/* <label className="flex items-center hover:bg-[#DDF8FC] hover:border-black cursor-pointer px-[19.5px] py-[14px] rounded-xl border-[1px] border-dashed border-[#dad3c3]">
                  <input
                    type="checkbox"
                    name="free"
                    checked={isHourlyEvent}
                    value={isHourlyEvent ? "free" : "paid"}
                    className="sr-only peer"
                    onChange={() => setIsHourlyEvent(!isHourlyEvent)}
                  />
                  <div className="w-5 h-5 rounded-md bg-[#f6f1e9] flex justify-center items-center peer-checked:bg-[#045540] peer-checked:text-white">
                    {isHourlyEvent && <FaCheck size={15} />}
                  </div>
                  <span className="text-black ml-4">Hourly Event</span>
                </label> */}
                <RadioButtonGroup columns={2} options={timeOptions} selectedValue={isHourlyEvent ? "hourly" : "multiday"} onChange={(value) => {value=='hourly' ? setIsHourlyEvent(true) : setIsHourlyEvent(false)}} />
              </div>
            <div className="flex flex-col lg:flex-row w-full gap-2 pt-2">

              <div className={`basis-full ${isHourlyEvent ? 'hidden' : ''}`}>
                <label className="text-sm input_label_padding  font-medium">Day(s)</label>
                <Input
                  placeholder="Day(s)"
                  type="number"
                  handleChange={(e) => {
                    setDays(e.target.value);
                  }}
                  value={days}
                  classes={'w-full'}
                  required={!isHourlyEvent}
                />
              </div>
              <div className={`basis-full ${isHourlyEvent ? 'hidden' : ''}`}>
                <label className="text-sm input_label_padding  font-medium">Night(s)</label>
                <Input
                  placeholder="Night(s)"
                  type="number"
                  handleChange={(e) => {
                    setNights(e.target.value);
                  }}
                  value={nights}
                  classes={'w-full'}
                  required={!isHourlyEvent}
                />
              </div>

              <div className={`${isHourlyEvent ? 'lg:w-[50%] col-span-1': ''}`}>
                <label className="text-sm input_label_padding  font-medium">Hour(s)</label>
                <Input
                  placeholder="Hour(s)"
                  type="number"
                  handleChange={(e) => {
                    setHours(e.target.value);
                  }}
                  value={hours}
                  classes={'w-full'}
                  required={isHourlyEvent}
                />
              </div>
            </div>
            </div>
          </div>
          <div className="section_padding_sm grid lg:grid-cols-3 lg:section_padding_lg">
            <h2 className="section_heading">Open dates</h2>
            <div className="flex flex-col gap-0 col-span-2">
            {isEdit == false && (
            <div class=" mb-3">
              <RadioButtonGroup
              columns={2}
                options={dateOptions}
                selectedValue={dateOptionSelected}
                onChange={(value) => {
                  setDateOptionSelected(value);
                }}
                name="dateOptions"
              />
            </div>
          )}

            
          {dateOptionSelected == "custom" && (
            <div className="flex flex-col gap-0">
              {dateInput.map((date, index) => (
                <DateInput
                  key={date}
                  index={date}
                  arrayIndex={index}
                  removeDate={removeDate}
                  setFormData={setFormData}
                  storedFormData={storedFormData}
                  id={date}
                  days={days}
                  nights={nights}
                />
              ))}
              <div className="w-full mt-3 text-center">
                {isEdit && storedFormData?.dates?.length > 1 && (
                  <Button
                    handleClick={() => {
                      setDatesVisible(true);
                    }}
                    text="View all dates"
                    classes="mt-0 mb-3 mx-auto"
                  />
                )}
              </div>
              {datesVisible && (
                <DatesModal
                  closeModal={closeModal}
                  setFormData={setFormData}
                  dates={formData?.dates}
                  removeDate={removeDate}
                />
              )}
              {isEdit == false &&
                storedFormData.dates.length > 1 &&
                storedFormData.dates
                  .slice(1)
                  .map((date, index) => (
                    <DateInput
                      key={date.id}
                      index={date.id}
                      arrayIndex={index}
                      removeDate={removeDate}
                      setFormData={setFormData}
                      storedFormData={storedFormData}
                      id={date.id}
                    />
                  ))}
              {/* <button type="button" onClick={()=>{setDatesVisible(true)}}>All dates</button> */}

              {/* <Button
                handleClick={addDate}
                text={"Add another date"}
                classes="mt-2 mx-auto"
              ></Button> */}
              <SecondaryOutline text={"Add another date"} handleClick={addDate} classes="mt-0 text-left"/>
            </div>
          )}
          {dateOptionSelected == "repeat" && (
            <div className="flex flex-col">
              <DateInput repeats={true} key={'0'} index={0} arrayIndex={0} removeDate={removeDate} setFormData={setFormData} storedFormData={storedFormData}/>
              <div className="mt-3 lg:pb-4 grid grid-cols-2 lg:flex gap-2">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="countries"
                    class="block text-sm input_label_padding font-medium text-gray-900"
                  >
                    Repeats
                  </label>
                  <select
                    id="countries"
                    onChange={handleSelectChange}
                    class="border font-medium text-black text-[13px] rounded-xl bg-white focus:outline-none block px-[13px] py-[9.570px]"
                  >
                    <option className="font-medium" defaultChecked>
                      Choose frequency
                    </option>
                    <option value="everyday">Everyday</option>
                    <option value="everyweek">Every week</option>
                  </select>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="tilldate"
                    class="block text-sm input_label_padding font-medium text-gray-900"
                  >
                    Till
                  </label>
                  <DatePicker
                    id="tilldate"
                    format="MMM DD, YYYY"
                    render={
                      <InputIcon placeholder={"Select date"} className="lg:w-[180px] w-[100%] focus-within:border-[#B5B0A1] focus:outline-none text-[13px] border px-[13px] py-[9.570px] rounded-xl" />
                    }
                    placeholder="Select date"
                    value={moment(tillDate).format("MMM DD, YYYY")}
                    onChange={(value) => {
                      console.log(value.format());
                      console.log(moment(value.format()).format("YYYY-MM-DD"));
                      setTillDate(moment(value.format()).format("YYYY-MM-DD"));
                      setFormData((prevData) => {
                        return {
                          ...prevData,
                          tilldate: moment(value.format()).format("YYYY-MM-DD"),
                        };
                      });
                    }}
                  />
                </div>
              </div>
              {selectedFrequency == "everyweek" && (
                <div className="lg:py-4">
                  <div className="my-3 lg:my-0">
                    <RadioButtonGroup
                    columns={3}
                      options={repeatOptions}
                      selectedValue={filter}
                      name="repeatOptions"
                      onChange={(value) => {
                        toggleDays(value);
                      }}
                    />
                    {/* <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginRight: "10px",
                      }}
                      onClick={() => toggleDays("all")}
                    >
                      All
                    </span> */}

                    {/* <SecondaryButton text={"All"} handleClick={() => toggleDays("all")} classes={`pl-1 border-r-2 rounded-none py-3`}/> */}
                    {/* <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginRight: "10px",
                      }}
                      onClick={() => toggleDays("weekday")}
                    >
                      Weekdays
                    </span> */}
                    {/* <SecondaryButton text={"Weekdays"} handleClick={() => toggleDays("weekday")} classes={`border-r-2 rounded-none`}/> */}
                    {/* <span
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => toggleDays("weekend")}
                    >
                      Weekend
                    </span> */}
                    {/* <SecondaryButton text={"Weekend"} handleClick={() => toggleDays("weekend")} classes={``}/> */}
                  </div>
                  <div className="flex gap-2 flex-wrap py-0 lg:py-4">
                    {/* {
                      days_of_week.map((day)=>(
                        <span className={`w-[25px] h-[25px] ${selectedDays.includes(day.name) ? 'bg-[#4f55da] text-white' : ''} flex justify-center items-center text-sm rounded-full bg-gray-300`}>{day.name[0]}</span>
                      ))
                    } */}
                    {days_of_week.map((day) => (
                      <span
                        onClick={() => {
                          toggleDay(day.name);
                        }}
                        className={`w-[54px] h-[24px] items-center ${
                          selectedDays.includes(day.name)
                            ? "bg-[#045540] text-white"
                            : "bg-[#f6f1e9]"
                        } flex justify-center  text-sm rounded-full`}
                      >
                        {day.name}
                      </span>
                    ))}
                    {/* {days_of_week.map((day) => (
                        <label className="flex items-center space-x-1 cursor-pointer">
                        <input
                          type="checkbox"
                          name="free"
                          checked={selectedDays.includes(day.name)}
                          
                          className="sr-only peer"
                          onChange={() => toggleDay(day.name)}
                        />
                        <div className="w-5 h-5 rounded-md bg-[#f8f6f2] border-2 flex justify-center items-center peer-checked:bg-[#045540] peer-checked:text-white">
                          {selectedDays.includes(day.name) && <FaCheck size={15} />}
                        </div>
                        <span className="text-black">{day.name}</span>
                      </label>
                     
                    ))} */}
                  </div>
                </div>
              )}
            </div>
          )}
            </div>
          </div>
          

          
          
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DateForm;
