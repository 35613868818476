import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputAdornment } from "@mui/material";
import { MdAccessTime } from "react-icons/md";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {Box} from "@mui/material";
import {DialogActions} from "@mui/material";
import Button from "../UI/Button";
import SecondaryOutline from "../UI/SecondaryOutline";
import BackButton from "../UI/BackButton";
import { FaLeaf } from "react-icons/fa6";

const theme = createTheme({
  components: {
    MuiClock: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: "#045540",
            color: "white",
          },
          "& .MuiClockPointer-root":{
            backgroundColor: "#045540",
          },
          "& .MuiClock-pinThumb": {
                backgroundColor: "orange", // Change thumb color
            },
          

        },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
          
            "& .MuiPickersLayout-root":{
              display: "flex",
              flexDirection: "column",
            }
          },
        },
      }
    },
});

export default function BasicTimePicker({
  isOpen,
  setIsTimePickerOpen,
  value,
  handleChange,
  label,
  id,
  width
}) {
 
  const [selectedTime, setSelectedTime] = React.useState(
    value ? dayjs(value) : null
  );

  React.useEffect(() => {

  
    if (value) {
      let date;
      try {
        // Check if value is a Date object
        if (value instanceof Date) {
          const hrs = value.getHours();
          const mins = value.getMinutes();
          date = dayjs().hour(hrs).minute(mins);
        } else {
          // If value is in a different format (e.g., a string), parse it using dayjs
          date = dayjs(value);
          if (!date.isValid()) throw new Error("Invalid date format");
        }
  
       
        setSelectedTime(date);
      } catch (err) {
        // console.error("Error parsing value:", err);
      }
    }
  }, [value]);



  const handleChangeTime = (newValue) => {
    console.log(newValue)
    if (newValue) {
      console.log("ampm",newValue.format("A"));
      const formattedTime = newValue.format("HH:mm");
      console.log("Selected Time:", formattedTime);
      // setSelectedTime(newValue);
      if(id){

        handleChange(formattedTime, label,id);
      }
      else{
        handleChange(formattedTime, label);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        // ampm={false}
        ampm={true}
        
        // format="hh:mm"
        value={selectedTime}
        onChange={(newValue) => handleChangeTime(newValue)}
        slotProps={{
          
          textField: {
            placeholder: "Select time",
            variant: "standard",
            InputProps: {
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="center">
                  <MdAccessTime size={20} className="text-black" />
                </InputAdornment>
              ),
              className: "border-none",
            },
            sx: {

              "& .MuiInputBase-root": {
                display: "flex",
                alignItems: "center",
                padding: "8.57px 4px 9.57px 13px", // Adjust the padding here
                border: "1px solid #e5e7eb",
                borderRadius: "0.75rem",
                background: "white",
                width: width ? width : {
                  xs: '100%', // Small screens
                  sm: "180px", // Medium screens
                  lg: "180px", // Large screens

                },
                fontSize: "13px", 
                cursor: 'pointer'// Adjust the padding here
              },
              "& .MuiInputBase-root:focus": {
                border: "1px solid #B5B0A1",
              },
              "& .MuiInputBase-input": {
                padding: "0",
                border: "none",
                fontFamily: "Hanken Grotesk",
                color: "black", // Adjust the inner input padding here
              },
             
            },
            className: "border-none",
          },
        }}
        
        
       
        slots={{
          actionBar: MyActionBar,
        }}
      />
    </LocalizationProvider>
    </ThemeProvider>
  );
}

const MyActionBar = ({
  onAccept,
  onCancel,
  onClear,
  onSetToday,
}) => {

  return (
    <div className="flex justify-between w-full py-4 px-6">
     <BackButton text="Cancel" handleClick={onCancel} />
     <Button text="Done" handleClick={onAccept} />
    </div>
  );
};