import React, { useEffect, useState, useRef } from 'react'
import './styles.css'
import Stepper from './Stepper'
import FormControl from './FormControl'
import { Card } from 'reactstrap'
import MediaForm from './FormSteps/MediaForm'
import DetailsForm from './FormSteps/DetailsForm'
import PricingForm from './FormSteps/PricingForm'
import DateForm from './FormSteps/DateForm'
import LaunchForm from './FormSteps/LaunchForm'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { clearStore, setCompanyName, setEventSlug, setIsEdit, updateFormData, updateFormDataFiles, updateFormStep } from './redux/rootSlice'
import { CgDetailsMore } from "react-icons/cg";
import { GoFileMedia } from "react-icons/go";
import { CiMoneyCheck1 } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import { FaRegCompass } from "react-icons/fa";
import { FiHeart } from "react-icons/fi";
import { FaRegCreditCard } from "react-icons/fa6";
import { useForm } from "react-hook-form"
import moment from 'moment'
import { clearState } from './redux/utils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { act } from 'react'
import { isFluxStandardAction } from '@reduxjs/toolkit'
import { LaunchModal } from './UI/Modal'

const EventForm = () => {
    const steps = ['Basics', 'Details', 'Pricing', "Dates", 'Launch']
    const steps_images = [<GoFileMedia className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />, <FiHeart className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />, <FaRegCreditCard className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />, <IoCalendarOutline className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />, <FaRegCompass className='w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />]
    const step = useSelector(state => state.activeFormStep)
    const [activeStep, setActiveStep] = useState(step || 0)
    const isDesktop = useMediaQuery({ query: '(min-width: 1022px)' })
    const [isLoading, setIsLoading] = useState(false)
    const [salesTeam, setSalesTeam] = useState([])
    const [instructors, setInstructors] = useState([])

    const dispatch = useDispatch()
    var fd = new FormData();
    const { companyname, slug } = useParams();

    const navigate = useNavigate()

    useEffect(() => {

        dispatch(setCompanyName(companyname))
        if (slug) {
            console.log("found", slug)
            dispatch(setEventSlug(slug))
            dispatch(setIsEdit(true))
            getEvent(slug)
        }
    }, [slug])

    useEffect(()=>{},[])




    const isEdit = useSelector(state => state.isEdit)

    const eventSlug = useSelector(state => state.eventSlug)
    // const companyname = useSelector(state => state.companyname)

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [isLaunched,setIsLaunched] = useState(false)  
    const storedFormData = useSelector(state => state.formData)
    const [formData, setFormData] = useState(storedFormData && Object.keys(storedFormData).length > 0 ? storedFormData : {
        'About_Tour': '',
        'who_for': '',
        'name': '',
        'event_type': false,
        'hosts': [],
        'free': false,
        'price': null,
        'price_striked': null,
        'gst_percentage': null,
        'price_includes_gst': false,
        
        'inclusions': '',
        'exclusions': '',
        'customer_pay': false,
        'sales_representative': [],
        'number_of_days': null,
        'number_of_nights': null,
        'number_of_hours': null,
        'repeats': false,
        'repeatType': null,
        'variablePrices': [{
            "temp": "temp"
        }],
        'addonPrices': [
            {
                "temp": "temp"
            }
        ],
        "pickuppoints": [{
            "temp": "temp"
        }],
        'droppoints': [{
            "temp": "temp"
        }],
        'dates': [],
        'eventId': null,
        'tilldate': null
    })

    const formFiles = useSelector(state => state.formDataFiles)
    const [formDataFiles, setFormDataFiles] = useState(formFiles && Object.keys(formFiles).length ? formFiles : {
        coverImage: null,
        eventImages: [],
        coverVideo: null,
        deletedPhotos: []
    })

    const [repeatedDays, setRepeatedDays] = useState([])

    const getEvent = async (slug) => {

        const eventSlug1 = slug || eventSlug
        const res = await fetch(`${process.env.REACT_APP_API_URL}/tours/api/${eventSlug1}/`)
        if (!res.ok) {
            throw new Error("Error in fetching event")
        }
        const data = await res.json()
        console.log("data", data)
        data.variablePrices = data.eventprice
        data.addonPrices = data.addoneventprice
        data.batches.map((batch, index) => {
            batch.starttime = moment(batch.starttime).format('HH:mm')
            batch.endtime = moment(batch.endtime).format('HH:mm')
        })
        data.dates = data.batches
        data.addonPrices.map((addon, index) => {
            addon.price = Math.round(addon.price)
        })

        data.variablePrices.map((price, index) => {
            price.price = Math.round(price.price)
        })
        data.addonPrices.map((price, index) => {
            price.price = Math.round(price.price)
            price.image = {
                file: null,
                preview: price.image
            }
        })
        var eventImages = []
        data?.gallery?.map((image, index) => {
            eventImages.push({
                file: null,
                preview: image.url,
                id: image.id
            })
        })
        data.eventImages = eventImages
        data.price = data.price == null ? data.price : Math.round(data.price)
        data.price_striked = data.price_striked == null ? data.price_striked : Math.round(data.price_striked)
        data.gst_percentage = data.gst_percentage == null ? data.gst_percentage : Math.round(data.gst_percentage)
        data.salesRep = data.sales_representative
        data.event_type = data.offline_event
        

        console.log("data", data)

        setFormData(data)
        const coverImage = {
            file: null,
            preview: data.cover_pic
        }
        const coverVideo = {
            file: null,
            preview: data.cover_video
        }
        const eventImgs = data.eventImages
        dispatch(updateFormDataFiles({ activeStep: activeStep, formDataFiles: { coverImage: { file: null, preview: data.cover_pic }, eventImages: data.eventImages, coverVideo: coverVideo } }))

        setFormDataFiles({ coverImage: coverImage, eventImages: eventImgs, coverVideo: coverVideo, deletedPhotos: [] })
        // dispatch(updateFormDataFiles({ activeStep: activeStep, formDataFiles: formDataFiles }))
        dispatch(updateFormData({ activeStep: activeStep, formData: data }))


    }


    const processForm = async () => {
        console.log("processing form",formData)
        for (var key in formData) {
            fd.append(key, formData[key]);
        }

        if (eventSlug || formData.id) {
            if (formDataFiles?.coverImage?.file) {
                fd.set("cover_pic", formDataFiles.coverImage.file);
            } else if (formFiles?.coverImage?.preview) {
                fd.set("cover_pic", formFiles.coverImage.preview);
            }
        } else {
            if (formDataFiles?.coverImage?.file) {
                fd.set("cover_pic", formDataFiles.coverImage.file);
            }
        }

        if (formDataFiles.eventImages) {
            formDataFiles?.eventImages?.map((image, index) => {
                fd.append("eventImages", image.file);
            });
        } else {
            formFiles?.eventImages?.map((image, index) => {
                fd.append("eventImages", image.preview);
            });
        }

        if (eventSlug || formData.id) {
            if (formDataFiles?.coverVideo?.file) {
                fd.set("cover_video", formDataFiles.coverVideo.file);
            } else if (formFiles?.coverVideo?.preview) {
                fd.set("cover_video", formFiles.coverVideo.preview);
            }
        } else {
            if (formDataFiles?.coverVideo?.file) {
                fd.set("cover_video", formDataFiles.coverVideo.file);
            }
        }

        // fd.append("hosts", formData.hosts);
        for (var key in formData.variablePrices) {
            for (var key2 in formData.variablePrices[key]) {
                if (key != "id") {
                    fd.append(key + "_price_" + key2, formData.variablePrices[key][key2]);
                }
            }
        }
        for (var key in formData.addonPrices) {
            for (var key2 in formData.addonPrices[key]) {
                console.log(key2, " : ", formData.addonPrices[key][key2]);
                if (key2 == "image") {
                    fd.append(key + "_addon_" + key2, formData.addonPrices[key][key2]?.file);
                } else {
                    fd.append(key + "_addon_" + key2, formData.addonPrices[key][key2]);
                }
            }
        }

        const hosts = formData?.hosts?.length > 0 && formData?.hosts?.map((option) => option).join(",");
        const sales = formData?.salesRep?.length > 0 && formData?.salesRep?.map((option) => option).join(",");
        const categories = formData?.events_category?.length > 0 && formData?.events_category?.map((option) => option).join(",");
        fd.append("hosts", hosts);
        fd.append("sales_representative", sales);
        fd.append("events_category", categories);
        fd.append("numberofprice", formData?.variablePrices?.length);
        fd.append("numberofaddonprice", formData?.addonPrices?.length);
        fd.append("numberofeventpickuppoint", formData?.eventpickuppoint?.length);
        fd.append("numberofeventdroppoint", formData?.eventdroppoint?.length);
        fd.append("numberofbatch", formData?.dates?.length);
        fd.append('numberofdays', 0)


        fd.append("companyname", companyname);

        console.log("fd", [...fd]);

        if (!eventSlug && !formData.id && activeStep == 0) {
            try {

                const res = await fetch(`${process.env.REACT_APP_API_URL}/tours/save-event-react-api/`, {
                    method: "POST",
                    body: fd,
                })
                if (!res.ok) {
                    throw new Error("Error in saving event")
                }
                const data = await res.json()
                console.log("data", data)
                const eventImages = data.uploaded_images.map((image) => {
                    return {
                        file: null,
                        id: image.id,
                        preview: image.url,
                    };
                });
                console.log("evimgs", eventImages)
                const coverImage = {
                    file: null,
                    preview: data.eventImage,
                };
                const coverVideo = {
                    file: null,
                    preview: data.cover_video ? data.cover_video : null,
                }
                setFormDataFiles({ ...formDataFiles, coverImage: coverImage, eventImages: eventImages, coverVideo: coverVideo });
                setFormData({ ...formData, id: data.eventId, eventSlug: data.slug });
                dispatch(updateFormDataFiles({ formDataFiles: { coverImage: coverImage, eventImages: eventImages, coverVideo: coverVideo } }));
                dispatch(setEventSlug(data.slug));
                // getEvent()
                try {
                    await getEvent(data.slug);

                } catch (error) {
                    console.error("Error fetching event data:", error);
                    return { error: error };

                }

                return 0;

            }
            catch (err) {
                return { error: err };
            }
        }

        else {
            
            if (formDataFiles?.deletedPhotos?.length > 0) {
                console.log("deleting photos", formDataFiles.deletedPhotos)
                const fdimage = new FormData();
                formDataFiles.deletedPhotos.map((photo, index) => {
                    fdimage.append("urls", photo)
                })
                try {
                    const deleteRes = await fetch(`${process.env.REACT_APP_API_URL}/tours/delete-event-photo/`, {
                        method: "POST",
                        body: fdimage
                    })
                    // if (!deleteRes.ok) {
                    //     throw new Error("Error in deleting photos")
                    // }
                    const deleteData = await deleteRes.json()
                    console.log("delted",formDataFiles)
                    const temp = structuredClone(formDataFiles)
                    temp.deletedPhotos = []
                    dispatch(updateFormDataFiles({ formDataFiles: temp }))
                    setFormDataFiles({ ...formDataFiles, deletedPhotos: [] })
                }
                catch (err) {
                    console.log("error", err)
                    return { error: err };
                }

            }
            if (activeStep == 0) {
                console.log("new imgs", formDataFiles.eventImages)
                formDataFiles?.eventImages?.map((image, index) => {
                    if (image.file) {
                        fd.append("newEventImages", image.file)
                    }
                })
            }
            if (activeStep == 4) {
                fd.set("publish", true)
            }
            try {
                if(!formData.repeats){
                    for (var key in formData.dates) {
                        for (var key2 in formData.dates[key]) {
                            if (key2 != "id") {
                                fd.append(key + "_batch_" + key2, formData.dates[key][key2]);
                            }
                        }
                    }
                }
                const updateRes = await fetch(`${process.env.REACT_APP_API_URL}/tours/update-event-react-api/`, {
                    method: "POST",
                    body: fd,
                })
                if (!updateRes.ok) {
                    throw new Error("Error in updating event")
                }
                const updateData = await updateRes.json()
                console.log("files", formDataFiles);
                const eventImages = updateData?.uploaded_images?.map((image) => {
                    return {
                        file: null,
                        preview: image.url,
                        id: image.id
                    };
                })
                const evimgs = formDataFiles?.eventImages?.filter((image) => image.file == null)
                console.log("new", eventImages)
                dispatch(updateFormDataFiles({ formDataFiles: { ...formDataFiles, eventImages: evimgs?.concat(eventImages) } }));
                // getEvent();

                if (formData.repeats) {
                    var data;
                    if (formData.repeatType == "everyday") {
                        const num = Math.round(
                            (new Date(formData.dates[0].enddate) - new Date(formData.dates[0].startdate)) / (1000 * 60 * 60 * 24)
                        );
                        data = {
                            startdate: formData.dates[0].startdate,
                            enddate: formData.dates[0].enddate,
                            starttime: formData.dates[0].starttime,
                            endtime: formData.dates[0].endtime,
                            repeats: formData.repeats,
                            repeatType: formData.repeatType,
                            changeEndTime: true,
                            tillDate: formData.tilldate,
                            numberOfDays: num,
                        };
                        console.log(data);
                    } else {
                        data = {
                            startdate: formData.dates[0].startdate,
                            enddate: formData.dates[0].enddate,
                            starttime: formData.dates[0].starttime,
                            endtime: formData.dates[0].endtime,
                            repeats: formData.repeats,
                            repeatType: formData.repeatType,
                            changeEndTime: true,
                            tillDate: formData.tilldate,
                            numberOfDays: null,
                            repeatedDays: repeatedDays,
    
                        };
                        console.log(data);
                    }
                    try {
                        const batchRes = await fetch(`${process.env.REACT_APP_API_URL}/tours/${eventSlug}/add-bulk-batches-api/`, {
                            method: "POST",
                            body: JSON.stringify({ batchData: data }),
                        })
                        if (!batchRes.ok) {
                            throw new Error("Error in adding batches")
                        }
                        const batchData = await batchRes.json()
                    }
                    catch (err) {
                        console.log("error", err)
                        return { error: err };
                    }
    
                }

                return 0;
            }
            catch (err) {
                console.log("error", err)
                return { error: err };
            }
            
        }
    };

    useEffect(()=>{
        console.log("updated",formDataFiles)
    },[formDataFiles])



    const handleNext = async (e) => {

        console.log(formData)
        setIsLoading(true)
        const res = await processForm();
        setIsLoading(false)
        if (res.error) {

            toast.error(res.error.message)
        }
       if (!res.error) {
            if (activeStep == 0 && !eventSlug) {
                toast.success('Good start!')
            }
            else {
                toast.success('Good going!')
            }
            const temp = structuredClone(formData)
            temp.addonPrices.map((addon, index) => {
                console.log("addon",addon.image)
                if(addon.image){
                addon.image.file = null
                }
            })
            dispatch(updateFormData({ activeStep: activeStep, formData: temp }))
            console.log("upadtinf",formData)
            // if (activeStep == 0 && !isEdit) {
            //     console.log(formDataFiles)
            //     const temp = structuredClone(formDataFiles)
            //     console.log("temp", temp)
            //     if (temp.coverImage) {
            //         temp.coverImage.file = null
            //     }
            //     if (temp.coverVideo) {
            //         temp.coverVideo.file = null
            //     }
            //     temp?.eventImages?.map((image, index) => {
            //         image.file = null
            //     })
            //     dispatch(updateFormDataFiles({ activeStep: activeStep, formDataFiles: temp }))

            // }
            console.log("submitted")

            if (activeStep < steps.length - 1) {
                setActiveStep(activeStep + 1)
                dispatch(updateFormStep(activeStep + 1))
            }
            else {
                setIsLaunched(true)
                // dispatch(clearStore())
                // setActiveStep(0)
                // navigate(`${process.env.REACT_APP_API_URL}/dashboard/${companyname}`)
                
            }
            // if(activeStep == steps.length-1){
            //     clearState()
            // }
       }
    }

    useEffect(()=>{
        console.log("step",activeStep)
    },[activeStep])

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
            dispatch(updateFormStep(activeStep - 1))
        }
    }

    const formStep = () => {
        switch (activeStep) {
            case 0:
                return <MediaForm register={register} errors={errors} formDataFiles={formDataFiles} setFormDataFiles={setFormDataFiles} formData={formData} setFormData={setFormData} />
            case 1:
                return <DetailsForm register={register} errors={errors} formData={formData} setFormData={setFormData} salesTeam={salesTeam} instructors={instructors} />
            case 2:
                return <PricingForm formData={formData} setFormData={setFormData} />
            case 3:
                return <DateForm formData={formData} setFormData={setFormData} setRepeatedDays={setRepeatedDays} />
            case 4:
                return <LaunchForm isLaunched={isLaunched} setIsLaunched={setIsLaunched}/>
          
        }
    }

    return (
        <div className='bodydiv'>
            <Toaster />
            {!isDesktop && <div className='overflow-scroll'>
                <nav
                        className="navbar flex justify-between shadow-sm sticky top-0 w-full items-center bg-black bg-gradient-to-r z-10 from-black to-black py-4"
                    >
                        <a className="navbar-brand" href={`/dashboard/${companyname}`}>
                            <p className="mb-0 flex items-center">
                                <i
                                    className="far fa-compass transform rotate-8 text-[#fd305e] text-2xl pl-4 pr-2"
                                    style={{ fontSize: '1.8rem' }}
                                ></i>
                                <span className="text-lg text-white">
                                    <b>
                                        <span className="text-[#f7e4cb] text-[24px] font-semibold">Logout</span>
                                        <span className="text-[#f7e4cb] text-[24px] font-semibold">.Studio</span>
                                    </b>
                                </span>
                            </p>
                        </a>

                        <div className="pr-4">
                            <a
                                className="text-[12.025px] shadow-sm text-white bg-[#045540]  border-green-500 px-3 py-2 rounded flex items-center"
                                href={`/dashboard/${companyname}`}
                            >
                                <i
                                    className="fas fa-fw fa-chevron-circle-left text-white mr-2"
                                ></i>
                                Goto Dashboard
                            </a>
                        </div>
                    </nav>
                <div className=''>
                    <Stepper setActiveStep={setActiveStep} activeStep={activeStep} steps={steps} steps_images={steps_images} />
                </div>
                <form onSubmit={handleSubmit(handleNext)} className='mt-8 relative max-h-[80vh] overflow-scroll bg-form_background shadow-[rgba(0,0,0,0.2)_0px_-3px_5px_0px] rounded-t-2xl '>
                    <div className='' >
                        {formStep()}
                    </div>

                    <FormControl isLoading={isLoading} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />
              
                </form>
            </div>
            }




            {

                isDesktop &&
                <div>
                    <nav
                        className="navbar flex justify-between shadow-sm sticky top-0 w-full items-center bg-black bg-gradient-to-r from-black to-black z-10 py-4"
                    >
                        <a className="navbar-brand" href={`/dashboard/${companyname}`}>
                            <p className="mb-0 flex items-center">
                                <i
                                    className="far fa-compass transform rotate-8 text-[#fd305e] text-2xl pl-4 pr-2"
                                    style={{ fontSize: '1.8rem' }}
                                ></i>
                                <span className="text-lg text-white">
                                    <b>
                                        <span className="text-[#f7e4cb] text-[24px] font-semibold">Logout</span>
                                        <span className="text-[#f7e4cb] text-[24px] font-semibold">.Studio</span>
                                    </b>
                                </span>
                            </p>
                        </a>

                        <div className="pr-4">
                            <a
                                className="text-[0.925rem] shadow-sm text-white bg-[#045540]  border-green-500 px-4 py-2 rounded flex items-center"
                                href={`/dashboard/${companyname}`}
                            >
                                <i
                                    className="fas fa-fw fa-chevron-circle-left text-white mr-2"
                                ></i>
                                Goto Dashboard
                            </a>
                        </div>
                    </nav>

                    <div className="flex overflow-hidden">
                        <div className="sidebar w-[16.5%] fixed left-0 h-full overflow-hidden flex justify-center py-8 bg-form_background border-r-[1px] border-black border-opacity-30">
                            <Stepper setActiveStep={setActiveStep} activeStep={activeStep} steps={steps} steps_images={steps_images} />
                        </div>
                        <div className="main overflow-hidden flex-grow bg-form_background">
                            <form onSubmit={handleSubmit(handleNext)} className="overflow-auto ml-[16.5%]">
                                <div className="min-h-[96vh] px-20 pt-10">{formStep()}</div>
                                <FormControl
                                    isDesktop={isDesktop}
                                    activeStep={activeStep}
                                    handleBack={handleBack}
                                    handleNext={handleNext}
                                    isLoading={isLoading}
                                />
                            </form>
                        </div>
                    </div>
                </div>


            }

        </div>
    )
}

export default EventForm



