import React, { useEffect, useRef, useState } from "react";
import { MdAddCircleOutline, MdOutlineClose, MdCancel } from "react-icons/md";
import BackButton from "./BackButton";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Map from "../components/Map";
import Select from "react-select";
import { Calendar, DateObject } from "react-multi-date-picker";
import { MdOutlineSearch } from "react-icons/md";
import { MdOutlineAccountCircle } from "react-icons/md";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { MdOutlineAccessTime } from "react-icons/md";
import { IoCalendarOutline, IoCloseOutline } from "react-icons/io5";
import BasicTimePicker from "../components/MobileTime";
import { set } from "react-hook-form";
import { useSelector } from "react-redux";
import { matchRoutes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Button from "./Button";
import Lottie from "lottie-react";
import confetti from "../confetti.json";

import tickmark from "../tickmark.lottie";
import SecondaryButton from "./SecondaryButton";
import { PiSealCheckFill } from "react-icons/pi";

const Modal = ({
  isOpen,
  closeModal,
  heading,
  setCoverImage,
  setCoverVideo,
}) => {
  const fileRef = useRef(null);
  const [error, setError] = useState(null);
  const handleClick = () => {
    fileRef.current.click();
  };
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (heading.includes("image")) {
      if (!file.type.includes("image")) {
        setError("Please select an image file");
        return;
      }
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        setError("Please select a .jpg or a .png file");
        return;
      }
    }
    if (heading.includes("video")) {
      if (!file.type.includes("video")) {
        setError("Please select a video file");
        return;
      }
      const maxSize = 25 * 1024 * 1024; // 50 MB in bytes
      if (file.size > maxSize) {
        setError("Please select a file less than 25MB");
        return;
      }
    }
    setFilePreview(file);
    setError(null);
  };

  const isDesktop = useMediaQuery({ minWidth: "768px" });

  const handleUploadImage = () => {
    console.log("uploading image");
    if (heading.includes("image")) {
      console.log("uploading image", filePreview);
      setCoverImage({
        file: filePreview,
        preview: URL.createObjectURL(filePreview),
      });
    } else {
      setCoverVideo({
        file: filePreview,
        preview: URL.createObjectURL(filePreview),
      });
    }
    setFilePreview(null);
    closeModal();
  };

  const [filePreview, setFilePreview] = useState(null);

  if (!isOpen) return null;
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-30 w-screen h-[94vh] overflow-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 md:px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex flex-col gap-4 px-4">
                <div className="flex gap-8 items-center justify-between">
                  <h2 className="text-2xl md:text-3xl font-bold">{heading}</h2>
                  <MdOutlineClose
                    size={25}
                    onClick={() => {
                      setFilePreview(null);
                      closeModal();
                    }}
                    className=" text-gray-500 cursor-pointer"
                  />
                </div>
                <div
                  onClick={handleClick}
                  className="flex flex-col py-8 rounded-xl cursor-pointer items-center justify-center border border-[#dad3c3] border-dashed"
                >
                  <MdAddCircleOutline
                    size={25}
                    className=" text-gray-500 cursor-pointer mb-2"
                  />
                  <h2 className="text-lg text-black font-semibold">
                    Click to upload
                  </h2>
                  <input
                    ref={fileRef}
                    type="file"
                    onChange={handleChange}
                    className="hidden"
                  />
                  <span className="text-gray-500 text-sm">
                    Supported formats:{" "}
                    {heading.includes("video") ? ".mp4" : ".png, .jpg"}
                  </span>
                  {error && (
                    <span className="text-red-500 text-md mt-2">{error}</span>
                  )}
                </div>
              </div>
              {filePreview &&
                (heading.includes("image") ? (
                  <div className="mt-2 mx-4">
                    <div
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(
                          filePreview
                        )})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        borderRadius: "0.75rem",
                        paddingBottom: !isDesktop ? "66.67%" : "50.67%",
                      }}
                      className={`relative overflow-hidden`}
                    >
                      {/* <img src={URL.createObjectURL(coverImage)} alt='cover' className='absolute object-cover w-full top-0 left-0'/> */}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`relative overflow-hidden mt-2 px-4 flex justify-center`}
                  >
                    <video
                      className="rounded-xl"
                      width={`${isMobile ? "275" : ""}`}
                      controls
                    >
                      <source src={URL.createObjectURL(filePreview)} />
                    </video>
                    {/* <img src={URL.createObjectURL(coverImage)} alt='cover' className='absolute object-cover w-full top-0 left-0'/> */}
                  </div>
                ))}
            </div>
            <hr className="my-2 h-[1px] border-none bg-black opacity-30" />
            <div className="flex justify-between px-4 py-3">
              {/* <button type='button' onClick={()=>{setCoverImagePreview(null); closeModal()}} className=' bg-white font-semibold  pl-5 pr-10 py-2 text-black rounded-lg'>Cancel</button> */}
              <BackButton
                text={"Cancel"}
                handleClick={() => {
                  setFilePreview(null);
                  closeModal();
                }}
              ></BackButton>
              {/* <button
                onClick={handleUploadImage}
                type="button"
                className="text-white bg-[#4F55DA] font-medium px-10 py-2 my-0 rounded-lg"
              >
                Done
              </button> */}
              <Button text={"Done"} handleClick={handleUploadImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

export const LocationModal = ({
  isOpen,
  closeModal,
  setLocation,
  offlineLocation,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  if (!isOpen) return null;
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed z-20 inset-0 w-screen h-[90vh] overflow-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="w-[85vw] md:w-auto bg-white px-4 md:px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex flex-col gap-4 px-2">
                <div className="flex gap-8 items-center justify-between">
                  <h2 className="text-2xl md:text-3xl font-bold">
                    Choose location
                  </h2>
                  <MdOutlineClose
                    size={25}
                    onClick={closeModal}
                    className=" text-gray-500 cursor-pointer"
                  />
                </div>
                <Map setSelectedLocation={setSelectedLocation} />
                {selectedLocation && (
                  <h2 className="text-lg">
                    Location:{" "}
                    <span className="font-semibold">{selectedLocation}</span>
                  </h2>
                )}
              </div>
            </div>
            <hr className="my-2 h-[1px] border-none bg-black opacity-30" />
            <div className="flex justify-between px-4 py-3">
              {/* <button type='button' onClick={()=>{setCoverImagePreview(null); closeModal()}} className=' bg-white font-semibold  pl-5 pr-10 py-2 text-black rounded-lg'>Cancel</button> */}
              <BackButton
                text={"Cancel"}
                handleClick={() => {
                  closeModal();
                }}
              ></BackButton>
              {/* <button
                onClick={() => {
                  setLocation(selectedLocation);
                  closeModal();
                }}
                type="button"
                className="text-white bg-[#4F55DA] font-medium px-10 py-2 my-0 rounded-lg"
              >
                Select
              </button> */}
              <Button
                text={"Select"}
                handleClick={() => {
                  setLocation(selectedLocation);
                  closeModal();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InstructorsModal = ({
  handleAddClickSales,
  handleAddClickInstructors,
  hosts,
  instrutorOptions,
  salesRep,
  salesOptions,
  closeModal,
}) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState(
    salesRep.length > 0 ? salesRep : []
  );
  const [selectedInstructors, setSelectedInstructors] = useState([
    hosts.length > 0 ? hosts : [],
  ]);
  const storedFormData = useSelector((state) => state.formData);

  console.log(hosts);

  const options = [
    {
      value: "chocolate",
      label: "Chocolate",
    },
    {
      value: "strawberry",
      label: "Strawberry",
    },
    {
      value: "vanilla",
      label: "Vanilla",
    },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f8f6f2"
        : state.isFocused
        ? "#f8f6f2"
        : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };

  // useEffect(() => {
  //   if (storedFormData.hosts) {
  //     setSelectedInstructors(storedFormData.hosts);
  //   }
  // }, [storedFormData]);

  // const [salesOptions, setSalesOptions] = useState([]);
  // const [instrutorOptions, setInstructorOptions] = useState([]);

  // useEffect(() => {
  //   console.log("instr", instructors);
  //   if (salesTeam.length > 0) {
  //     const options = salesTeam?.map((mate) => {
  //       return { value: mate.peopleid, label: mate.username };
  //     });
  //     setSalesOptions(options);
  //   }
  //   if (instructors.length > 0) {
  //     const opts = instructors?.map((mate) => {
  //       return { value: mate.peopleid, label: mate.username };
  //     });
  //     setInstructorOptions(opts);
  //   }
  // }, []);

  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-20 w-screen h-[90vh] overflow-hidden">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="w-[85vw] md:w-auto rounded-lg bg-white px-4 md:px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="py-2 ">
                <h2 className="text-xl font-medium mb-2">Select instructors</h2>
                <Select
                  onChange={handleAddClickInstructors}
                  value={hosts}
                  isMulti
                  isSearchable
                  options={instrutorOptions}
                  styles={customStyles}
                  classNames={{
                    control: () => "!rounded-xl !border-2 !border-[#e5e7eb]",
                  }}
                />
              </div>
              <div className="py-2">
                <h2 className="text-xl font-medium mb-2">Select sales team</h2>
                <Select
                  onChange={handleAddClickSales}
                  value={salesRep}
                  isSearchable
                  options={salesOptions}
                  classNames={{
                    control: () => "!rounded-xl !border-2 !border-[#e5e7eb]",
                  }}
                />
              </div>
            </div>
            <hr className="my-2 h-[1px] border-none bg-black opacity-30" />
            <div className="flex justify-between px-4 py-3">
              {/* <button type='button' onClick={()=>{setCoverImagePreview(null); closeModal()}} className=' bg-white font-semibold  pl-5 pr-10 py-2 text-black rounded-lg'>Cancel</button> */}
              <BackButton
                text={"Cancel"}
                handleClick={() => {
                  closeModal();
                }}
              ></BackButton>
              {/* <button
                onClick={() => {
                  closeModal();
                }}
                type="button"
                className="text-white bg-[#4F55DA] font-medium px-10 py-2 my-0 rounded-lg"
              >
                Select
              </button> */}
              <Button text={"Select"} handleClick={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DatesModal = ({ dates, closeModal, removeDate, setFormData }) => {
  const [selDates, setSelDates] = useState([]);
  const [clickedDate, setClickedDate] = useState([]);
  const [startValue, setStartValue] = useState({});
  const [endValue, setEndValue] = useState({});
  const [startDateValue, setStartDateValue] = useState({});
  const [endDateValue, setEndDateValue] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const handleChange = (value, name, index) => {
    console.log("called");
    if (name == "startdate") {
      startDateValue[index] = value;
      setStartDateValue(startDateValue);
    }
    if (name == "enddate") {
      endDateValue[index] = value;
      setEndDateValue(endDateValue);
    }
    if (name == "starttime") {
      startValue[index] = value;
      setStartValue(startValue);
    }
    if (name == "endtime") {
      endValue[index] = value;
      setEndValue(endValue);
    }
    setFormData((prevData) => {
      const newDate = {
        ...prevData.dates.find((item) => item.id === index),
        id: index,
        [name]: name.includes("date")
          ? moment(value.format()).format("YYYY-MM-DD")
          : value,
      };
      console.log("new", newDate);

      const updatedDates = prevData.dates.some((item) => item.id === index)
        ? prevData.dates.map((item) => (item.id === index ? newDate : item))
        : [...prevData.dates, newDate];
      console.log(prevData);
      console.log(updatedDates);

      return { ...prevData, dates: updatedDates };
    });
  };

  const handleDateClick = (clickedDate) => {
    if (selDates.length == 0) return;
    console.log(selDates);
    const curr = selDates.find(
      (date) =>
        moment(date).format("YYYY-MM-DD") === clickedDate.format("YYYY-MM-DD")
    );
    setSelectedDate(curr);
    console.log(dates);

    const matchedDates = dates.filter(
      (item) =>
        moment(item.startdate).format("YYYY-MM-DD") ===
        clickedDate.format("YYYY-MM-DD")
    );
    console.log("matched", matchedDates);
    setClickedDate(matchedDates);
    if (matchedDates.length > 0) {
      matchedDates.map((date) => {
        const start = new Date();
        start.setHours(date.starttime.split(":")[0]);
        start.setMinutes(date.starttime.split(":")[1]);
        startValue[date.id] = start;
        setStartValue(startValue);

        const end = new Date();
        end.setHours(date.endtime.split(":")[0]);
        end.setMinutes(date.endtime.split(":")[1]);
        endValue[date.id] = end;
        setEndValue(endValue);

        startDateValue[date.id] = moment(date.startdate).format("MMM DD, YYYY");
        setStartDateValue(startDateValue);

        endDateValue[date.id] = moment(date.enddate).format("MMM DD, YYYY");
        setEndDateValue(endDateValue);
      });
    }
    // setClickedDateId(matchedDate ? matchedDate.id : null);
  };

  useEffect(() => {
    console.log("start", startDateValue);
  }, [startDateValue]);

  const handleRemove = (id) => {
    console.log(id);
    removeDate(id);
    setClickedDate(null);
    setSelDates([]);
  };

  useEffect(() => {
    dates?.map((date) => {
      selDates.push(new Date(date.startdate));
    });
    setSelDates([...new Set(selDates)]);
    setCurrentDate(new DateObject({ year: 2024, month: 12, day: 25 }));
    const matchedDates = dates?.filter(
      (item) =>
        moment(item.startdate).format("YYYY-MM-DD") ===
        moment(selDates[0]).format("YYYY-MM-DD")
    );

    setClickedDate(matchedDates);
    if (matchedDates.length > 0) {
      matchedDates.map((date) => {
        const start = new Date();
        start.setHours(date.starttime.split(":")[0]);
        start.setMinutes(date.starttime.split(":")[1]);
        startValue[date.id] = start;
        setStartValue(startValue);

        const end = new Date();
        end.setHours(date.endtime.split(":")[0]);
        end.setMinutes(date.endtime.split(":")[1]);
        endValue[date.id] = end;
        setEndValue(endValue);

        startDateValue[date.id] = moment(date.startdate).format("MMM DD, YYYY");
        setStartDateValue(startDateValue);

        endDateValue[date.id] = moment(date.enddate).format("MMM DD, YYYY");
        setEndDateValue(endDateValue);
      });
    }
    setSelectedDate(selDates[0]);
    // const cur =  new DateObject({ year: 2024, month: 12, day: 25 })
    // console.log(cur.format("YYYY-MM-DD"));
    // setCurrentDate(cur)
  }, [dates]);

  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed z-20 inset-0 w-screen overflow-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative text-center  overflow-y-auto transform rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white lg:h-auto w-[85vw] rounded-lg md:w-auto px-4 md:px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex flex-col text-left gap-4 lg:px-4 px-0 h-full">
                <div className="flex gap-8 items-center justify-between">
                  <h2 className="text-2xl md:text-3xl font-bold">All Dates</h2>
                  <MdOutlineClose
                    size={25}
                    onClick={() => {
                      closeModal();
                    }}
                    className=" text-gray-500 cursor-pointer"
                  />
                </div>

                <Calendar
                  multiple
                  readOnly={true}
                  highlightToday={false}
                  currentDate={currentDate}
                  // value={selectedDates}
                  // onFocusedDateChange={handleChange}
                  value={selDates[0]}
                  // value={currentDate}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  mapDays={({ date }) => {
                    const formattedDate = date.format("YYYY-MM-DD");
                    const isClicked =
                      moment(selectedDate)?.format("YYYY-MM-DD") ===
                      formattedDate;

                    const isFocused = selDates.find(
                      (d) => moment(d).format("YYYY-MM-DD") === formattedDate
                    );
                    return {
                      style: {
                        backgroundColor: isClicked
                          ? "#045540"
                          : isFocused
                          ? "blue"
                          : "white",
                        color: isClicked
                          ? "white"
                          : isFocused
                          ? "white"
                          : "gray",
                        cursor: "pointer",
                      },
                      onClick: () => {
                        const exists = selDates.find(
                          (d) =>
                            moment(d).format("YYYY-MM-DD") === formattedDate
                        );
                        if (!exists) return;
                        handleDateClick(date);
                      },
                    };
                  }}
                />
                {clickedDate &&
                  clickedDate.map((date) => (
                    <div className="text-center">
                      <div className="grid relative rounded-xl border border-[#dad3c3] px-[19.5px] py-[14px] border-dashed text-left grid-cols-2 place-items-center grid-rows-2 mt-4 gap-y-2 lg:gap-x-4 gap-x-8">
                        <div className="">
                          <label
                            htmlFor={date.id}
                            className="block text-sm font-medium text-gray-900"
                          >
                            Start date
                          </label>
                          <DatePicker
                            format="MMM DD, YYYY"
                            render={
                              <InputIcon className="lg:w-[180px] w-[130px] text-[13px] px-[13px] py-[9.570px] focus-within:border-[#B5B0A1] focus:outline-none border rounded-xl" />
                            }
                            placeholder="Select date"
                            inputclassName="custom-className"
                            style={{ width: "100px" }}
                            value={startDateValue[date.id]}
                            onChange={(value) => {
                              handleChange(value, "startdate", date.id);
                            }}
                            id={date.id}
                          />
                        </div>
                        <div className="">
                          <label
                            htmlFor={date.id}
                            className="block text-sm font-medium text-gray-900"
                          >
                            End date
                          </label>
                          <DatePicker
                            format="MMM DD, YYYY"
                            render={
                              <InputIcon className="lg:w-[180px] w-[130px] text-[13px] px-[13px] py-[9.570px] focus-within:border-[#B5B0A1] focus:outline-none border rounded-xl" />
                            }
                            placeholder="Select date"
                            value={endDateValue[date.id]}
                            onChange={(value) => {
                              handleChange(value, "enddate", date.id);
                            }}
                            id={date.id}
                          />
                        </div>
                        {/* <div className="flex align-self-center items-center gap-2"> */}
                        {/* <div>
                          <label
                            htmlFor={date.id}
                            className="block text-sm font-medium text-gray-900"
                          >
                            Start time
                          </label>
                          <DatePicker
                            value={startValue[date.id]}
                            disableDayPicker
                            onChange={(value) => {
                              handleChange(value, "starttime", date.id);
                            }}
                            id={date.id}
                            render={(value, openCalendar) => {
                              return (
                                <div className="border flex justify-between items-center rounded-md p-[0.27rem]">
                                  <input
                                    onClick={openCalendar}
                                    value={value}
                                    placeholder="Select time"
                                    className="w-[110px] focus:outline-none"
                                  />
                                  <MdOutlineAccessTime size={20} />
                                </div>
                              );
                            }}
                            format="HH:mm"
                            // style={{ width: "60px" }}
                            plugins={[<TimePicker hideSeconds />]}
                          />
                        </div> */}

                        {/* <div>
                          <label
                            htmlFor={date.id}
                            className="block text-sm font-medium text-gray-900"
                          >
                            End time
                          </label>
                          <DatePicker
                            value={endValue[date.id]}
                            disableDayPicker
                            onChange={(value) => {
                              handleChange(value, "endtime", date.id);
                            }}
                            id={date.id}
                            render={(value, openCalendar) => {
                              return (
                                <div className="border flex justify-between items-center rounded-md p-[0.27rem]">
                                  <input
                                    onClick={openCalendar}
                                    value={value}
                                    placeholder="Select time"
                                    className="w-[110px] focus:outline-none"
                                  />
                                  <MdOutlineAccessTime size={20} />
                                </div>
                              );
                            }}
                            format="HH:mm"
                            // style={{ width: "60px" }}
                            plugins={[<TimePicker hideSeconds />]}
                          />
                        </div> */}
                        <div className="flex flex-col">
                          <label
                            for="start-time"
                            className="text-sm font-medium text-gray-700"
                          >
                            Start time
                          </label>

                          <BasicTimePicker
                            label="starttime"
                            value={startValue[date.id]}
                            handleChange={handleChange}
                            id={date.id}
                            width={isMobile && "130px"}
                          />
                        </div>
                        <div className="flex flex-col">
                          <label
                            for="end-time"
                            className="text-sm font-medium text-gray-700"
                          >
                            End time
                          </label>

                          <BasicTimePicker
                            label="endtime"
                            value={endValue[date.id]}
                            handleChange={handleChange}
                            id={date.id}
                            width={isMobile && "130px"}
                          />
                        </div>
                        {/* </div> */}
                        <div
                          onClick={() => handleRemove(date.id)}
                          className="absolute cursor-pointer top-3 right-2"
                        >
                          <IoCloseOutline size={20} className="text-red-500" />
                        </div>
                      </div>
                      {/*<SecondaryButton*/}
                      {/*  handleClick={() => handleRemove(date.id)}*/}
                      {/*  text={"Remove"}*/}
                      {/*  classes="m-auto mt-4"*/}
                      {/*/>*/}
                    </div>
                  ))}
              </div>
            </div>

            <hr className="my-2 h-[1px] border-none bg-black opacity-30" />
            <div className="flex justify-between px-4 py-3">
              {/* <button type='button' onClick={()=>{setCoverImagePreview(null); closeModal()}} className=' bg-white font-semibold  pl-5 pr-10 py-2 text-black rounded-lg'>Cancel</button> */}
              <BackButton
                text={"Cancel"}
                handleClick={() => {
                  closeModal();
                }}
              ></BackButton>
              {/* <button
                onClick={() => {
                  closeModal();
                }}
                type="button"
                className="text-white bg-[#4F55DA] font-medium px-10 py-2 my-0 rounded-lg"
              >
                Done
              </button> */}
              <Button text={"Done"} handleClick={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpcomingModal = ({ isOpen, closeModal, event, dates }) => {
  if (!isOpen) return null;
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-20 w-screen h-[90vh] overflow-hidden">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:mym-8 sm:w-full sm:max-w-lg">
            {dates.length > 0 &&
              dates.map((batch, index) => {
                const targetId = batch.id;
                const date = dates.find((d) => d.id == batch.id);
                return (
                  <div className="p-2 flex gap-3 items-center">
                    <div>
                      <IoCalendarOutline size={35} />
                    </div>
                    <div className="flex items-center justify-between w-100">
                      <div className="" style={{ width: "9rem" }}>
                        <div className="">
                          <span className="date_indicator">Starts on</span>
                          <p className="mb-0 date">
                            {date.start.day} {date.start.month}.{" "}
                            {date.start.date}, {date.start.year}
                          </p>
                        </div>
                        {date.end && (
                          <div>
                            <span className="date_indicator">Ends on</span>
                            <p className="mb-0 date">
                              {date.end.day} {date.end.month}. {date.end.date},{" "}
                              {date.end.year}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2 justify-start items-baseline">
                        <span className="" style={{ fontSize: "14px" }}>
                          from
                        </span>
                        {/* <span className={` fw-bold d-flex align-items-center justify-center ${batch.eventprices.length > 0 ? 'hasVariable' : ''}`} id={`popover-${targetId}`}    style={{ fontSize: '1.1rem' }}> {batch.price ? <span >&#8377;{~~batch.price}</span> : selectedEvent.price}{<MdArrowDropDown style={{cursor: 'pointer'}} className={`popoverbtn text-black ${batch.eventprices.length==0 && selectedEvent.event_price_types.length==0 && "hidden"}`} size={33}  />} </span> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const LaunchModal = ({ isOpen, closeModal, event }) => {
  const companyname = useSelector((state) => state.companyname);
  return (
    <div
      className="relative z-20"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        aria-hidden="true"
      >
        <div className="fixed z-20 inset-0 w-screen md:h-[90vh] h-[100vh] overflow-hidden">
          <div className="flex min-h-full items-center justify-center md:p-4 text-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="w-[100vw] flex relative md:block h-[100vh] md:h-auto md:w-auto bg-white px-2 md:px-2 py-6">
                <div className="flex flex-col flex-grow items-center justify-center gap-8 text-center">
                  <div className="relative">
                    <PiSealCheckFill
                      size={100}
                      className="text-[#045540] relative z-10"
                    />
                    <div className="absolute top-[-70%] left-[-45%]">
                      <Lottie
                        loop={true}
                        animationData={confetti}
                        style={{
                          width: "12rem",
                          height: "12rem",
                          display: "flex",
                          margin: "0",
                        }}
                      />
                    </div>
                  </div>
                  <IoCloseOutline
                    size={25}
                    onClick={closeModal}
                    className="absolute top-6 right-5 cursor-pointer"
                  />

                  <h2 className="congrats_message mt-[-14px]">
                    <span className="text-3xl">Congrats!</span> <br></br> You
                    just gifted this world a new experience
                  </h2>
                </div>
                <div className="md:relative absolute md:bottom-auto md:mt-10 md:grid md:left-auto bottom-[10%] left-[20%]  mx-auto justify-center">
                  <button
                    className="w-full text-[1rem] shadow-sm text-white bg-[#045540] border-green-500 md:px-6 md:py-2 px-8 py-3 rounded flex items-center justify-center space-x-2"
                    onClick={() =>
                      (window.location.href = `/dashboard/${companyname}`)
                    }
                  >
                    <i className="fas fa-fw fa-chevron-circle-left text-white"></i>
                    <span>Go to your Dashboard</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
