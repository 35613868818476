import React, { useState, useRef, useEffect } from "react";
import {
  MdCancel,
  MdChevronRight,
  MdOfflineBolt,
  MdOutlineAddAPhoto,
} from "react-icons/md";
import { useSelector } from "react-redux";
import Modal, { LocationModal } from "../UI/Modal";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../UI/Button";
import useAutosizeTextArea from "../hooks/useAutoSizeTextArea";
import ModalMultiple from "../UI/ModalMultiple";
import Carousel from "../UI/Carousel";
import ImageCarousel from "../UI/Carousel";
import Map from "../components/Map";
import PlacesAutoComplete from "../components/PlacesAutocomplete";
import { PiYoutubeLogo } from "react-icons/pi";
import { MdAddCircleOutline } from "react-icons/md";
import { FaRegImages } from "react-icons/fa6";
import { IoImagesOutline, IoLocationOutline } from "react-icons/io5";
import Input, { RadioButtonGroup, RadioInput } from "../UI/Input";
import { HiOutlineTrash } from "react-icons/hi2";
import SecondaryButton from "../UI/SecondaryButton";
import { updateFormDataFiles } from "../redux/rootSlice";
import { useMediaQuery } from "react-responsive";
import { useLoadScript } from "@react-google-maps/api";

const LinkInput = ({
  register,
  errors,
  index,
  formData,
  setFormData,
  storedFormData,
}) => {
  const handleChange = (evt) => {
    const link = evt.target?.value;
    formData.meeting_link = link;
    setFormData(formData);
  };

  return (
    <div className="flex flex-col gap-0 mt-2">
      <Input
        placeholder="Link to the meeting"
        type="text"
        classes="w-[100%]"
        // required={true}
        name={`meetlink`}
        handleChange={handleChange}
        defaultValue={
          storedFormData?.meeting_link ? storedFormData?.meeting_link : null
        }
      ></Input>
      {/* <SecondaryButton text="Remove" handleClick={()=>{removeLinkInput(index)}}></SecondaryButton> */}
    </div>
  );
};

const libraries = ["places"]

const MediaForm = ({
  errors,
  register,
  formDataFiles,
  setFormDataFiles,
  formData,
  setFormData,
}) => {
  const [clicked, setClicked] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [value, setValue] = useState("");
  const [coverImage, setCoverImage] = useState({});

  const [coverVideo, setCoverVideo] = useState({});
  const [eventImages, setEventImages] = useState([]);
  const [isMultipleUpload, setIsMultipleUpload] = useState(false);
  const [location, setLocation] = useState("offline");
  const [addLocationClicked, setAddLocationClicked] = useState(false);
  const [offlineLocation, setOfflineLocation] = useState(null);

  const [defaultValue, setDefaultValue] = useState("");

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const storedFormData = useSelector((state) => state.formData);
  const storedFormFiles = useSelector((state) => state.formDataFiles);

  const eventSlug = useSelector((state) => state.eventSlug);

  useEffect(() => {
    console.log("stored form files", storedFormFiles);
    if (storedFormFiles && Object.keys(storedFormFiles).length > 0) {
      if (storedFormFiles.coverImage && Object.keys(storedFormFiles.coverImage).length > 0) {
        // console.log("here",storedFormFiles.coverImage.preview.startsWith("https" || "blob"))
        if (
          storedFormFiles.coverImage.preview.startsWith("https") ||
          storedFormFiles.coverImage.preview.startsWith("blob")
        ) {
          setCoverImage(storedFormFiles.coverImage);
        } else {
          const image = { ...storedFormFiles.coverImage };
          image.preview = `${process.env.REACT_APP_API_URL}` + image.preview;
          setCoverImage(image);
        }
      }

      if (storedFormFiles.coverVideo && Object.keys(storedFormFiles.coverVideo).length > 0) {
        setCoverVideo(storedFormFiles.coverVideo);
        // console.log("here",storedFormFiles.coverVideo)
        if (storedFormFiles?.coverVideo?.preview != null) {
          if (
            storedFormFiles?.coverVideo?.preview?.startsWith("https") ||
            storedFormFiles?.coverVideo?.preview?.startsWith("blob")
          ) {
            console.log("herrre");
            setCoverVideo(storedFormFiles.coverVideo);
          } else {
            const video = { ...storedFormFiles.coverVideo };
            video.preview = `${process.env.REACT_APP_API_URL}` + video.preview;
            setCoverVideo(video);
          }
        }
      }
      if (storedFormFiles.eventImages && storedFormFiles.eventImages.length > 0) {
        setEventImages(storedFormFiles.eventImages);
        
        // if(storedFormFiles?.eventImages[0]?.preview?.startsWith("blob"))
        // console.log("event images",storedFormFiles.eventImages)
        // if (
        //   storedFormFiles?.eventImages[0]?.preview?.startsWith("https") ||
        //   storedFormFiles?.eventImages[0]?.preview?.startsWith("blob")
        // ) {
        //   console.log("here", storedFormFiles.eventImages);
        //   setEventImages(storedFormFiles.eventImages);
        // } else {
        //   var eventImgs = [];
        //   storedFormFiles.eventImages.forEach((image) => {
        //     const newImage = { ...image };
        //     newImage.preview =
        //       `${process.env.REACT_APP_API_URL}` + newImage.preview;
        //     eventImgs.push(newImage);
        //   });
        //   // console.log("imgs",eventImgs)
        //   setEventImages(eventImgs);
        // }
      }
    }
  }, [storedFormFiles]);

  


  const adjustHeight = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to calculate scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scrollHeight
    }
  };

  // Run on initial render and when value changes

  const textAreaRef = useRef(null);
  const locationOptions = [
    { value: "offline", label: "Offline" },
    { value: "online", label: "Online" },
  ];
  // useAutosizeTextArea(textAreaRef.current, value);
  const [meetlink, setMeetLink] = useState("");
  // const [linkIndex, setLinkIndex] = useState(0);
  // const [linkInput, setLinkInput] = useState([0]);
  useEffect(() => {
    if (storedFormData?.meeting_link) {
      setMeetLink(storedFormData.meeting_link);
    }
    if (storedFormData?.name) {
      setValue(storedFormData.name);
      // adjustHeight();
    }
  }, [storedFormData]);

  // const addLinkInput = () => {
  //   setLinkInput([...linkInput, linkInput.length]); // Add a new input field
  // };

  //  const removeLinkInput = (index) => {
  //   setLinkInput(linkInput.filter((linkIndex) => linkIndex !== index));
  // };

  const handleImageChange = (e) => {
    setFormData({ ...formData, coverImage: e.target.files[0] });
  };

  useEffect(() => {
    if (coverImage?.preview) {
      console.log("setting cover image");
      setFormDataFiles({ ...formDataFiles, coverImage: coverImage });
    }
    console.log(coverImage);
  }, [coverImage]);

  useEffect(() => {
    if(offlineLocation){
    setFormData({ ...formData, event_location: offlineLocation });
    }
  }, [offlineLocation]);

  useEffect(() => {
    if (coverVideo?.preview)
      setFormDataFiles({ ...formDataFiles, coverVideo: coverVideo });
  }, [coverVideo]);

  useEffect(() => {
    console.log("events", formDataFiles);
  }, [formDataFiles]);

  useEffect(() => {
    if (eventImages.length > 0) {
      console.log("setting event images", eventImages);
      setFormDataFiles({ ...formDataFiles, eventImages: eventImages });

      // var eventImageURLS = []
      // eventImages.forEach((image)=>{
      //   eventImageURLS.push(image.preview)
      // })
      // setFormData({...formData,eventImages:eventImageURLS})
    }
  }, [eventImages]);

  const handleLocChange = (value) => {
    setLocation(value);
    setFormData({ ...formData, event_type: value == "offline" ? true : false });
  };

  useEffect(()=>{
    setFormData({...formData,event_type:location == "offline" ? true : false})
  },[])

  const openModal = (title) => {
    setModalTitle(title);
    setClicked(true);
  };

  const closeModal = () => {
    setClicked(false);
    setIsMultipleUpload(false);
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setFormData({ ...formData, name: evt.target.value });
  };

  const removeEventImage = (id) => {
    console.log(id, formDataFiles);
    setEventImages(eventImages.filter((image) => image.id !== id));
    setFormDataFiles({
      ...formDataFiles,
      eventImages: eventImages.filter((image) => image.id !== id),
    })
    if(eventSlug){
    const updatedDeletedPhotos = formDataFiles.deletedPhotos && [...formDataFiles.deletedPhotos];
    if (!updatedDeletedPhotos.includes(id)) {
      updatedDeletedPhotos.push(id); // Add the photo URL
    } else {
      console.log("This item already exists");
    }
    console.log("updated deleted photos", updatedDeletedPhotos);
    setFormDataFiles({
      ...formDataFiles,
      deletedPhotos: updatedDeletedPhotos,
    });
  }
  };

  useEffect(() => {
    if (storedFormData && Object.keys(storedFormData).length > 0) {
      console.log("loc",storedFormData)
      if (storedFormData.event_type) {
        setLocation(storedFormData.event_type ? "offline" : "online");
        console.log("setting location", storedFormData.event_type);
        if (storedFormData.event_type) {
          console.log("setting offline location");
          if(storedFormData.event_location!=null){
            console.log("setting offline location",storedFormData.event_location);
          setOfflineLocation(storedFormData.event_location);
          }
        }
      }
    }
  }, [storedFormData]);

  return (
    <AnimatePresence>
      <motion.div
        className=""
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ opacity: { duration: 0.75 }, y: { duration: 0.5 } }}
      >
        <img
          src="https://logout.world/static/dashboard_studio/media/illustrations/sigma-1/17.png"
          alt="image"
          className="w-40 h-40 m-auto mt-4"
        />
        <div className="grid xl:grid-cols-1 grid-cols-1 gap-8 px-6 py-4 pb-24">
          <div className="flex flex-col section_gap_sm lg:section_gap_lg">
            <div className="grid grid-rows-1 lg:grid-cols-3 mt-6">
              <h2 className="section_heading">
                Event name*
              </h2>
              <div className="col-span-2">
              <input
              type="text"
                style={{ fontSize: "1rem" }}
                placeholder="Event Name"
                autoFocus
                defaultValue={storedFormData?.name}
                onChange={handleChange}
                required
                // ref={textAreaRef}
                className=" bg-[#f8f6f2] w-full rounded-xl px-[1.5rem] py-[0.65rem] focus:bg-[#f4f1eb] font-medium outline-none border-none text-black focus:outline-none placeholder-gray-400"
              />
              </div>
            </div>
            <div className="grid grid-rows-1 lg:grid-cols-3 grid-cols-1">
              <h2 className="section_heading">
                Cover Image*
              </h2>
              {!coverImage?.preview ? (
                <div className="bg-white col-span-2 relative rounded-xl border-2 border-[#dad3c3] border-dashed py-10 flex flex-col justify-center items-center gap-2">
                  <MdOutlineAddAPhoto size={35} className="" />
                  <Button handleClick={() => openModal("Choose cover image")} text={'Add cover image'} />
                  {/* <button
                    type="button"
                    className="text-white relative bg-[#4F55DA] font-medium px-4 py-1 my-0 rounded-lg"
                    onClick={() => openModal("Choose cover image")}
                  >
                    Add cover image
                  </button> */}
                  <input
                    type="file"
                    value={coverImage?.preview}
                    required
                    className="opacity-0 left-10 absolute top-20 pointer-events-none"
                  />
                </div>
              ) : (
                <div className="">
                  <div
                    style={{
                      backgroundImage: `url(${coverImage.preview})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      paddingBottom: "66.67%",
                      borderRadius: "0.75rem",
                      aspectRatio: "1/1",
                    }}
                    className={`relative overflow-hidden`}
                  ></div>
                  <div
                    onClick={() => {
                      setCoverImage(null);
                    }}
                    className="flex justify-self-end mt-2 text-sm text-black items-center gap-1 px-2 py-1 rounded-md cursor-pointer"
                  >
                    <span>Remove</span>
                    <HiOutlineTrash size={20} />
                  </div>
                </div>
              )}
            </div>
            <div className="grid grid-rows-1 lg:grid-cols-3 grid-cols-1">
              <h2 className="section_heading">
                Cover Video
              </h2>
              {!coverVideo?.preview ? (
                isMobile ? (
                  <div
                    className="bg-[#f8f6f2] flex justify-between items-center px-4 py-2 rounded-xl"
                    onClick={() => openModal("Choose cover video")}
                  >
                    <div className="flex items-center gap-3 cursor-pointer">
                      <PiYoutubeLogo size={25} />
                      <span className="font-semibold">Add cover video</span>
                    </div>
                    <MdAddCircleOutline
                      size={25}
                      className=" text-gray-500 cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="bg-white col-span-2 relative rounded-xl border-2 border-[#dad3c3] border-dashed py-10 flex flex-col justify-center items-center gap-2">
                    <MdOutlineAddAPhoto size={35} className="" />
                    {/* <button
                      type="button"
                      className="text-white relative bg-[#4F55DA] font-medium px-4 py-1 my-0 rounded-lg"
                      onClick={() => openModal("Choose cover video")}
                    >
                      Add cover video
                    </button> */}
                    <Button text={'Add cover video'} handleClick={() => openModal("Choose cover video")} />
                    <input
                      type="file"
                      value={coverVideo?.preview}
                      className="opacity-0 left-10 absolute top-20 pointer-events-none"
                    />
                  </div>
                )
              ) : (
                <div className={`relative overflow-hidden`}>
                  <video width="400" controls style={{borderRadius: '0.75rem'}}>
                    <source src={coverVideo?.preview} />
                  </video>
                  <div
                    onClick={() => {
                      setCoverVideo(null);
                    }}
                    className="flex justify-self-end mt-2 text-sm text-black items-center gap-1 px-2 py-1 rounded-md cursor-pointer"
                  >
                    <span>Remove</span>
                    <HiOutlineTrash size={20} />
                  </div>
                  {/* <img src={URL.createObjectURL(coverImage)} alt='cover' className='absolute object-cover w-full top-0 left-0'/> */}
                </div>
              )}
            </div>
            <div className="lg:hidden">
            <h2 className="section_heading lg:hidden">Event Images</h2>
            {eventImages.length > 0 && (
              <div className="lg:hidden">
                <ImageCarousel
                  className="lg:hidden"
                  removeEventImage={removeEventImage}
                  images={eventImages}
                />
              </div>
            )}

            <div
              className={`bg-[#f8f6f2] lg:hidden flex justify-between items-center px-4 py-2 ${eventImages.length>0 ? 'mt-3' : 'mt-2'} rounded-xl`}
              onClick={() => {
                setIsMultipleUpload(true);
              }}
            >
              <div className="flex items-center gap-3 cursor-pointer">
                <IoImagesOutline size={25} />
                <span className="font-semibold">Add images</span>
              </div>
              <MdAddCircleOutline
                size={25}
                className=" text-gray-500 cursor-pointer"
              />
            </div>
            </div>
            <div className="grid-cols-3 grid-rows-1 hidden lg:grid">
              <h2 className="section_heading">Event Images</h2>
              {eventImages.length == 0 && (
                <div className="bg-white hidden col-span-2 lg:flex relative rounded-xl border-2 border-[#dad3c3] border-dashed py-10 flex-col justify-center items-center gap-2">
                  <MdOutlineAddAPhoto size={35} className="" />
                  {/* <button
                    type="button"
                    className="text-white relative bg-[#4F55DA] font-medium px-4 py-1 my-0 rounded-lg"
                    onClick={() => setIsMultipleUpload(true)}
                  >
                    Add event images
                  </button> */}
                  <Button handleClick={() => setIsMultipleUpload(true)} text={'Add event images'} />
                </div>
              )}
              {eventImages.length > 0 && (
                <div className="flex-col hidden lg:flex">
                  <ImageCarousel
                    removeEventImage={removeEventImage}
                    images={eventImages}
                  />
                  <div
                    className="bg-[#f8f6f2] flex justify-between items-center px-4 py-2 rounded-xl mt-4"
                    onClick={() => {
                      setIsMultipleUpload(true);
                    }}
                  >
                    <div className="flex items-center gap-3 cursor-pointer">
                      <IoImagesOutline size={25} />
                      <span className="font-semibold">Add images</span>
                    </div>
                    <MdAddCircleOutline
                      size={25}
                      className=" text-gray-500 cursor-pointer"
                    />
                  </div>
                </div>
              )}
            </div>

          
            <div className="grid lg:grid-cols-3 grid-rows-1 lg:mb-4">
              <h2 className="section_heading">Location</h2>
              <div className="flex flex-col col-span-2">
              <div class="space-x-4">
                <RadioButtonGroup
                columns={2}
                  options={locationOptions}
                  selectedValue={location}
                  onChange={(value) => handleLocChange(value)}
                  name="location"
                />
              </div>

              {location == "offline" && (
                <div>
                  {offlineLocation && (
                    <div>
                      <h2 className="mt-4 section_heading">
                        Selected location: {" "}
                        <span className="ml-1 font-semibold">{offlineLocation}</span>
                      </h2>
                      <Map  selectedLocation={offlineLocation} />
                    </div>
                  )}

                  <div
                    className="bg-[#f8f6f2] mt-3 flex justify-between cursor-pointer items-center px-4 py-2 rounded-xl"
                    onClick={() => {
                      setAddLocationClicked(true);
                    }}
                  >
                    <div className="flex items-center gap-3 ">
                      <IoLocationOutline size={25} />
                      <span className="font-semibold">
                        {offlineLocation ? "Update" : "Add"} location
                      </span>
                    </div>
                    <MdAddCircleOutline
                      size={25}
                      className=" text-gray-500 cursor-pointer"
                    />
                  </div>
                </div>
              )}

              {location == "online" && (
                <div className="mt-3">
                  <h2 className="section_heading">Meeting link</h2>
                  <LinkInput
                    key={0}
                    register={register}
                    formData={formData}
                    setFormData={setFormData}
                    storedFormData={storedFormData}
                    meetlink={meetlink}
                    errors={errors}
                  />
                </div>
              )}
              </div>
            </div>
          </div>
          <Modal
            isOpen={clicked}
            closeModal={closeModal}
            heading={modalTitle}
            setCoverImage={setCoverImage}
            setCoverVideo={setCoverVideo}
          />
          <ModalMultiple
            isOpen={isMultipleUpload}
            closeModal={closeModal}
            setEventImages={setEventImages}
            eventImages={eventImages}
          />
          <LocationModal
            isOpen={addLocationClicked}
            closeModal={() => setAddLocationClicked(false)}
            setLocation={setOfflineLocation}
            offlineLocation={offlineLocation}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MediaForm;

