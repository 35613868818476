import React from 'react'
import {LuPlus} from "react-icons/lu";

const SecondaryOutline = ({text,classes,handleClick}) => {
  return (
    <div className='mx-auto lg:ml-0'>
      <button type='button' onClick={handleClick} className={`font-semibold flex items-center gap-2 w-[100%] px-4 py-2 text-black opacity-75 border-2 rounded-xl ${classes}`}>
          <LuPlus size={15} />
    {text}
    </button>
    </div>
  )
}

export default SecondaryOutline
