import { createSlice } from "@reduxjs/toolkit";

const rootSlice = createSlice({
  name: "root",

  initialState: {
    activeFormStep: 0,
    formData: {},
    formDataFiles: {},
    user: null,
    accessToken: null,
    eventSlug: null,
    isEdit: false,
    companyname: null
  },
  reducers: {
    updateFormStep: (state, action) => {
      console.log(action.payload);
      state.activeFormStep = action.payload;
    },
    updateFormData: (state, action) => {
      // if (action.payload.activeStep === 0) {
      //   const coverImage = action.payload.formData.coverImage
      //   state.formDataFiles['coverImage'] = coverImage
      //   delete action.payload.formData.coverImage
      //   if(action.payload.formData.coverVideo){
      //     state.formDataFiles['coverVideo'] = action.payload.formData.coverVideo
      //     delete action.payload.formData.coverVideo
      //   }
      //   if(action.payload.formData.eventImages){
      //     state.formDataFiles['eventImages'] = action.payload.formData.eventImages
      //     delete action.payload.formData.eventImages
      //   }
      // }
      state.formData = { ...state.formData, ...action.payload.formData };
      console.log("payload",action.payload);
    },

    updateFormDataFiles: (state, action) => {
      console.log("payload",action.payload)
      state.formDataFiles = { ...state.formDataFiles, ...action.payload.formDataFiles };
    },  

    clearStore: (state,action) => {
      state.activeFormStep = 0;
      state.formData = {};
      state.formDataFiles = {};
      state.user = null;
      state.accessToken = null;
      state.eventSlug = null;
      state.isEdit = false;
      state.companyname = null;
      
    },
     
    setToken: (state, action) => {
      state.accessToken = action.payload;
    },

    setEventSlug: (state, action) => {
      console.log("slug",action.payload)
      state.eventSlug = action.payload;
    },

    setCompanyName: (state, action) => {
      state.companyname = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setIsEdit: (state, action) => {
      state.isEdit = action.payload
    }
  },
});

// Actions
export const {
  updateFormStep,
  updateFormData,
  updateFormDataFiles,
  setToken,
  setEventSlug,
  setUser,
  setCompanyName,
  setIsEdit,
  clearStore
} = rootSlice.actions;
export const reducer = rootSlice.reducer;
