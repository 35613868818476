import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody, ModalFooter, Button, UncontrolledPopover } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { BioLinkData, DownloadPdf } from "../../api/Api";
import EventLocation from "../link_in_bio/pin (2).png"
import Duration from "../link_in_bio/history.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Zoom, Navigation, Pagination, Autoplay, Lazy } from 'swiper/modules';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Skeleton from 'react-loading-skeleton'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-loading-skeleton/dist/skeleton.css'
import { useMediaQuery } from 'react-responsive'
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";




import advisordownload from './advisordownload.png'
import NavigationTab from "./NavigationTab";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import LogoutWidget from "./LogoutWidget";
import { MdArrowDropDown } from "react-icons/md";







const EventModal = ({ isOpen, toggle, eventList, selectedEventSlug, formatDate, company }) => {
    const demoRef = useRef(null)
    const [blur, setBlur] = useState('0');
    const [logoHeight, setLogoHeight] = useState('100%');
    const [initialScroll, setInitialScroll] = useState(true);
    const [isAccordOpen, setIsAccordOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const [bookingUrl, setBookingUrl] = useState('');
    const location = useLocation()
    const navigate = useNavigate();
    const [dates, setDates] = useState([]);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);
    const ref = useRef(null)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
        const handlePopState = () => {
            console.log("oppe")
            window.history.pushState({}, '', ``);
            toggle();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [window.history]);


    useEffect(() => {

        console.log(eventList);
        const ev = eventList.filter((ev) => { return ev.slug === selectedEventSlug });
        setBookingUrl(ev[0].booking_url);

    }, [])

    const handleScroll = (e) => {
        setScrolled(true);
        const blurlevel = e.target.scrollTop / 50;
        setBlur(blurlevel);
        const height = 100 - e.target.scrollTop / 10;
        setLogoHeight(height + '%');
        if (initialScroll) {

            e.target.scroll({
                top: 180,
                behavior: 'smooth',
            });

            setInitialScroll(false);

            e.preventDefault();
        }

        if (e.target.scrollTop == 0) {
            setScrolled(false);
        }

    };






    const [pdfData, setPdfData] = useState({ name: '', number: '' });
    const [scrolled, setScrolled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);




    const selectedEvent = eventList ? eventList.find(event => event.slug === selectedEventSlug) : null;

    const host = "https://logout.world"

    useEffect(() => {
        const sel = eventList ? eventList.find(event => event.slug === selectedEventSlug) : null
        const res = formatDate(sel.batches)
        setDates(res)
        console.log(res)
        console.log(dates, 'dates')
    }, [])

    // console.log(company, '2345678')

    if (!selectedEvent) return null;

    const removeTags = (html) => {
        return html.replace(/<(?:.|\n)*?>/gm, '');
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setPdfData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };






    const savePdfInfo = async (slug) => {
        try {
            if (!pdfData.name || !pdfData.number) {
                toast.error("Please fill in all required fields.");
                return;
            }
            if (pdfData.number.length !== 10 || !/^\d+$/.test(pdfData.number)) {
                toast.error("Phone number must be exactly 10 digits.");
                return;
            }

            const response = await DownloadPdf(pdfData, slug);
            setPdfData({ name: '', number: '' });
            toggleModal();
            const modal = document.getElementById("pdfModal");
            if (modal) {
                modal.style.display = "none";
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Error submitting form");
        }
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleBackButtonClick = () => {
        toggle();
    };



    const hasAddDayImages = selectedEvent.addday.some(day => day.pic1 || day.pic2 || day.pic3 || day.pic4);

    return (
        <>

            <div className="mt-0">

                <div id="book-container"></div>
                <Modal id="eventModal" isOpen={isOpen} fullscreen={true} toggle={toggle} >
                    <ModalBody className="rounded-bottom p-0 " onScroll={(e) => { handleScroll(e) }}>

                        <div className=" d-flex justify-content-between p-2 m-1 sticky bg-white  d-lg-flex d-none d-md-block ">

                            <button className="btn" onClick={handleBackButtonClick}>
                                <i className="fas fa-arrow-left text-dark"></i>
                            </button>

                            <span style={{ margin: '0 auto', fontFamily: 'Helvetica Neue Medium', fontSize: '22px' }}><b>{company.name}</b></span>
                        </div>

                        <div className='fordesktop'>
                            <Swiper
                                style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff',
                                    '--swiper-pagination-bottom': '23px',
                                    position: "sticky",
                                    top: '0',
                                    zIndex: '0',

                                }}
                                zoom={true}


                                // pagination={true}
                                // autoplay={{
                                //     delay: 2500,
                                //     pauseOnMouseEnter: true,
                                //     disableOnInteraction: false
                                // }}
                                // loop={true}
                                direction={isMobile ? 'horizontal' : "vertical"}
                                modules={[Zoom, Navigation, Pagination, Autoplay]}
                                className={`mySwiper`}
                            >
                                <button className="btn m-lg-0 back_button m-3 px-2 d-lg-none d-sm-block d-md-none" onClick={handleBackButtonClick} style={{ zIndex: "11", position: "fixed", top: "0" }}>
                                    <i className="fas back_arrow fa-arrow-left text-white fs-5"></i>
                                </button>
                                {hasAddDayImages ? selectedEvent.addday.map((day, dayIndex) => (
                                    [day.pic1, day.pic2, day.pic3, day.pic4].map((pic, picIndex) => pic && (
                                        <SwiperSlide key={`${dayIndex}-${picIndex}`}>
                                            <div className="swiper-zoom-container">

                                                {isMobile ? <img src={`${host}${pic}`} onLoad={() => setLoading(false)} style={{ filter: `blur(${blur + 'px)'} url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><filter id="a"><feComponentTransfer><feFuncA type="linear" slope="10"/></feComponentTransfer></filter>#a')`, height: logoHeight }} alt={`Slide ${dayIndex}-${picIndex}`} />
                                                    : <img src={`${host}${pic}`} style={{ borderRadius: '25px' }} alt={`Slide ${dayIndex}-${picIndex}`} />}

                                            </div>
                                        </SwiperSlide>
                                    ))
                                )) : (
                                    <SwiperSlide>
                                        <div className="swiper-zoom-container">
                                            <img src={`${selectedEvent.list_pic}`} style={{ filter: `blur${blur + 'px'}` }} alt={`Cover Image`} />

                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>

                            <div className="desktopCard" style={{ width: `${isMobile ? '100%' : '90%'}` }}>
                                <div>

                                    <div className="card bordercardBody-0  " style={{ borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                                        <span className="my-1 mx-auto" style={{ display: 'flex', backgroundColor: '#e5e5e7', height: '3px', width: '8%', borderRadius: '10px', margin: 'auto' }}>
                                            <hr style={{ visibility: 'hidden' }} />
                                        </span>
                                        <div className="cards_wrap1" >
                                            <div className=" -0">
                                                <div className="card-body pt-0 " >
                                                    {eventList.length == 0 ?
                                                        <Skeleton height={50} /> :
                                                        eventList
                                                            .filter((ev) => ev.slug === selectedEventSlug)
                                                            .map((ev) => (
                                                                <div key={ev.id}>
                                                                    <h5 className=" footer-type" style={{ fontFamily: 'Helvetica Neue Medium', fontWeight: '600', fontSize: '22px' }}><b>{ev.name}</b></h5>
                                                                    <p style={{ marginBottom: '10px' }} className=" mb-for-plain">
                                                                        {ev.price_striked ? (
                                                                            <>
                                                                                <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '400' }}>from</span>
                                                                                <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '500', fontSize: '20px' }}> <b>{(ev.price)}</b></span>
                                                                                <span style={{ textDecoration: 'line-through' }} className="px-1 strike"> {ev.price_striked}</span>
                                                                                <br />
                                                                            </>
                                                                        ) : (
                                                                            <span><b>{ev.price || <Skeleton />}</b></span>
                                                                        )}
                                                                    </p>
                                                                    <div>
                                                                        {dates.length > 0 &&
                                                                            <div>
                                                                                <p style={{ fontFamily: 'Helvetica Neue', fontWeight: '400' }} className="rounded mb-0 eventCardfont">Next upcoming on</p>
                                                                                <p className="mb-0"><b style={{ fontFamily: 'Helvetica Neue', fontWeight: '600', fontSize: '15px' }}>{dates[0]?.start?.day} - {dates[0]?.start?.month}. {dates[0]?.start?.date}, {dates[0]?.start?.year}</b></p>
                                                                                {dates.length > 1 && <a href="javascript:void(0);" type="button" data-bs-toggle="modal" data-bs-target="#datesModal">  Show +{dates.length - 1} dates</a>}

                                                                            </div>
                                                                        }




                                                                        <hr />


                                                                    </div>





                                                                    <div class="d-flex flex-column gap-3" style={{ fontFamily: 'Helvetica Neue' }}>
                                                                        {ev.event_location && (
                                                                            <div class="l">
                                                                                <div class="song">
                                                                                    {/* <p class="name mb-0">Event Location </p> */}
                                                                                    <p class="artist mb-0"> {ev.event_location}</p>
                                                                                </div>
                                                                                <div class="albumcover"><img src={EventLocation} alt="" className="" /></div>

                                                                            </div>
                                                                        )}
                                                                        {(ev.number_of_days || ev.number_of_nights) && <div class="l">
                                                                            <div class="song">
                                                                                {/* <p class="name mb-0">Duration </p> */}
                                                                                {ev.number_of_days && ev.number_of_nights ? (
                                                                                    ev.number_of_days === 1 && ev.number_of_nights === 1 ? (
                                                                                        <>  <p class="artist mb-0"> 1 Day - 1 Night</p></>
                                                                                    ) : ev.number_of_days === 1 ? (
                                                                                        <>  <p class="artist mb-0"> 1 Day - {ev.number_of_nights} Nights</p></>
                                                                                    ) : ev.number_of_nights === 1 ? (
                                                                                        <>  <p class="artist mb-0"> {ev.number_of_days} Days - 1 Night</p></>
                                                                                    ) : (
                                                                                        <> <p class="artist mb-0"> {ev.number_of_days} Days - {ev.number_of_nights} Nights</p></>
                                                                                    )
                                                                                ) : ev.number_of_days ? (
                                                                                    ev.number_of_days === 1 ? (
                                                                                        <>  <p class="artist mb-0"> 1 Day</p></>
                                                                                    ) : (
                                                                                        <>  <p class="artist mb-0"> {ev.number_of_days} Days</p></>
                                                                                    )
                                                                                ) : ev.number_of_nights ? (
                                                                                    ev.number_of_nights === 1 ? (
                                                                                        <>  <p class="artist mb-0">1 Night</p></>
                                                                                    ) : (
                                                                                        <>  <p class="artist mb-0"> {ev.number_of_nights} Nights</p></>
                                                                                    )
                                                                                ) : null}
                                                                            </div>
                                                                            <div class="albumcover">
                                                                                <img src={Duration} alt="" className="duration-icon" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>

                                                                    <hr />
                                                                    {ev.summary_for_chatbot && (
                                                                        <>
                                                                            <p className={`p-2 mt-2 mb-2 rounded mb-2 eventCardfont highlights ${!isAccordOpen ? 'close' : ''}`}>
                                                                                <span className="highlight_accord">
                                                                                    <b style={{ fontFamily: 'Helvetica Neue Medium', fontWeight: '500', fontSize: '18px' }}>Highlights</b>
                                                                                    {!isAccordOpen ? <AiOutlinePlus size={20} onClick={() => setIsAccordOpen(true)} /> :
                                                                                        <AiOutlineMinus size={20} onClick={() => setIsAccordOpen(false)} />}
                                                                                </span>
                                                                                <br /><span style={{ fontFamily: 'Helvetica Neue', whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: ev.summary_for_chatbot }}></span>

                                                                            </p>
                                                                            <hr />
                                                                        </>
                                                                    )}
                                                                    <NavigationTab event={ev} />
                                                                    {/* <div class="accordion" id="accordionPanelsStayOpenExample">
                                                                    {ev.About_Tour && <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                                                About Tour
                                                                            </button>
                                                                        </h2>
                                                                        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                                            <div class="accordion-body">

                                                                                <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont"> <span dangerouslySetInnerHTML={{ __html: ev.About_Tour }}></span></p>
                                                                                <hr />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }


                                                                    {ev.addday && <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                                                                Itinerary
                                                                            </button>
                                                                        </h2>
                                                                        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                                                            <div class="accordion-body">
                                                                                {ev.addday.map((day, dayIndex) => (
                                                                                    <>


                                                                                        <div key={dayIndex} className='eventCardfont p-2 rounded mb-2'>
                                                                                            <p className="mb-1"><b>Day </b>:  <span dangerouslySetInnerHTML={{ __html: day.daysnumber }}></span></p>
                                                                                            {day.name && (
                                                                                                <p className="mb-1"><b>Highlight : </b><br /><span dangerouslySetInnerHTML={{ __html: day.name }}></span></p>
                                                                                            )}
                                                                                            {day.details && (
                                                                                                <p className="mb-1"><b>Details :</b><br /><span dangerouslySetInnerHTML={{ __html: day.details }}></span></p>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }

                                                                    {ev.inclusions && (
                                                                        <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont"><b><i className="fa-regular fa-circle-check" style={{ color: "#34b233" }}></i><span className="px-2">Inclusion</span></b> <br /><span dangerouslySetInnerHTML={{ __html: ev.inclusions }}></span></p>
                                                                    )}
                                                                    {ev.exclusions && (
                                                                        <p className="p-2 mt-2 mb-2 rounded mb-2 eventCardfont"><b><i className="fa-regular fa-circle-xmark" style={{ color: "rgb(178 51 89)" }}></i><span className="px-2">Exclusion</span></b> <br /><span dangerouslySetInnerHTML={{ __html: ev.exclusions }}></span></p>
                                                                    )}
                                                                </div> */}
                                                                    {/* <div class="notifications-container">
                                                                    <div class="success">
                                                                        <div class="flex">
                                                                            <div class="success-prompt-wrap">

                                                                                <div class="stars text-center">
                                                                                    <p class="success-prompt-heading px-2 mb-0">Ratings</p>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="star">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                                                                    </svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="star">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                                                                    </svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="star">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                                                                    </svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="star">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                                                                    </svg>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="star">
                                                                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                                                                    </svg>
                                                                                </div>
                                                                                <div class="success-prompt-prompt d-flex justify-content-between align-items-center p-3">
                                                                                    <div className="g">
                                                                                        <p className="mb-0"> Facebook Rating </p>
                                                                                    </div>
                                                                                    <div className="h">
                                                                                        <p className="mb-0">{company.Facebook_rating} </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="success-prompt-prompt d-flex justify-content-between align-items-center p-3">
                                                                                    <div className="g">
                                                                                        <p className="mb-0"> Advioser Rating </p>
                                                                                    </div>
                                                                                    <div className="h">
                                                                                        <p className="mb-0">{company.TripAdvisor_rating} </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="success-prompt-prompt d-flex justify-content-between align-items-center p-3">
                                                                                    <div className="g">
                                                                                        <p className="mb-0"> Google Rating </p>
                                                                                    </div>
                                                                                    <div className="h">
                                                                                        <p className="mb-0">{company.Google_reviews_rating} </p>
                                                                                    </div>
                                                                                </div>

                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                </div>
                                                            ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter className="pdfbookfooter p-0  rounded m-0  d-lg-block d-md-block d-none ">
                        <div className="shadow">
                            <div className="d-flex justify-content-end align-items-between mt-2 p-2 gap-4">
                                <p className="text-detail-price mb-for-plain mb-0">
                                    <a onClick={() => console.log("Implement setSelectedTripSlug")} href="#" className="text-decoration-none  border-0 " data-bs-toggle="modal" data-bs-target="#pdfModal">
                                        <b>
                                            <button type="button" className="  buttonpdf  pb-2 pt-1 px-4 small-button d-flex align-items-center" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', border: '1px solid black', fontFamily: 'Helvetica Neue' }}>
                                                {/* <button type="button" className=" btn-alt buttonpdf p-2  small-button px-4 d-flex align-items-center  shine" style={{ backgroundColor: 'white', color: 'black', border: '2px solid black', borderRadius: '20px' }}> */}
                                                <b> <span className="fw-smaller fs-6 price px-2"><i className="fa-regular fa-circle-down"></i> PDF</span></b>
                                            </button>
                                        </b>
                                    </a>
                                </p>

                                <p className="text-detail-price mb-for-plain mb-0 text-decoration-none">
                                    {/* <a href={bookingUrl} target="_blank" className="text-decoration-none"> */}
                                    <b>

                                        <button onClick={() => { setIsWidgetOpen(true); }} id="bookingBtn" type="button" className="btn-alt1 pb-2 pt-1 px-4 small-button d-flex align-items-center  shine  " style={{ backgroundColor: selectedEvent.style_data.backgroundColor, color: selectedEvent.style_data.textColor, borderRadius: '20px', border: '1px solid black', fontFamily: 'Helvetica Neue' }} >
                                            <b> <span className="fw-smaller fs-6 price px-2"><i className="fa-regular fa-calendar-days"></i> Book Now</span></b>
                                        </button>
                                    </b>
                                    {/* </a> */}
                                </p>

                            </div>
                        </div>
                    </ModalFooter>

                    <div className="row  d-lg-none d-md-none d-sm-block p-2 gx-1 footer-shadow ">
                        <div className="col ">
                            <a onClick={() => console.log("Implement setSelectedTripSlug")} href="#" className="text-decoration-none " data-bs-toggle="modal" data-bs-target="#pdfModal">
                                <b>
                                    {/* <button type="button" className=" btn-alt buttonpdf w-100 p-2  small-button px-4 d-flex align-items-center  shine" style={{ backgroundColor: selectedEvent.style_data.backgroundColor, color: selectedEvent.style_data.textColor }}> */}
                                    <button type="button" className=" buttonpdf w-100 p-2  small-button px-4 d-flex align-items-center" style={{ backgroundColor: 'white', color: 'black', borderRadius: '20px', border: '1px solid black', fontFamily: 'Helvetica Neue' }}>
                                        <b> <span className="fw-smaller price px-2"><i className="fa-regular fa-circle-down"></i> PDF</span></b>
                                    </button>
                                </b>
                            </a>
                        </div>
                        <div className="col ">
                            {/* <a href={bookingUrl} target="_blank" className="text-decoration-none"> */}
                            {/* <p className="text-detail-price mb-for-plain mb-0 text-decoration-none"> */}
                            <b>
                                <button id="bookingBtn" ref={ref} onClick={() => { setIsWidgetOpen(true) }} type="button" className=" p-2 btn-alt1 w-100 small-button px-4 d-flex align-items-center  shine  " style={{ backgroundColor: selectedEvent.style_data.backgroundColor, color: selectedEvent.style_data.textColor, borderRadius: '20px', border: '1px solid black', fontFamily: 'Helvetica Neue', padding: '10px 5px' }} >
                                    <b> <span className="fw-smaller price px-2"><i className="fa-regular fa-calendar-days"></i> Book Now</span></b>
                                </button>
                            </b>
                            {/* </a> */}
                            {/* </p> */}

                            {/* <button className="w-100 shine p-2 m-0" style={{ backgroundColor: selectedEvent.style_data.backgroundColor, color: selectedEvent.style_data.textColor }}><b> <span className="fw-smaller price px-2"><i className="fa-regular fa-calendar-days"></i> Book Now</span></b>
                            </button> */}
                        </div>
                    </div>

                </Modal>
            </div>

            <div className="modal fade shadow" id="pdfModal" tabIndex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered mt-0 mb-0 ">
                    <div className="modal-content p-lg-2">
                        <div className="modal-header border-0">
                            <h4 className="modal-title" id="pdfModalLabel">
                                <b>Download PDF</b>
                            </h4>
                            <button type="button" className="btn-close b-0 modal-border-zero" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-0 border-0">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="nameInput" className="form-label">
                                        Name
                                    </label>
                                    <input id="validationDefault01" type="text" className="form-control" name="name" value={pdfData.name} onChange={handleOnChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phoneInput" className="form-label">
                                        Phone Number
                                    </label>
                                    <input type="text" className="form-control" name="number" value={pdfData.number} id="phoneInput" onChange={handleOnChange} required />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer p-lg-3 border-0 ">
                            <button type="button" onClick={() => { savePdfInfo(selectedEventSlug) }} className="btn w-100 btn-outline-dark bg-dark text-white">
                                <span>Download PDF</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="modal fade shadow" id="datesModal" tabIndex="-1" aria-labelledby="datesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable mt-4 mb-0 ">
                    <div className="modal-content p-lg-2">
                        <div className="modal-header border-0">
                            <h4 className="modal-title" id="pdfModalLabel">
                                <b>All dates</b>
                            </h4>
                            <button type="button" className="btn-close b-0 modal-border-zero" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-0 border-0 p-3">
                            <div>


                            </div>
                            {
                                dates.length > 0 && selectedEvent.batches.map((batch, index) => {
                                    const targetId = batch.id
                                    const date = dates.find((d) => d.id == batch.id)
                                    return (
                                        <div className="p-2 d-flex gap-3 align-items-center">
                                            <div>
                                                <IoCalendarOutline size={35} />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <div className="" style={{ width: '9rem' }}>
                                                    <div className="">
                                                        <span className="date_indicator">Starts on</span>
                                                        <p className="mb-0 date">{date.start.day} {date.start.month}. {date.start.date}, {date.start.year}</p>
                                                    </div>
                                                    {date.end && <div>
                                                        <span className="date_indicator">Ends on</span>
                                                        <p className="mb-0 date">{date.end.day} {date.end.month}. {date.end.date}, {date.end.year}</p>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="d-flex gap-2 justify-content-start align-items-baseline">
                                                    <span className="" style={{ fontSize: '14px' }}>from</span>
                                                    <span className={` fw-bold d-flex align-items-center justify-center ${batch.eventprices.length > 0 ? 'hasVariable' : ''}`} id={`popover-${targetId}`}    style={{ fontSize: '1.1rem' }}> {batch.price ? <span >&#8377;{~~batch.price}</span> : selectedEvent.price}{<MdArrowDropDown style={{cursor: 'pointer'}} className={`popoverbtn text-black ${batch.eventprices.length==0 && selectedEvent.event_price_types.length==0 && "hidden"}`} size={33}  />} </span>

                                                </div>
                                                {<PopoverComponent batch={batch} selectedEvent={selectedEvent}/>}

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="modal-footer p-lg-3 border-0 ">
                            {/* <a href={bookingUrl} target="_blank" className="text-decoration-none text-white w-100"> */}
                            <button id="bookingBtn" type="button" onClick={() => { setIsWidgetOpen(true) }} className="btn w-100 btn-outline-dark bg-dark text-white">
                                <span>Book Now</span>
                            </button>
                            {/* </a> */}
                        </div>
                    </div>
                </div>

            </div>

            <LogoutWidget eventSlug={selectedEventSlug} isOpen={isWidgetOpen} />




        </>
    );
};

export default React.memo(EventModal);

const PopoverComponent = ({ batch, selectedEvent }) => {
   
    return (
        <UncontrolledPopover
            container="body"
            fade={true}
            placement="left"
            // isOpen={isPopoverOpen}
            // target="Popover1"
            target={`popover-${batch.id}`}
            trigger="legacy"
            style={{ transition: 'opacity 0.5s ease, transform 0.5s ease' }}
            // offset={[0, 10]}
            
            
           
        // toggle={function noRefCheck() { }}
        >
            {/* <PopoverHeader>Prices</PopoverHeader> */}
            <PopoverBody style={{width: '225px', padding: '0.5rem 0.75rem'}}>
            <span style={{ fontFamily: 'Helvetica Neue', fontSize: '16px', fontWeight: '600', marginBottom: '6px' }}>Ticket Prices</span>
            <div className="d-flex flex-column mt-2" style={{gap: '8px'}}>
                {
                   batch.eventprices.length > 0 && batch.eventprices.map((price, index) => (

                        <div className="d-flex justify-between w-full">
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '400', maxWidth: '70%' }}>{price.attribute}</span>
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '600' }}>&#8377;{Math.round(price.price)}</span>
                        </div>

                    ))
                }
                {
                   batch.eventprices.length==0 && selectedEvent.event_price_types.length>0 && selectedEvent.event_price_types.map((price, index) => (
                        <div className="d-flex justify-between w-full">
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '400', maxWidth: '70%' }}>{price.attribute}</span>
                            <span style={{ fontFamily: 'Helvetica Neue', fontWeight: '600' }}>&#8377;{Math.round(price.price)}</span>
                        </div>
                    ))
                }
                </div>
                <div>

                </div>
            </PopoverBody>
        </UncontrolledPopover>
    )
}
