import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import PlacesAutoComplete from "./PlacesAutocomplete";
import { useEffect, useState } from "react";
import { MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";

const libraries = ["places"]
const Map = ({ setSelectedLocation, selectedLocation }) => {
  const [isLoaded,setIsLoaded] = useState(false)

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 100,
  });
  
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyBJQg3RkKR9QCBuyWNOKPJSibgs4rOxso8",
  //   libraries: libraries
  // });
  const [mapRef, setMapRef] = useState();
  const [markerPosition, setMarkerPosition] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });

  
 

  // useEffect(() => {
  //   if (selectedLocation && window.google) {
  //     var geocoder = new window.google.maps.Geocoder();
  //     geocoder.geocode({ address: selectedLocation }, function (results, status) {
  //       if (status == window.google.maps.GeocoderStatus.OK) {
  //         setMarkerPosition({
  //           lat: results[0].geometry.location.lat(),
  //           lng: results[0].geometry.location.lng(),
  //       })
  //     }
  //     })
  //   }
  // }, []);

  

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    const lat = markerPosition.lat;
    const lng = markerPosition.lng;
    // bounds.extend({ lat, lng });
    // map.fitBounds(bounds);
    setMapRef(map);
  };

  const handleMarkerClick = (lat, lng) => {
    mapRef?.setZoom(15);
    mapRef?.panTo({ lat, lng });
  };

  const center = { lat: 20.5937, lng: 78.9629 };
  return (
    <div className="Map mt-4">
      
      {false ? (
        <h3>Loading…..</h3>
      ) : (
        <LoadScript googleMapsApiKey="AIzaSyBJQg3RkKR9QCBuyWNOKPJSibgs4rOxso8" libraries={libraries}>
          {!selectedLocation && <PlacesAutoComplete
        setMarkerPosition={setMarkerPosition}
        setSelectedLocation={setSelectedLocation}
        ready={ready}
        value={value}
        status={status}
        data={data}
        setValue={setValue} 
        clearSuggestions={clearSuggestions}
      />}
        <GoogleMap
          mapContainerClassName="w-full h-[200px] rounded-xl"
          center={markerPosition}
          onLoad={onLoad}
          zoom={8}
        >
          <MarkerF
            onClick={() => {
              handleMarkerClick(markerPosition.lat, markerPosition.lng);
            }}
            position={markerPosition}
          />
        </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
};
export default Map;
