import React, { useEffect, useRef, useState } from "react";
import { MdChevronRight, MdOutlineAddAPhoto, MdOutlineRemoveCircleOutline } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../UI/Button";
import SecondaryButton from "../UI/SecondaryButton";
import Input, { RadioButtonGroup, RadioInput } from "../UI/Input";
import { FaCheck } from "react-icons/fa6";
import Editor from "../components/Editor";
import { useSelector } from "react-redux";
import SecondaryOutline from "../UI/SecondaryOutline";

const PriceComponent = ({
  index,
  arrayIndex,
  removePrice,
  setVariablePrices,
  storedFormData,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const obj = { [name]: value, id: index };
    setVariablePrices((prev) => {
      const updatedPrices = [...prev];
      updatedPrices[arrayIndex] = {
        ...updatedPrices[arrayIndex],
        [name]: value,
      };
      return updatedPrices;
    });
  };

  const isEdit = useSelector((state) => state.isEdit);

  return (
    <div className="text-center md:flex md:items-center md:gap-2">
      <div className="md:grid md:grid-cols-4 md:items-center flex flex-col gap-2 lg:gap-2 w-full">
        <div className="col-span-2 text-left">
          <label className="text-sm input_label_padding  font-normal">
            Price Category
          </label>
          <Input
            placeholder="Price category"
            type="text"
            classes="w-[100%] w-full"
            name="attribute"
            handleChange={handleChange}
            required
            defaultValue={
              storedFormData?.variablePrices?.length > 0
                ? storedFormData?.variablePrices[arrayIndex]?.attribute
                : ""
            }
          />
        </div>
        <div className="col-span-2 lg:flex items-center gap-2 text-left">
          <div
            className={`${isEdit ? "lg:flex-[0.9]" : "lg:flex-[1]"} flex-[1]`}
          >
            <label className="text-sm input_label_padding  font-normal">
              Price (INR)
            </label>
            <Input
              placeholder="Price in INR"
              type="text"
              classes="w-[100%]"
              name="price"
              handleChange={handleChange}
              required
              defaultValue={
                storedFormData?.variablePrices?.length > 0
                  ? storedFormData?.variablePrices[arrayIndex]?.price
                  : ""
              }
            />
          </div>
          {((isEdit && storedFormData?.variablePrices?.length>0) || (arrayIndex!=0)) && (
            <div className="flex justify-self-end lg:flex-[0.1]">
              <SecondaryButton
                handleClick={() => removePrice(index)}
                text={"Remove"}
                classes="my-3 lg:my-0 mx-auto lg:ml-0 py-0 lg:flex hidden"
              />
              {/* {
        arrayIndex != 0 && <SecondaryButton
        handleClick={() => removePrice(index)}
        text={"Remove"}
        classes="my-3 lg:my-0 mx-auto lg:ml-0 py-0 lg:flex hidden"
      />
      } */}
            </div>
          )}
        </div>
      </div>
      {((isEdit && storedFormData?.variablePrices?.length>0) || (arrayIndex!=0)) && (
        <SecondaryButton
          handleClick={() => removePrice(index)}
          text={"Remove"}
          classes="my-3 lg:my-0 mx-auto lg:ml-0 py-0 lg:hidden"
        />
      )}
    </div>
  );
};

const AddonComponent = ({
  index,
  arrayIndex,
  removeAddon,
  setAddPrices,
  storedFormData,
}) => {
  const fileRef = useRef(null);
  const [addonImage, setAddonImage] = useState({
    file: null,
    preview: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddPrices((prev) => {
      const updatedPrices = [...prev];
      updatedPrices[arrayIndex] = {
        ...updatedPrices[arrayIndex],
        [name]: value,
      };
      return updatedPrices;
    });
  };

  const isEdit = useSelector((state) => state.isEdit);
  console.log(storedFormData.addonPrices)

  useEffect(() => {
    console.log("changed");
    if (storedFormData) {
      const res = storedFormData?.addonPrices?.find(
        (price) => price.id === index
      );
      // console.log("res",res?.image)
      if (res?.image) {
        console.log("res", res);
        if (
          res?.image?.preview?.startsWith("https") ||
          res?.image?.preview?.startsWith("blob")
        ) {
          setAddonImage({
            file: null,
            preview: res?.image?.preview,
          });
        } else {
          if (res?.image?.preview) {
            setAddonImage({
              file: null,
              preview: process.env.REACT_APP_API_URL + res?.image?.preview,
            });
          }
        }
      }
    }
  }, []);

  const handleFile = (e) => {
    const file = e.target.files[0];
    const fileObj = {
      file: file,
      preview: URL.createObjectURL(file),
    };
    setAddonImage(fileObj);

    console.log("file", fileObj);
    console.log(URL.createObjectURL(fileObj.file));
    setAddPrices((prev) => {
      const updatedPrices = [...prev];
      updatedPrices[arrayIndex] = {
        ...updatedPrices[arrayIndex],
        image: fileObj,
      };
      return updatedPrices;
    });
  };

  return (
    <div className="text-center md:flex md:items-center md:gap-2">
      <div className="md:grid md:grid-cols-6 md:items-center flex flex-col gap-2 lg:gap-2 w-full">
        <div className="col-span-3 text-left">
          <label className="text-sm input_label_padding  font-normal">
            Addon name
          </label>
          <Input
            defaultValue={
              storedFormData?.addonPrices?.length > 0
                ? storedFormData?.addonPrices[arrayIndex]?.attribute
                : ""
            }
            name={`attribute`}
            handleChange={handleChange}
            placeholder="Addon name"
            type="text"
            classes="w-[100%]"
          />
        </div>
        <div className="flex w-full gap-2 items-center lg:col-span-3">
          <div className="lg:flex-[0.6] md:flex-[0.5] flex-[1] text-left">
            <label className="text-sm input_label_padding  font-normal">
              Price (INR)
            </label>
            <Input
              defaultValue={
                storedFormData?.addonPrices?.length > 0
                  ? storedFormData?.addonPrices[arrayIndex]?.price
                  : ""
              }
              name={`price`}
              handleChange={handleChange}
              placeholder="Price in INR"
              type="text"
              classes="w-[100%]"
            />
          </div>
          <div className="flex lg:flex-[0.6] items-end gap-3 text-left">
            <div className="w-[100%]">
              {addonImage != null ? (
                addonImage?.file != null || addonImage?.preview != "" ? (
                  <div className="flex items-center gap-4">
                  <div className="lg:w-[4rem] lg:h-[4rem] w-[5rem] h-[3rem] overflow-hidden rounded-md">
                    <img
                      src={addonImage.preview}
                      alt="addon"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>
                  <MdOutlineRemoveCircleOutline
                    size={20}
                    onClick={() => setAddonImage(null)}
                    className="cursor-pointer text-red-500"
                  />
                </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      fileRef?.current.click();
                    }}
                    className=""
                  >
                    <label className="text-sm input_label_padding  font-normal">
                      Image
                    </label>
                    <button
                      type="button"
                      // onClick={handleClick}
                      className={`justify-center flex items-center gap-2 w-[100%] px-4 py-2 text-black opacity-75 border-2 rounded-xl `}
                    >
                      {/* <MdOutlineAddAPhoto size={15} /> */}
                      <span className="lg:text-[13px] font-semibold ">Add image</span>
                    </button>
                    <input
                      onChange={handleFile}
                      name="image"
                      ref={fileRef}
                      type="file"
                      className="hidden"
                    />
                    {/* <div className="flex items-center  gap-2 ">
             
              <span className="text-gray-400 text-[13px]">
                Image
              </span>
            </div>
            <MdChevronRight size={25} className="text-gray-400" /> */}
                  </div>
                )
              ) : (
                <div
                  onClick={() => {
                    fileRef?.current.click();
                  }}
                  className=""
                >
                  <label className="text-sm input_label_padding  font-normal">
                    Addon image
                  </label>
                  <button
                      type="button"
                      // onClick={handleClick}
                      className={` justify-center flex items-center gap-2 w-[100%] px-4 py-2 text-black opacity-75 border-2 rounded-xl `}
                    >

                      {/* <MdOutlineAddAPhoto size={15} /> */}
                      <span className="lg:text-[13px] font-semibold">Add image</span>
                    </button>
                  <input
                    onChange={handleFile}
                    name="image"
                    ref={fileRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="hidden"
                  />
                  {/* <div className="flex items-center  gap-2 ">
             
              <span className="text-gray-400 text-[13px]">
                Image
              </span>
            </div>
            <MdChevronRight size={25} className="text-gray-400" /> */}
                </div>
              )}
            </div>
            {addonImage != null &&
              (addonImage?.file != null || addonImage?.preview != "") && (
                <div>
                  {/* <MdOutlineRemoveCircleOutline
                    size={20}
                    onClick={() => setAddonImage(null)}
                    className="cursor-pointer text-red-500"
                  /> */}
                </div>
              )}
          </div>

          {(storedFormData?.addonPrices?.length>0 || arrayIndex != 0) && (
            <SecondaryButton
              handleClick={() => removeAddon(index)}
              text={"Remove"}
              classes="my-3 py-0 mx-auto lg:ml-0 lg:my-0 hidden lg:flex"
            />
          )}
        </div>
      </div>
      {(storedFormData?.addonPrices?.length>0 || arrayIndex != 0) && (
        <SecondaryButton
          handleClick={() => removeAddon(index)}
          text={"Remove"}
          classes="my-3 py-0 mx-auto lg:ml-0 lg:my-0 lg:hidden"
        />
      )}
    </div>
  );
};

const PricingForm = ({ formData, setFormData }) => {
  const [gstStatus, setGstStatus] = useState("includes");
  const [gatewayCharges, setGatewayCharges] = useState("customer");
  const [isEventFree, setIsEventFree] = useState(false);
  const [displayPrice, setDisplayPrice] = useState(null);
  const [strikedPrice, setStrikedPrice] = useState(null);
  const [bookingAmt,setBookingAmt] = useState(null);
  const [gst, setGst] = useState(null);
  const [varPrices, setVarPrices] = useState([0]);
  const [addonPricesId, setAddonPricesId] = useState([0]); //for ids
  const [variablePrices, setVariablePrices] = useState([]);
  const [addPrices, setAddPrices] = useState([]); // original prices
  const [counter, setCounter] = useState(1);
  const [addonCounter, setAddonCounter] = useState(1);
  const [inclusions, setInclusions] = useState(null);
  const [exclusions, setExclusions] = useState(null);

  const storedFormData = useSelector((state) => state.formData);

  useEffect(() => {
    if (isEventFree == true) {
      setFormData((prevData) => ({
        ...prevData,
        free: true,
        variablePrices: [
          {
            temp: "temp",
          },
        ],
        addonPrices: [
          {
            temp: "temp",
          },
        ],
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, free: false }));
    }
  }, [isEventFree]);

  useEffect(() => {
    if (storedFormData) {
      console.log("storedFormData", storedFormData);
      const {
        free,
        price,
        price_striked,
        min_booking_price,
        gst_percentage,
        price_includes_gst,
        customer_pay,
        variablePrices,
        addonPrices,
        inclusions,
        exclusions,
      } = storedFormData;
      setIsEventFree(free);
      setDisplayPrice(price);
      setStrikedPrice(price_striked);
      setGst(gst_percentage);
      setBookingAmt(min_booking_price);
      setGstStatus(price_includes_gst ? "includes" : "excludes");
      setGatewayCharges(customer_pay ? "customer" : "company");
      setVariablePrices(variablePrices);
      setAddPrices(addonPrices);
      setInclusions(inclusions);
      setExclusions(exclusions);
      if (addonPrices.length >= 1) {
        setAddonPricesId(addonPrices.map((price) => price.id));
        setAddonCounter(addonPrices.length);
      }
      if (variablePrices.length >= 1) {
        setVarPrices(variablePrices.map((price) => price.id));
        setCounter(variablePrices.length);
      }
    }
  }, [storedFormData]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      variablePrices: variablePrices,
      addonPrices: addPrices,
    }));
  }, [variablePrices, addPrices]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      price: displayPrice,
      price_striked: strikedPrice,
      min_booking_price: bookingAmt,
      gst_percentage: gst,
      price_includes_gst: gstStatus === "includes" ? true : false,
      customer_pay: gatewayCharges == "customer" ? true : false,
      inclusions: inclusions,
      exclusions: exclusions,
    }));
  }, [
    displayPrice,
    strikedPrice,
    bookingAmt,
    gst,
    gstStatus,
    gatewayCharges,
    inclusions,
    exclusions,
  ]);

  const addPrice = () => {
    varPrices.push(counter);
    setVarPrices([...varPrices]);
    console.log(varPrices);
    setCounter(counter + 1);
    setFormData((prevData) => ({
      ...prevData,
      variablePrices: [...variablePrices, { id: counter }],
    }));
  };

  const addAddon = () => {
    addonPricesId.push(addonCounter);
    setAddonPricesId([...addonPricesId]);
    console.log(addonPricesId);
    setAddonCounter(addonCounter + 1);
  };

  const removePrice = (index) => {
    if (varPrices.length === 1) {
    
      setVariablePrices([{
        'temp': 'temp'
      }])
      setFormData({...formData,variablePrices: [{
        'temp': 'temp'
      }]})
      return;
    }
    setVarPrices(varPrices.filter((idx) => idx != index));
    setVariablePrices(variablePrices.filter((price) => price.id !== index));
    console.log(varPrices);
    setFormData((prevData) => ({
      ...prevData,
      variablePrices: variablePrices.filter((price) => price.id !== index),
    }));
  };

  const removeAddon = (index) => {
    console.log("remove", index);
    if (addonPricesId.length === 1) {
      console.log("Cannot remove the last addon.");
      setAddPrices([{
        'temp': 'temp'
      }])
      setFormData({...formData,addonPrices: [{
        'temp': 'temp'
      }]})
      return;
    }
    setAddonPricesId(addonPricesId.filter((idx) => idx != index));
    setAddPrices(addPrices.filter((price) => price.id !== index));
    console.log(addPrices);
  };

  useEffect(()=>{
    console.log("formData",formData)
  },[formData])

  const gstOptions = [
    { value: "includes", label: "GST included" },
    { value: "excludes", label: "GST excluded" },
  ];

  const gatewayOptions = [
    { value: "customer", label: "On customer" },
    { value: "company", label: "On me" },
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ opacity: { duration: 0.75 }, y: { duration: 0.5 } }}
      >
        <div className="px-6 py-4 overflow-auto pb-20">
          <div className="mt-4">
            {/* <h2 className="section_heading">Price</h2> */}
            <div className="flex flex-col">
              <div className="grid grid-rows-1 section_padding_sm pt-0 lg:grid-cols-3 lg:section_padding_lg lg:pt-0">
                <h2 className="section_heading">Make event free</h2>
                <label className="flex items-center hover:bg-[#DDF8FC] hover:border-black cursor-pointer px-[19.5px] py-[14px] rounded-xl border-[1px] border-dashed border-[#dad3c3]">
                  <input
                    type="checkbox"
                    name="free"
                    checked={isEventFree}
                    value={isEventFree ? "free" : "paid"}
                    className="sr-only peer"
                    onChange={() => setIsEventFree(!isEventFree)}
                  />
                  <div className="w-5 h-5 rounded-md bg-[#f6f1e9] flex justify-center items-center peer-checked:bg-[#045540] peer-checked:text-white">
                    {isEventFree && <FaCheck size={15} />}
                  </div>
                  <span className="text-black ml-4">Make event free</span>
                </label>
              </div>
              {!isEventFree && (
                <div>
                  <div className="flex flex-col">
                    <div className="grid grid-rows-1 align-baseline lg:grid-cols-3 lg:section_padding_lg section_padding_sm">
                      <h2 className="section_heading mb-2 lg:mb-0">Prices</h2>
                      <div className="grid lg:grid-cols-3 gap-2 col-span-2">
                        <div>
                          <label className="text-sm input_label_padding  font-normal">
                            Display price (INR)
                          </label>
                          <Input
                            placeholder="Price you want to display"
                            type="number"
                            classes="w-[100%]"
                            required
                            name={"displayPrice"}
                            defaultValue={displayPrice}
                            handleChange={(e) =>
                              setDisplayPrice(e.target.value)
                            }
                          />
                        </div>
                        <div className="lg:py-0">
                          <label className="text-sm input_label_padding  font-normal">
                            Striked price (INR)
                          </label>
                          <Input
                            placeholder="This shall be striked out: I̶N̶R̶ 4̶0̶0̶0̶"
                            type="number"
                            classes="w-[100%]"
                            name={"strikedPrice"}
                            defaultValue={strikedPrice}
                            handleChange={(e) =>
                              setStrikedPrice(e.target.value)
                            }
                          />
                        </div>
                        <div className="lg:py-0">
                          <label className="text-sm input_label_padding  font-normal">
                            Booking Amount (INR)
                          </label>
                          <Input
                            placeholder="Booking Amount"
                            type="number"
                            classes="w-[100%]"
                            name={"bookingAmt"}
                            defaultValue={bookingAmt}
                            handleChange={(e) =>
                              setBookingAmt(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="lg:section_padding_lg align-baseline section_padding_sm grid grid-rows-1 lg:grid-cols-3">
                      <h2 className="section_heading mb-2 lg:mb-0">Variable Prices</h2>
                      <div className="flex flex-col gap-2 lg:mt-0 col-span-2">
                        {varPrices.map((price, index) => (
                          <PriceComponent
                            key={price}
                            index={price}
                            arrayIndex={index}
                            removePrice={removePrice}
                            setVariablePrices={setVariablePrices}
                            storedFormData={formData}
                          />
                        ))}
                        <div className="controls flex justify-left gap-4 mt-1">
                          {/* <Button
                        handleClick={addPrice}
                        text={"Add another price"}
                      /> */}
                          <SecondaryOutline
                            handleClick={addPrice}
                            text={"Add another price"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-rows-1 align-baseline lg:grid-cols-3 section_padding_sm lg:section_padding_lg">
                      <h2 className="section_heading mb-2 lg:mb-0">Add-Ons</h2>
                      <div className="flex flex-col gap-2 lg:mt-0 col-span-2">
                        {addonPricesId.map((addon, index) => (
                          <AddonComponent
                            key={addon}
                            index={addon}
                            arrayIndex={index}
                            removeAddon={removeAddon}
                            setAddPrices={setAddPrices}
                            storedFormData={formData}
                          />
                        ))}
                        <div className="controls flex justify-left gap-4 mt-1">
                          {/* <Button
                        handleClick={addAddon}
                        text={"Add another addon"}
                      /> */}
                          <SecondaryOutline
                            handleClick={addAddon}
                            text={"Add another addon"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-rows-1 align-baseline section_padding_sm lg:grid-cols-3 lg:section_padding_lg">
                      <h2 className="section_heading mb-2 lg:mb-0">GST</h2>
                      <div className="flex flex-col lg:flex-row w-full col-span-2 gap-1 lg:gap-2 items-center">
                        <div className="lg:w-[30%] w-full">
                          <label className="text-sm input_label_padding  font-normal ">
                            GST %
                          </label>
                          <Input
                            placeholder="GST %"
                            type="number"
                            classes="w-[100%]"
                            name={"gst"}
                            required={gstStatus === "includes" ? false : true}
                            defaultValue={gst}
                            handleChange={(e) => setGst(e.target.value)}
                          />
                        </div>
                        <div className="mt-2 w-full flex-grow">
                          <RadioButtonGroup
                            columns={2}
                            name="gst"
                            options={gstOptions}
                            selectedValue={gstStatus}
                            onChange={(value) => {
                              setGstStatus(value);
                            }}
                            classes={"pr-0"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-rows-1 align-baseline section_padding_sm lg:grid-cols-3 lg:section_padding_lg">
                      <h2 className="section_heading">Gateway charges</h2>
                      <div className="col-span-2">
                        <RadioButtonGroup
                          columns={2}
                          name="gateway"
                          options={gatewayOptions}
                          selectedValue={gatewayCharges}
                          onChange={(value) => {
                            setGatewayCharges(value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div>
            <h2 className="section_heading mt-4">Accommodation</h2>
          </div> */}
              <div className="lg:section_padding_lg section_padding_sm relative grid align-baseline grid-rows-1 lg:grid-cols-3">
                <h2 className="section_heading mb-2 lg:mb-0">Inclusions</h2>
                {/* <textarea
            required
            defaultValue={inclusions}
            onChange={(e)=>{setInclusions(e.target.value)}}
              style={{ fontSize: "13px" }}
              placeholder="Inclusions"
              className="w-[100%] p-2 h-[100px] border-2 rounded-xl focus:outline-none bg-[rgba(248,246,242,0.30)] font-medium placeholder-gray-400"
            /> */}
                <div className="col-span-2 mt-1">
                  <Editor defaultvalue={inclusions} setValue={setInclusions} />
                </div>
               
              </div>
              <div className=" lg:section_padding_lg section_padding_sm grid align-baseline grid-rows-1 lg:grid-cols-3">
                <h2 className="section_heading mb-2 lg:mb-0">Exclusions</h2>
                {/* <textarea
            required
            defaultValue={exclusions}
            onChange={(e)=>{setExclusions(e.target.value)}}
              style={{ fontSize: "13px" }}
              placeholder="Exclusions"
              className="w-[100%] p-2 h-[100px] border-2 rounded-xl focus:outline-none bg-[rgba(248,246,242,0.30)] font-medium placeholder-gray-400"
            /> */}
                <div className="col-span-2 mt-1">
                  <Editor defaultvalue={exclusions} setValue={setExclusions} />
                </div>
                {/* <input
                  type="text"
                  value={exclusions}
                  required
                  className="opacity-0 absolute z-0 top-40 lg:right-20"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PricingForm;
