import React, { useEffect, useRef, useState } from "react";
import { BioLinkData, DownloadPdf } from "../../api/Api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CategoryFilter from "./CategoryFilter";
import EventModal from "./EventModal";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { MdArrowOutward, MdOutlineLocalOffer } from "react-icons/md";
import { NumericFormat } from 'react-number-format';
import icons from "./icons";
import Fuse from 'fuse.js'
import Skeleton from "react-loading-skeleton";



const Card = ({input, eventList,company,category}) => {
    const parameter = useParams();
    const partner = parameter.slug;

    // const [company, setCompany] = useState([]);
    // const [eventList, setEventList] = useState([]);
    // const [category, setCategory] = useState([]);
    const [selectedTripSlug, setSelectedTripSlug] = useState("");
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [pdfData, setPdfData] = useState([]);
    const [selectedEventSlug, setSelectedEventSlug] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [modal, setModal] = useState(false);
    const [selectedCat, setSelectedCat] = useState(null)
    const [catimages,setcatImages] = useState([]);
    const [activecat,setActiveCat] = useState('all')
    const [loading, setLoading] = useState(false);
    const [filteredEvents,setFilteredEvents] = useState(eventList)
    const [filteredCategory,setFilteredCategory] = useState([])
    const ref = useRef()
    const location = useLocation();
    const navigate = useNavigate();
   

    const toggle = () => setModal(!modal);


    useEffect(()=>{
        const filter = () => {
            setLoading(true)
        if(input=='' && selectedCategoryId){
            const filtered = eventList.filter((event) =>
                category?.find((cat) => cat.id === selectedCategoryId)
                    ?.events.includes(event.id)
            );
      
            
            setFilteredEvents(filtered)
        }
        else if(input!="" && selectedCategoryId==null){
            const filtered = eventList.filter((event) =>
                event.name.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredEvents(filtered)
        }
        else if(input!="" && selectedCategoryId){
            const textFiltered = eventList.filter((event) =>
                event.name.toLowerCase().includes(input.toLowerCase())
            );
            const filtered = textFiltered.filter((event) =>
                category?.find((cat) => cat.id === selectedCategoryId)
                    ?.events.includes(event.id)
            );
            setFilteredEvents(filtered)
        }
        else{
            if(eventList.length>0){
            setFilteredEvents(eventList)
          
            }
           
            
            
        }
        setLoading(false)
        // setTimeout(()=>{
        //     console.log('done');
        //     setLoading(false)
            
        // },2000)
    }
    filter();
    
    },[input,selectedCategoryId])

    

   




    // console.log(eventList)

    // useEffect(()=>{
    //     let obj = eventList.find(o => o.id == '1141');
    //     console.log(obj);
        
    //    category?.map((cat)=>{
    //     cat.events.map((e)=>{
    //         // console.log(e);
            
    //         let obj = eventList.find(o => o.id == e);
    //         // console.log(obj);
            
    //         if(obj?.batches?.length>0 && filteredCategory.indexOf(cat)==-1){
    //             console.log('pushed');
    //             filteredCategory.push(cat)
    //         }
    //     })
    //    })
    // },[])

    // useEffect(()=>{
    
       
       
    // },[eventList])

    useEffect(()=>{
        const eventLookup = Object.fromEntries(eventList?.map(event => [event.id, event]));
        
        
        var filteredCategories = category?.map((category) => {
         
          const hasNonEmptyBatches = category?.events
            .map((eventId) => eventLookup[eventId]?.batches.length > 0)
            .includes(true);
         
          
  
          
          return hasNonEmptyBatches ? category : null;
        }).filter(Boolean);
        setFilteredCategory(filteredCategories)
        var images = {};

     
       const keys = Object.keys(icons)
       const options = {
        includeScore:true
       }
       const fuse = new Fuse(keys,options)
       filteredCategories?.map((cat)=>{
        
        const res = fuse.search(cat.slug);
        // console.log(res);
        if(res.length==0){
            const obj = {
                item : 'default'
            }
            res.push(obj)
        }
        // if(res.length!=0){
        images[cat.slug] = icons[res[0].item]
        // }
       })
    
       setcatImages(images)
       
      
  
    },[category])

    



   

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setPdfData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

   

    

  

    const handleShowAll = () => {
        setSelectedCategoryId(null);
        // filterEvents(null)
    };

    const toggleDrawer = (eventSlug) => {
       
        setSelectedEventSlug(eventSlug);
        setIsDrawerOpen(!isDrawerOpen);
        window.history.pushState({ eventSlug: eventSlug }, "", ``);
    };

    const closeDrawer = () =>{
        setIsDrawerOpen(false);
        window.history.back()
    }

    const handleBackButtonClick = () => {
        setIsDrawerOpen(false);
    };

    

    

    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };

    const formatDate = (dates) => {
        
        if (!dates || dates.length === 0) {
            return "No upcoming batches";
        }

        var formDates = []

        const formattedDates = dates.map(date => {
            const startDate = new Date(date.startdate);
            const endDate = new Date(date.enddate)
            var obj = {}
            const obj1 = {
                date: startDate.getDate(),
                month: startDate.toLocaleString('default', { month: 'short' }),
                day: startDate.toLocaleString('default', { weekday: 'short' }),
                year: startDate.getFullYear(),
            }
            if(endDate){
            const obj2 = {
                date: endDate.getDate(),
                month: endDate.toLocaleString('default', { month: 'short' }),
                day: endDate.toLocaleString('default', { weekday: 'short' }),
                year: endDate.getFullYear(),
            }
            obj.end = obj2
            obj.id = date.id
        }
            obj.start = obj1
            obj.id = date.id
            formDates.push(obj)
        });
      
        return formDates
        //Monday - Nov. 11, 2024
        // return formattedDates
    };

    return (
        <>
            {/* <hr /> */}
           
            <div className="whole-category d-flex container-fluid px-2 mt-4">
               
                {/* Category Filter */}
                <div id="book-container"></div>
                <div className="category button-container w-100" >
                    <div className="category d-flex w-100 gap-4 px-4">
                    <div role="button" className={`d-flex flex-column justify-content-center align-items-center  ${activecat=='all' ? 'activecat' : 'inactivecat'}`}  onClick={()=>{setActiveCat('all'); setSelectedCategoryId(null)}}
                        style={{
                            // backgroundColor: !selectedCategoryId ? (eventList[0]?.style_data.backgroundColor || "green") : "",
                            borderBottom: activecat=='all' && '2px solid '+ eventList[0]?.style_data.backgroundColor,
                            color: 'black',
                            borderRadius: '0px',
                            fontFamily: 'Helvetica Neue Medium', fontSize: '15px'
                        }}
                        >
                    <img src={`${process.env.PUBLIC_URL}/icons/all.png`} alt="image" width={25} height={25} />
                        <span
                            // type="button"
                            className={`category-button d-flex align-items-center `}
                            
                            
                        >
                            <span className="fw-smaller price" style={{fontSize: '14px'}}>All</span>
                        </span>
                    </div>
                        {filteredCategory && filteredCategory.length>0 ? filteredCategory.map((cat, index) => (
                             <div key={cat.id} role="button" className={`d-flex flex-column justify-content-center align-items-center ${activecat==cat.name ? 'activecat' : 'inactivecat'} `} onClick={()=>{setActiveCat(cat.name); setSelectedCategoryId(cat.id);}}
                        style={{
                            // backgroundColor: !selectedCategoryId ? (eventList[0]?.style_data.backgroundColor || "green") : "",
                            borderBottom: activecat==cat.name && '2px solid '+ selectedCat?.style_data?.backgroundColor,
                            color: 'black',
                            borderRadius: '0px',
                            fontFamily: 'Helvetica Neue Medium', fontSize: '15px'
                        }}
                        >
                            {catimages[cat.slug] ? 
                            <img src={`${process.env.PUBLIC_URL}/icons/${catimages[cat.slug]}.png`} alt="image" width={25} height={25} />
                        
                        : <Skeleton circle={true} height={25} width={25} />}
                            <span
                                key={index}
                                // type="button"
                                className={` category-button d-flex align-items-center`}
                                onClick={() => {


                                    // setSelectedCat(cat.events.map((e)=>{
                                    //     console.log(eventList.find(event => event.id == e))
                                    // }))
                                    setSelectedCategoryId(cat.id)
                                    const id = cat.id
                                    const selcat = category.find(cat => cat.id === id)
                           
                                    selcat?.events.map((e) => {
                                        eventList.map((event) => {
                                            if (event.id === e) {
                                                setSelectedCat(event)
                                                ref.current = event
                                            }
                                        })
                                    })
                                    
                                }}

                               
                            >
                                <span className="fw-smaller price" style={{fontSize: '14px'}}>{cat.name}</span>
                            </span>
                            </div>
                        ))
                        :
                        <div className="d-flex gap-4">
                            {
                               category!=null && [...Array(category.length)].map((_,index)=>{
                                    return (
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <Skeleton circle={true} height={35} width={35} />
                                            <Skeleton count={1} height={5} width={35} />
                                        </div>
                                    )
                                    })
                            }
                        </div>
                    
                    }
                    </div>
                </div>

            </div>
            {/* <hr /> */}
            {/* <ToastContainer /> */}

            {/* Event Cards */}
            <div className="wrapper container-fluid px-1 px-md-4" >
                {loading && (
                    <Skeleton count={3} height={100} />
                )}
                {(filteredEvents.length === 0 && !loading) ? (
                    <div style={{display: 'flex',justifyContent: 'center', marginTop: '2rem'}}><h3>No events found for this category</h3></div>
                ) : (
                    <div className="cards_wrap">
                        {filteredEvents
                            ?.filter((event) => event.batches.length > 0)
                            .map((event, index) => (
                                <div key={index} className="card_item mb-2 p-lg-2">
                                    <div className="cla" onClick={() => toggleDrawer(event.slug)}>
                                        <div className="card cardradius " >
                                            <div className="whole-imgae">
                                                <img src={event.list_pic} className="card-img-top" style={{borderRadius: '5px'}} alt="Event" />
                                            </div>
                                            <div className="card_body_height p-2">
                                                <h6 className="card-title d-lg-block d-md-block d-none mb-0" style={{ maxWidth: '12rem', fontFamily: 'Helvetica Neue Medium', fontSize: '22px', color: 'white' }}>
                                                    <b>
                                                        {event.name}
                                                    </b>
                                                </h6>

                                                <h6 className="card-title d-lg-none d-md-none d-block mb-0" style={{ maxWidth: '18rem', fontFamily: 'Helvetica Neue Medium', fontSize: '24px', color: 'white' }}>
                                                    <b>
                                                        {/* {event.name.length > 18 ? event.name.substring(0, 16) + "..." : event.name} */}
                                                        {event.name}
                                                    </b>
                                                </h6>
                                                <div style={{ color: 'white' }} className="d-flex justify-content-between align-items-center">
                                                    {/* <p className="text-detail-price mb-for-plain mb-0">{formatDate(event.batches)}</p> */}

                                                    <div>
                                                        <p className="text-detail-price mb-for-plain mb-0 text-white" style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '21px', fontWeight: '400' }}>
                                                            <span className="me-2" style={{ fontWeight: '300', fontSize: '16px', fontFamily: 'Helvetica Neue' }}>from</span>
                                                            {event.price_striked ? (
                                                                <>
                                                                    <NumericFormat className="fw-bolder" style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '22px' }} displayType="text" type="text" value={event.price} thousandsGroupStyle="lakh" thousandSeparator="," />
                                                                    <span style={{ textDecoration: 'line-through' }} className="px-1 strike">
                                                                        {event.price_striked}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span>{event.price}</span>
                                                            )}
                                                        </p>

                                                    </div>
                                                    <div>
                                                        <button className="book_button">
                                                            <span>Details</span>
                                                            <MdArrowOutward size={15} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {event.event_card_tags && 
                                            <div className="ribbon-2" style={{ backgroundColor: event.style_data.backgroundColor, color: event.style_data.textColor }}>
                                                <div className="ribbon_heading">
                                                <b className="event_tag">{toTitleCase(event.event_card_tags)}</b>
                                                </div>
                                            </div>
                                            }
                                            {event.coupon &&
                                              
                                                <div>
                                                    <div className="ribbon-offer" style={{color: 'black' }}>
                                                    <div className="offer_heading d-flex" style={{gap: '4px'}}>
                                                            <MdOutlineLocalOffer size={20} />
                                                        <b className="event_tag">
                                                            Offers Available!</b>
                                                    </div>
                                                    </div>
                                              </div>
                                            }
                                        </div>
                                    </div>
                                    

                                </div>
                            ))}
                    </div>
                )}
                {isDrawerOpen && <EventModal
                                        isOpen={isDrawerOpen}
                                        toggle={closeDrawer}
                                        eventList={eventList}
                                        company={company}
                                      
                                        currentSlide={currentSlide}
                                        handleDotClick={handleDotClick}
                                        selectedEventSlug={selectedEventSlug}
                                        formatDate={formatDate}
                                    />
                                    }
            </div>
           
          
        </>
    );
};

export default Card;
