import React, { useState } from "react";
import { MdCancel, MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi2";

const ImageCarousel = ({ images, removeEventImage }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null)
const [touchEnd, setTouchEnd] = useState(null)

// the required distance between touchStart and touchEnd to be detected as a swipe
const minSwipeDistance = 50 

const onTouchStart = (e) => {
  setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  setTouchStart(e.targetTouches[0].clientX)
}

const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

const onTouchEnd = () => {
  if (!touchStart || !touchEnd) return
  const distance = touchStart - touchEnd
  const isLeftSwipe = distance > minSwipeDistance
  const isRightSwipe = distance < -minSwipeDistance
  if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    if(isRightSwipe){
      goToNext()}
    else if(isLeftSwipe){
      goToPrev()
    }
  // add your conditional logic here
}

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? 0 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1
    );
  };

  return (
    <div className="relative w-full max-w-xl mx-auto" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className="relative overflow-hidden">
        <div className="flex flex-col items-center justify-center">
          <div className="w-full h-auto">
          <div
                    style={{
                      backgroundImage: images[currentIndex]?.preview?.startsWith("blob") ? `url(${images[currentIndex]?.preview})`: `url(${process.env.REACT_APP_API_URL}${images[currentIndex]?.preview})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      paddingBottom: "66.67%",
                      borderRadius: "0.75rem",
                      aspectRatio: "1/1",
                    }}
                    className={`relative overflow-hidden`}
                  ></div>
        {/* <img
          src={images[currentIndex].preview}
          alt={`carousel-image-${currentIndex}`}
          className="w-full h-full aspect-square rounded-xl object-cover transition-transform duration-500 ease-in-out"
        /> */}
        </div>
        </div>
        <div className="flex justify-end items-center">
          <div onClick={()=>{removeEventImage(images[currentIndex].id); setCurrentIndex(0)}} className="flex text-sm mt-2 text-black items-center cursor-pointer gap-1 px-2 py-1 rounded-md ">
            <span>Remove</span>
          <HiOutlineTrash size={20} />
          </div>
        </div>
        
      </div>

      {/* Prev and Next Buttons */}
      <span
    
        onClick={goToPrev}
        className={`absolute top-[45%] ${currentIndex==0 && 'opacity-30'} left-4 transform -translate-y-1/2 bg-black text-white p-1 rounded-full shadow-lg  transition`}
      >
        <MdChevronLeft size={20} />
      </span>
      <span
    
        onClick={goToNext}
        className={`absolute top-[45%] right-4 ${currentIndex==images.length-1 && 'opacity-30'} transform -translate-y-1/2 bg-black text-white p-1 rounded-full shadow-lg transition`}
      >
       <MdChevronRight size={20} />
      </span>

      {/* Optional: Indicator (Dot navigation) */}
      {/* <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full ${
              index === currentIndex ? "bg-white" : "bg-gray-400"
            }`}
          />
        ))}
      </div> */}
      
    </div>
  );
};

export default ImageCarousel;
