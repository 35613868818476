import React from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { CgDetailsMore } from "react-icons/cg";
import { GoFileMedia } from "react-icons/go";
import { CiMoneyCheck1 } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import { FaRegCompass } from "react-icons/fa";
import { GrValidate } from "react-icons/gr";
import { FiHeart } from "react-icons/fi";
import { FaRegCreditCard } from "react-icons/fa6";
import { useSelector } from 'react-redux';


const Stepper = ({ steps, steps_images, activeStep ,setActiveStep }) => {
  const isEdit = useSelector(state => state.isEdit);
  const handleJump = (index) => {
    if(isEdit){
      setActiveStep(index);
    }
  }
  return (
    <div className="flex lg:flex-col justify-center lg:justify-start mt-8 flex-row px-4 md:p-0 md:gap-2">
      {steps.map((step, index) => (
        <div className="flex justify-center items-center lg:items-start lg:flex-col" key={index}>
          <div onClick={()=>{handleJump(index)}} className={`step flex lg:gap-3 items-center lg:items-center ${isEdit ? 'cursor-pointer' : ''} flex-col lg:flex-row ${index > activeStep ? 'opacity-40' : ''}`}>
            {index < activeStep ? (
              <GrValidate className='text-[#045540] w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />
            ) : (
              steps_images[index]
            )}
            <p className={`text-[0.9375rem] font-semibold mt-2 md:mt-0 ${index < activeStep ? 'text-[#045540]' : ''}`}>
              {step}
            </p>
          </div>
          
          {/* Progress line between steps */}
          {index < steps.length - 1 && (
            <div className={`lg:h-8 lg:w-[1px] h-[1px] md:mx-4 w-8 lg:my-2 ${index<activeStep ? 'bg-[#045540]' : 'bg-gray-300'}`}></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
