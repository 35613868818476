import React, { useEffect, useState } from "react";
import { BioLinkData, forPdfDownload } from "../api/Api";
import './navbar/navbar.css'
import { MdArrowOutward } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = ({ inputText, onInputChange, slug, eventList, company }) => {
  // const [company, setCompany] = useState([]);
  const [previousRoute, setPreviousRoute] = useState('/');
  const [text, setText] = useState("");
  // const [eventList, setEventList] = useState([]);
  const [index, setIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const currentUrl = window.location.href;
  const [slide, setSlide] = useState(false);
  const [nextPlaceholder, setNextPlaceholder] = useState("");
  const [isErasing, setIsErasing] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150); // Speed of typing/erasing
  const [pauseTime, setPauseTime] = useState(1000);
  var event_names = [];
  const partner = slug.slug;

  
  

  useEffect(() => {
    eventList.map((event) => {
      event_names.push(event.name)
    })
    console.log(event_names);

  }, [eventList]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Start sliding
  //     setSlide(true);

  //     // After animation completes (500ms), update the placeholders
  //     setTimeout(() => {
  //       setIndex((prevIndex) => (prevIndex + 1) % eventList.length);
  //       setText(eventList[index].name);
  //       setNextPlaceholder(eventList[(index + 1) % eventList.length].name);

  //       // Reset slide to false after updating the placeholder
  //       setSlide(false);
  //     }, 500); // Time for slide-down effect
  //   }, 5000); // Change every 5 seconds

  //   return () => clearInterval(interval);
  // }, [index, eventList]);

  useEffect(() => {
    let timeout;
    if (eventList.length === 0) return;
    const typeText = () => {
      const currentPlaceholder = eventList[index].name;

      if (!isErasing) {
        // Typing effect: Add one character at a time
        const nextText = currentPlaceholder.slice(0, text.length + 1);
        setText(nextText);

        // If the whole placeholder is typed, wait and then start erasing
        if (nextText === currentPlaceholder) {
          setTimeout(() => setIsErasing(true), 1000); // Pause before erasing
        }
      } else {
        // Erasing effect: Remove one character at a time
        const nextText = currentPlaceholder.slice(0, text.length - 1);
        setText(nextText);

        // If the whole text is erased, move to the next placeholder
        if (nextText === "") {
          setIsErasing(false);
          setIndex((prevIndex) => (prevIndex + 1) % eventList.length);
        }
      }
    };

    timeout = setTimeout(typeText, isErasing ? typingSpeed / 2 : typingSpeed); // Speed up erasing

    return () => clearTimeout(timeout); // Clean up timeout on each effect
  }, [text, isErasing, index, eventList, typingSpeed]);


  // useEffect(() => {
  //   var n = 0;
  //   var loopLength = eventList.length;
  //   setInterval(function () {
  //     if (n < loopLength) {
  //       var newPlaceholder = eventList[n].name;
  //       n++;
  //       setText(newPlaceholder);
  //     } else {

  //       n = 0;
  //     }
  //   }, 5000);
  //   return () => clearInterval();
  // }, [eventList])



  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(inputText);
  };

  const handleBackButtonClick = () => {
    // Navigate to the previous route stored in state
    window.location.href = previousRoute;
  };

  return (
    <>
      <nav className="d-flex sticky align-items-center navbar-light bg-light container-fluid pt-2 pb-2 px-2" >
        <div className="d-lg-none d-sm-block  d-md-none navbar-brand-image">
          <div>
            <a className="navbar-brand" href="javascript:void(0)">
              <img
                src={company.cover_pic}
                alt=""
                className="d-inline-block align-text-top rounded-circle p-1"
              />
            </a>
          </div>
          <h6 className="text-dark px-2 mb-0 d-md-block mt-1 d-lg-block d-none  ">
            <b style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '18px' }}>{company.name}</b>
          </h6>
        </div>
        <div className="whole gap-4  p-1 d-flex justify-content-between w-100 align-items-center">
          <div>
            <h6 className="text-dark px-2 mb-0  mt-1 d-block  ">
              <b style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '18px' }}>{company.name}</b>
            </h6>
          </div>
          {company.Website &&
            <a href={company.Website} target="_blank" className="d-lg-none d-md-none d-block" style={{ position: 'absolute', right: '5%', textDecoration: 'none', color: 'black' }}>
              <div className="d-flex align-items-center px-2 py-1 text-white rounded-pill"  style={{background: eventList[0].style_data.backgroundColor}}>
                <h6 className="text-white px-2 mb-0  mt-1 d-block  ">
                  <b style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '15px' }}>Web</b>
                </h6>
                <MdArrowOutward size={20} />
              </div>
            </a>}


          <div className=" d-lg-block d-md-block flex-grow-1 d-none gap-4">
            <form className="" onSubmit={handleSearchSubmit} style={{ border: '0.5px solid rgba(0 0 0/0.08)', boxShadow: '0 3px 10px rgba(0 0 0/0.1)', borderRadius: '25px' }}>
              <div className="input-group px-2 py-2" style={{ width: '100%', margin: 'auto', display: 'flex', alignItems: 'center', gap: '8px' }}>
                {/* <button
              className="btn btn-outline-white bg-white p-2"
              
              style={{ border: 'none', borderBottom: '1px solid #dee2e6', borderRadius: '0px' }}
            > */}
                <i className="fa fa-search text-dark fs-6"></i>
                {/* </button> */}

                <input
                  type="text"
                  id="searchInput"
                  value={inputText}
                  onChange={onInputChange}
                  className={`form-control`}
                  placeholder={text}
                  // style={{border: '1px solid #dee2e6',borderTopLeftRadius: '5px',borderBottomLeftRadius: '5px', borderRight: 'none'}}
                  style={{ border: 'none', paddingLeft: '0px', fontFamily: 'Helvetica Neue', backgroundColor: 'transparent' }}
                />


              </div>
            </form>
          </div>
          <div className="d-flex align-items-center">
            <a className="navbar-brand" href="javascript:void(0)">
              <img
                src={company.cover_pic}
                alt=""
                width="50"
                height="50"
                className="d-inline-block align-text-top rounded-circle  d-lg-block d-md-block d-none"
              />
            </a>
            {company.Website &&
            <a href={company.Website} target="_blank" className="d-lg-block d-md-block d-none ml-4" style={{ textDecoration: 'none', color: 'black' }}>
              <div className="d-flex justify-content-center align-items-center px-2 py-1 text-white rounded-pill" style={{backgroundColor: eventList[0].style_data.backgroundColor}}>
                <h6 className="text-white px-2 mb-0  mt-1 d-block  ">
                  <b style={{ fontFamily: 'Helvetica Neue Medium', fontSize: '15px' }}>Web</b>
                </h6>
                <MdArrowOutward size={20} />
              </div>
            </a>}
            

          </div>



        </div>

        {/* <div className="bg_image">
          <div className="cover_pic">
            <a className="navbar-brand" href="/">
              <img
                src={company.cover_pic}
                alt=""
                width="67"
                height="67"
                className="d-inline-block align-text-top rounded-circle"
              />
            </a>
            <h6 className="text-dark px-3 mb-0  mt-1 d-md-none d-lg-none d-block  ">
            <b style={{fontFamily: 'Helvetica Neue Medium',fontSize: '18px'}}>{company.name}</b>
          </h6>
          </div>
        </div> */}





      </nav>
      <div className="px-4 py-2 d-lg-none d-md-none d-block mt-2" >
        <form onSubmit={handleSearchSubmit} style={{ border: '0.5px solid rgba(0 0 0/0.08)', boxShadow: '0 3px 10px rgba(0 0 0/0.1)', borderRadius: '25px' }}>
          <div className="input-group px-2 py-2" style={{ width: '95%', margin: 'auto', display: 'flex', alignItems: 'center', gap: '8px' }}>
            {/* <button
              className="btn btn-outline-white bg-white p-2"
              
              style={{ border: 'none', borderBottom: '1px solid #dee2e6', borderRadius: '0px' }}
            > */}
            <i className="fa fa-search text-dark fs-6"></i>
            {/* </button> */}

            <input
              type="text"
              id="searchInput"
              value={inputText}
              onChange={onInputChange}
              className={`form-control`}
              placeholder={text}
              // style={{border: '1px solid #dee2e6',borderTopLeftRadius: '5px',borderBottomLeftRadius: '5px', borderRight: 'none'}}
              style={{ border: 'none', paddingLeft: '0px', fontFamily: 'Helvetica Neue' }}
            />
            {/* <div className={`holder ${slide ? "slide-down" : ""}`}>
          {nextPlaceholder}
        </div> */}

          </div>
        </form>
      </div>
    </>
  );
};

export default Navbar;
