const icons = {
    'all': 'all',
    '1 day': 'trekking',
    'trek': 'trekking',
    'trips' : 'trips',
    'ttc' : 'yoga',
    'backpacking': 'backpacking',
    'adventure':'adventure',
    'island':'island',
    'craft' : 'craft',
    'badrinath' : 'temple',
    'kedarnath':'temple',
    'international' : 'international',
    'bangalore': 'bangalore',
    'bengaluru': 'bangalore',
    'bike': 'biking',
    'bungy': 'bungee',
    'camping': 'camp',
    'dham': 'dham',
    'cycling': 'cycling',
    'domestic': 'domestic',
    'ladakh': 'trips',
    'fireflies':'fireflies',
    'fishing':'fishing',
    'flying-fox':'flyingfox',
    'food-and-drinks': 'workshop',
    'music':'workshop',
    'giant-swing':'zipline',
    'goa-trips':'beach',
    'heritage-and-culture':'walks',
    'holi':'holi',
    'kashmir':'mountains',
    'kayaking':'kayak',
    'kerala':'kerala',
    'surf':'surfing',
    'leisure':'leisure',
    'monsoon':'monsoon',
    'online-yoga': 'online-yoga',
    'paraglide': 'paraglide',
    'photography':'photography',
    'rafting':'rafting',
    'romantic':'romantic',
    'scuba-dive': 'scuba-dive',
    'ski':'ski',
    'sky-diving':'sky-diving',
    'snorkeling': 'snorkeling',
    'snowboard':'snowboard',
    'social':'social',
    'watersports':'watersports',
    'wellness':'wellness',
    'wildlife': 'wildlife',
    'workation':'workation',
    'meghalaya': 'meghalaya',
    'default' : 'trips'
}

export default icons