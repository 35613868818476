import React from "react";
import SecondaryButton from "./UI/SecondaryButton";
import BackButton from "./UI/BackButton";
import { useSelector } from "react-redux";

const FormControl = ({
  activeStep,
  handleBack,
  handleNext,
  isDesktop,
  isLoading,
}) => {
  const isEdit = useSelector((state)=>state.isEdit)
  return (
    <div
      className={`${
        isDesktop ? "w-[83.5%]" : "w-full"
      } fixed bottom-0 bottom_controls h-[60px] lg:h-[57px] bg-[#fdfcfb]`}
    >
      {/* <hr className="h-[1px] border-none bg-black opacity-30" /> */}
      <div className="flex justify-between px-4 py-2">
        <BackButton
          text={"Back"}
          classes={`${activeStep == 0 && "invisible"} underline`}
          handleClick={handleBack}
        ></BackButton>
        <button
          type="submit"
          disabled={isLoading}
          className="text-white bg-[#045540] font-semibold px-20 py-2 my-0 rounded-lg"
        >
          {!isLoading && (activeStep !== 4 ? "Next" : isEdit ? 'Save' :'Launch')}
          {isLoading && <div className="px-3"><div className="dots"></div></div>}
        </button>
      </div>
    </div>
  );
};

export default FormControl;
