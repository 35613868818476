import { useState } from "react";
import "./App.css";
import AppRoutes from "./routes/Routes";
import { BrowserRouter as Router } from 'react-router-dom';

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import LinkInBio from "./pages/link_in_bio/LinkInBio";
// import {GoodI18nProvider} from './_theme/i18n/Goodi18n'



function App() {

  return (
    <>
      
        <Router>
          <AppRoutes />
        </Router>
     
    </>
  );
}

export default App;
