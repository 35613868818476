import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
  import { useRef } from "react";
  
  
  export default function PlacesAutocomplete({setMarkerPosition, setSelectedLocation}) {
   
    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 100,
    });
  
    const handleSelect =
      ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
        setSelectedLocation(description)
        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          console.log("📍 Coordinates: ", { lat, lng });
          setMarkerPosition({ lat, lng })
        });
      };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
          <li key={place_id} onClick={handleSelect(suggestion)}>
            <b>{main_text}</b> {secondary_text}
            <hr className="my-2"/>
          </li>
        );
      });
  
    return (
      <div className="relative mb-2">
        {<input
        className="w-full focus:outline-none p-2 border-2 rounded-xl text-input_text bg-[rgba(248,246,242,0.30)] font-semibold placeholder-gray-400"
          defaultValue={value}
          onChange={handleInput}
          
          placeholder="Search your location"
          
        />}
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {status === "OK" && 
        <div className="absolute rounded-b-xl border-2 border-t-0 top-100 z-20 w-full p-4 overflow-auto max-h-40 bg-white"><ul className="cursor-pointer">{renderSuggestions()}</ul></div>}
      </div>
    );
  };